import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import Routes from './Routes';

Sentry.init({
  dsn:
    'https://9f34ec85529e98390f2b68043b7b8a7c@o1275896.ingest.sentry.io/4505832813756416',
});

if (window.location.protocol !== 'https:') {
  window.location = `https:${window.location.href.substring(
    window.location.protocol.length,
  )}`;
}

ReactDOM.render(<Routes />, document.getElementById('app'));
