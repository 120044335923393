import React from 'react';
import { CardText, CardTitle, RaisedButton } from 'material-ui';
import moment from 'moment';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import InfoPanel from '../../utils/InfoPanel/InfoPanel';
import { Loading } from '../../utils/loading';
import OpenTrainingParticipants from '../OpenTrainings/OpenTrainingParticipants';
import getOpenTrainingListForUserQuery from '../../graphql/queries/getOpenTrainingListForUser.graphql';

function UserOpenTrainings() {
  const downloadRegistrationForm = (registrationFileUrl) => {
    window.open(registrationFileUrl);
  };

  const { data, loading } = useQuery(getOpenTrainingListForUserQuery);
  if (loading) {
    return <Loading />;
  }

  const registrations = get(data, 'getOpenTrainingListForUser', []);

  return (
    <div>
      {registrations.length > 0 ? (
        registrations.map((registration) => {
          const trainingDate = moment(registration.trainingDate).format(
            'DD-MM-YYYY',
          );
          const registrationSubtitle =
            registration.city && registration.place
              ? `${trainingDate} - ${registration.city} - ${registration.place}`
              : '';
          return registration.title ? (
            <div className="order" key={registration._id}>
              <div className="order__inner">
                <CardTitle
                  title={registration.title}
                  subtitle={registrationSubtitle}
                />
                <CardText>
                  <div className="order__button-wrapper">
                    Uczestnicy:
                    <OpenTrainingParticipants
                      participants={registration.participants}
                      displayOnly
                      changeParticipants={() => {}}
                    />
                  </div>
                  <br />
                  <RaisedButton
                    label="Pobierz formularz zgłoszeniowy"
                    onClick={() =>
                      downloadRegistrationForm(registration.registrationFileUrl)
                    }
                    primary
                  />
                </CardText>
              </div>
            </div>
          ) : null;
        })
      ) : (
        <InfoPanel role="info">Brak produktów do wyświetlenia</InfoPanel>
      )}
    </div>
  );
}

export default UserOpenTrainings;
