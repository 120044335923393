import React, { useState } from 'react';
import { FileFileDownload as DownloadIcon } from 'material-ui/svg-icons/index.es';
import { ListItem } from 'material-ui';
import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import axios from 'axios';
import { saveAs } from 'file-saver';
import getClientFileDownloadUrlMutation from '../../graphql/mutations/getClientFileDownloadUrl.graphql';
import { fileShape } from './utils';

const downloadFile = (url, fileName) => {
  axios
    .get(url, {
      responseType: 'blob',
    })
    .then((fileResponse) => {
      saveAs(fileResponse.data, fileName);
    });
};

const SingleOnlineRegistrationFile = ({ file, registrationId }) => {
  const defaultSecondaryText =
    'Osiągnięto limit pobrań pliku, bądź termin minął';
  const [secondaryText, setSecondaryText] = useState(
    file.canDownload ? '' : defaultSecondaryText,
  );
  const [canDownload, setCanDownload] = useState(file.canDownload);

  const [getFileUrl] = useMutation(getClientFileDownloadUrlMutation, {
    onError: () => {
      setSecondaryText(defaultSecondaryText);
      setCanDownload(false);
    },
    refetchQueries: ['getOnlineRegistrationsForUser'],
  });

  const disabledColor = !canDownload && '#ddd';

  return (
    <ListItem
      primaryText={file.fileName}
      leftIcon={<DownloadIcon color={disabledColor} />}
      key={file._id}
      onClick={async () => {
        return getFileUrl({
          variables: {
            fileId: file._id,
            registrationId,
          },
        }).then((result) => {
          if (result) {
            return downloadFile(
              result.data.getClientFileDownloadUrl,
              file.fileName,
            );
          }
        });
      }}
      disabled={!canDownload}
      secondaryText={secondaryText}
      style={{
        color: disabledColor,
      }}
    />
  );
};

SingleOnlineRegistrationFile.propTypes = {
  file: fileShape.isRequired,
  registrationId: PropTypes.string.isRequired,
};

export default SingleOnlineRegistrationFile;
