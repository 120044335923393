import React, { useContext, useState } from 'react';
import { browserHistory } from 'react-router';
import PropTypes from 'prop-types';
import Drawer from 'material-ui/Drawer';
import Reorder from 'material-ui/svg-icons/action/reorder';
import './Navbar.scss';
import { AppContext } from '../../common/AppContext';

function Navbar(props) {
  const [mobileMenuOpen, setMenuOpen] = useState(false);
  const closeDrawer = () => {
    setMenuOpen(false);
  };
  const { user } = useContext(AppContext);

  const openDrawer = () => {
    setMenuOpen(true);
  };

  const handleMenuItemClick = (event, item) => {
    event.preventDefault();
    closeDrawer();
    if (item.isLink) {
      window.open(item.link, '_blank');
    } else {
      event.preventDefault();
      browserHistory.push(item.link);
    }
  };

  const goToRoute = (url) => {
    browserHistory.push(url);
    closeDrawer();
  };

  const { isMobile } = props;
  return (
    <React.Fragment>
      {props.stickMenu ? <div className="navbar-fill" /> : null}
      <div className={`navbar-wrapper ${props.stickMenu ? 'stickMenu' : ''}`}>
        <div className="navbar-navbar">
          <div className="container">
            {
              <div className="navbar-menucontainer">
                {isMobile ? (
                  <div onClick={openDrawer} className="navbar-menuitem">
                    <Reorder color="white" />
                  </div>
                ) : (
                  props.menuItems.map((menuItem) => (
                    <div
                      className="navbar-menuitem"
                      onClick={(event) => handleMenuItemClick(event, menuItem)}
                      key={menuItem.label}
                      href={menuItem.link}
                    >
                      <div>{menuItem.icon}</div>
                      <span>{menuItem.label}</span>
                    </div>
                  ))
                )}
              </div>
            }
          </div>
        </div>
        <Drawer
          docked={false}
          open={mobileMenuOpen}
          onRequestChange={closeDrawer}
        >
          <div>
            <div
              className="navbar-mobilemenuitem"
              onClick={() => goToRoute('/')}
            >
              Strona główna
            </div>
            {user ? (
              <>
                <div
                  className="navbar-mobilemenuitem"
                  onClick={() => goToRoute('/profil')}
                >
                  Moje konto
                </div>
                <div
                  className="navbar-mobilemenuitem"
                  onClick={() => props.handleLogout()}
                >
                  Wyloguj
                </div>
              </>
            ) : (
              <div
                className="navbar-mobilemenuitem"
                onClick={() => goToRoute('/login')}
              >
                Zaloguj
              </div>
            )}
            <div
              className="navbar-mobilemenuitem"
              onClick={() => goToRoute('/koszyk')}
            >
              Koszyk
            </div>
          </div>
          {props.menuItems.map((menuItem) => {
            if (menuItem.dropdownElements && menuItem.dropdownElements.length) {
              return (
                <div key={menuItem.label}>
                  <div className="navbar-mobilemenuitem">{menuItem.label}</div>
                  {menuItem.dropdownElements.map((element) => {
                    return (
                      <div key={element.label}>
                        <div
                          className="navbar-mobilemenuitem-child"
                          key={`${element.label}`}
                          onClick={(event) =>
                            handleMenuItemClick(event, element)
                          }
                        >
                          {element.label}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            }
            return (
              <div key={menuItem.label}>
                <div
                  className="navbar-mobilemenuitem"
                  key={`${menuItem.label}`}
                  onClick={(event) => handleMenuItemClick(event, menuItem)}
                >
                  {menuItem.label}
                </div>
              </div>
            );
          })}
        </Drawer>
      </div>
    </React.Fragment>
  );
}

Navbar.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          link: PropTypes.string.isRequired,
        }),
      ),
    }),
  ).isRequired,
  isMobile: PropTypes.bool.isRequired,
  stickMenu: PropTypes.bool.isRequired,
  handleLogout: PropTypes.func.isRequired,
};

export default Navbar;
