import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import './ProductsList.scss';
import { Card, CardText, TextField } from 'material-ui';
import ReactPaginate from 'react-paginate';
import { browserHistory } from 'react-router';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import intersection from 'lodash/intersection';
import difference from 'lodash/difference';
import uniq from 'lodash/uniq';
import { Loading } from '../../utils/loading';
import CategoryContext from '../CategoriesFilter/CategoryContext';
import SchoolProduct from '../product/SchoolProduct';
import { productSlugToType, productTypeToSlug } from '../../utils/product';
import withGraphqlHook from '../../common/withGraphqlHook';
import getPageSettingsQuery from '../../graphql/queries/getPageSettings.graphql';
import getSchoolProducts from '../../graphql/queries/getSchoolProducts.graphql';
import getProductsQuery from '../../graphql/queries/getProducts.graphql';
import getCategoriesQuery from '../../graphql/queries/getCategories.graphql';
import { AppContext } from '../../common/AppContext';
import Product from '../product/Product';
import DynamicColumnView from '../../NewLayout/DynamicColumnView';

const limit = 20;

class ProductsList extends React.Component {
  state = {
    filter: [],
    search: '',
    windowWidth: window.innerWidth,
    showFilters: false,
  };

  componentDidMount() {
    window.addEventListener('resize', this.setNewWindowSize);
  }

  // eslint-disable-next-line class-methods-use-this
  componentWillUnmount() {
    window.onresize = null;
  }

  setNewWindowSize = () => {
    this.setState({
      windowWidth: window.innerWidth,
    });
  };

  changeValue = (event) => {
    this.setState({
      search: event.target.value,
    });
  };

  updateFilters = (slug) => {
    let currentFilter = [...this.state.filter];
    const currentCategory = this.props.categories.find(
      (category) => category.slug === slug,
    );

    if (!currentFilter.includes(slug) && currentCategory.children.length) {
      currentFilter = [...currentFilter, ...currentCategory.children];
      currentFilter.push(slug);
      currentFilter.forEach((filterSlug) => {
        const thisCategory = this.props.categories.find(
          (category) => category.slug === filterSlug,
        );
        if (
          thisCategory.children.length &&
          intersection(thisCategory.children, currentFilter)
        ) {
          currentFilter = [...thisCategory.children, ...currentFilter];
        }
      });
    } else if (
      currentFilter.includes(slug) &&
      currentCategory.children.length
    ) {
      const disableFilters = [...currentCategory.children, slug];
      currentFilter = difference(currentFilter, disableFilters);

      disableFilters.forEach((filterSlug) => {
        const thisCategory = this.props.categories.find(
          (category) => category.slug === filterSlug,
        );
        if (thisCategory.children.length) {
          thisCategory.children.forEach((innerSlug) => {
            if (currentFilter.includes(innerSlug)) {
              const index = currentFilter.indexOf(innerSlug);
              currentFilter.splice(index, 1);
            }
          });
        }
      });
    } else if (currentFilter.includes(slug)) {
      const index = currentFilter.indexOf(slug);
      currentFilter.splice(index, 1);
    } else {
      currentFilter.push(slug);
    }

    if (currentCategory.parent) {
      currentFilter.push(currentCategory.parent);
      const currentParent = this.props.categories.find(
        (category) => category.slug === currentCategory.parent,
      );
      if (currentParent.parent) {
        currentFilter.push(currentParent.parent);
      }
    }
    currentFilter = uniq(currentFilter);

    this.setState({
      filter: currentFilter,
    });
  };

  handlePageClick = (page) => {
    if (page.selected === 0) {
      browserHistory.push(this.props.baseUrl);
    } else {
      browserHistory.push(`${this.props.baseUrl}/${page.selected + 1}`);
    }
  };

  render() {
    const { skip } = this.props;
    const isMobile = this.state.windowWidth < 720;
    const resultRender = [];
    let i = 0;
    // TODO: I dare you, I double dare you - write this recursively
    const filterObjects = this.state.filter.map((filter) =>
      this.props.categories.find((category) => category.slug === filter),
    );
    let filters = [];
    filterObjects.forEach((filterObject) => {
      if (!filterObject.children.length) {
        filters.push(filterObject.slug);
      } else if (filterObject.children.length) {
        if (!intersection(this.state.filter, filterObject.children).length) {
          filters.push(filterObject.slug);
        } else {
          filterObject.children.forEach((child) => {
            if (this.state.filter.includes(child)) {
              const currentChild = this.props.categories.find(
                (category) => category.slug === child,
              );
              if (
                currentChild.children.some((innerChild) =>
                  this.state.filter.includes(innerChild),
                ) &&
                !currentChild.children.every((innerChild) =>
                  this.state.filter.includes(innerChild),
                )
              ) {
                currentChild.children.forEach((innerChild) => {
                  if (this.state.filter.includes(innerChild)) {
                    filters.push(innerChild);
                  }
                });
              } else {
                filters.push(currentChild.slug);
              }
            }
          });
        }
      }
    });
    filters = uniq(filters);
    const hasFilter = !!filters.length;

    const filteredProducts = this.props.products.filter((product) =>
      hasFilter ? intersection(product.categories, filters).length : true,
    );

    const searchFilter = new RegExp(
      this.state.search.toLowerCase().replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
    );

    const searchFilteredProducts = filteredProducts.filter(
      (product) => product.title.toLowerCase().search(searchFilter) >= 0,
    );

    const pagesCount = Math.ceil(searchFilteredProducts.length / limit);
    const productsToShow = searchFilteredProducts.slice(skip, skip + limit);

    productsToShow.forEach((product, index) => {
      const render = (
        <div
          key={i}
          className="products-list__product col-sm-6 col-md-6 col-lg-4"
        >
          {product.type !== 'schoolProduct' ? (
            <Product {...product} />
          ) : (
            <SchoolProduct {...product} />
          )}
        </div>
      );

      i++;

      resultRender.push(render);

      if (index % 2 === 1) {
        resultRender.push(<div key={i} className="clearfix visible-sm" />);
        i++;
      }

      if (index % 3 === 2) {
        resultRender.push(<div key={i} className="clearfix visible-md" />);
        i++;
      }
    });

    const hideDescription =
      this.props.params.pageNo !== '1' && Boolean(this.props.params.pageNo);

    return (
      <CategoryContext.Provider
        value={{
          filter: this.state.filter,
          changeFilter: this.updateFilters,
          pathName: this.props.pathName,
          showFilters: this.state.showFilters,
          toggleShowFilters: this.toggleShowFilters,
        }}
      >
        <DynamicColumnView
          isMobile={isMobile}
          ready={this.props.ready}
          path={this.props.path}
          {...this.props.pageSettings}
        >
          <div className="newsGroup">
            <div className="row row--flex">
              <div className="col-lg-12" style={{ width: '100%' }}>
                {this.props.ready ? (
                  this.props.pageSettings ? (
                    <Card>
                      <h1 className="pagetitle">
                        {this.props.pageSettings.title}
                      </h1>

                      {!hideDescription && this.props.pageSettings.content ? (
                        <CardText
                          dangerouslySetInnerHTML={{
                            __html: this.props.pageSettings.content,
                          }}
                        />
                      ) : null}
                    </Card>
                  ) : null
                ) : (
                  <Loading />
                )}
                <Card>
                  <CardText>
                    <div className="row">
                      <CardText>
                        <TextField
                          value={this.state.search}
                          floatingLabelText="Szukaj..."
                          fullWidth
                          onChange={this.changeValue}
                          name="search"
                        />
                      </CardText>
                    </div>
                  </CardText>
                </Card>
              </div>
            </div>
            <div className="col-lg-12" style={{ padding: '20px 0' }}>
              <div className="newsGroup__news-list">{resultRender}</div>
              <div className="paginationwrapper">
                {pagesCount ? (
                  <ReactPaginate
                    pageCount={pagesCount}
                    nextLabel={'Następna'}
                    previousLabel={'Poprzednia'}
                    onPageChange={this.handlePageClick}
                    disableInitialCallback
                  />
                ) : null}
              </div>
            </div>
          </div>
        </DynamicColumnView>
      </CategoryContext.Provider>
    );
  }
}

export default withGraphqlHook((props) => {
  const { user } = useContext(AppContext);
  let skip = 0;
  if (props.params.pageNo) {
    skip = limit * (parseInt(props.params.pageNo, 10) - 1);
  }
  let baseUrl = '/produkty/';
  const currentPage = parseInt(props.params.pageNo, 10) || 1;
  const pathName = props.location.pathname;
  const type = productSlugToType(pathName);

  const isSchoolProduct = type === 'schoolProduct';

  const { data: settingsData } = useQuery(getPageSettingsQuery, {
    variables: { type: 'pageContent', page: type },
  });
  const pageSettings = get(settingsData, 'getPageSettings.settings', null);
  let products = [];
  let categories = [];
  let ready = false;

  if (isSchoolProduct) {
    const productCategory = get(
      props,
      'params.productCategory',
      'dzienniki-szkolne',
    );
    baseUrl = `/produkty-szkolne/${productCategory}`;
    const { data, loading } = useQuery(getSchoolProducts, {
      variables: { categories: productCategory },
    });
    products = get(data, 'getSchoolProducts', []);
    ready = !loading;
  } else {
    const { data: productData, loading: productsLoading } = useQuery(
      getProductsQuery,
      {
        variables: { type },
      },
    );

    products = get(productData, 'getProducts', []);

    ready = !productsLoading;
    baseUrl = `/produkty/${productTypeToSlug(type)}`;
  }
  const { data: categoriesData } = useQuery(getCategoriesQuery, {
    variables: { type },
  });
  categories = get(categoriesData, 'getCategories', []);

  return {
    skip,
    pathName,
    user,
    products,
    ready,
    categories,
    pageSettings,
    currentPage,
    baseUrl,
  };
}, ProductsList);

ProductsList.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      imageSrc: PropTypes.string,
    }),
  ),
  pageSettings: PropTypes.shape({
    _id: PropTypes.string,
    content: PropTypes.string,
    hasTwoColumns: PropTypes.bool,
    secondColumn: PropTypes.array,
    title: PropTypes.string,
    type: PropTypes.string,
  }),
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      children: PropTypes.arrayOf(PropTypes.string),
      level: PropTypes.number,
      name: PropTypes.string,
      slug: PropTypes.string,
      friendlySlugs: PropTypes.shape({
        slug: PropTypes.shape({
          base: PropTypes.string,
          index: PropTypes.number,
        }),
      }),
    }),
  ),
  ready: PropTypes.bool.isRequired,
  pathName: PropTypes.string.isRequired,
  currentPage: PropTypes.number.isRequired,
  baseUrl: PropTypes.string.isRequired,
  skip: PropTypes.number.isRequired,
};

ProductsList.defaultProps = {
  products: [],
  pageSettings: {},
  categories: [],
};
