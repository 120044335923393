import React from "react";
import { List, Subheader } from "material-ui";
import { useQuery } from "@apollo/react-hooks";
import get from "lodash/get";
import InfoPanel from "../../utils/InfoPanel/InfoPanel";
import withGraphqlHook from "../../common/withGraphqlHook";
import getFilesForUserDocumentQuery from "../../graphql/queries/getFilesForUserDocument.graphql";
import SingleFile from "./singleFile";

function LimitedFileList(props) {
  return (
    <div>
      {props.files && props.files.length ? (
        <List>
          <Subheader inset>Pliki</Subheader>
          {props.files.map(file => (
            <SingleFile
              file={file}
              key={file._id}
              display
              fileDelete={false}
              userDocumentId={props.userDocumentId}
              refetch={props.refetch}
            />
          ))}
        </List>
      ) : (
        <InfoPanel fade={false} role="info">
          Wszystkie pliki zostały już pobrane
        </InfoPanel>
      )}
    </div>
  );
}

export default withGraphqlHook(({ userDocumentId }) => {
  const { data, loading, refetch } = useQuery(getFilesForUserDocumentQuery, {
    variables: {
      userDocumentId,
    },
  });

  const files = get(data, "getFilesForUserDocument", []);

  return { files, loading, refetch };
}, LimitedFileList);
