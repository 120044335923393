import React from "react";
import { ListItem } from "material-ui";
import { browserHistory } from "react-router";

export default class SingleOpenTrainingListEntry extends React.Component {
  goToOpenTraining = () => {
    browserHistory.push(`/szkolenia-otwarte/${this.props.product.slug}`);
  };

  render() {
    return (
      <ListItem
        primaryText={this.props.product.title}
        onClick={this.goToOpenTraining}
      />
    );
  }
}
