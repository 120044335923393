import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import WidgetsDisplay from '../components/Widgets/Widgets';
import './DynamicColumnView.scss';
import getWidgetsQuery from '../graphql/queries/getWidgets.graphql';

function DynamicColumnView(props) {
  const { data, loading } = useQuery(getWidgetsQuery);
  const widgets = get(data, 'getWidgets', []);

  const { children, hasTwoColumns, secondColumn } = props;

  return (
    <div style={{ marginTop: 20 }} className="row row--flex">
      {hasTwoColumns ? (
        <div className="dcv-wrapper">
          <div style={{ marginTop: 15 }} className="col-lg-9">
            {children}
          </div>
          <div className="col-lg-3">
            {secondColumn.map((widgetId) => (
              <WidgetsDisplay
                key={widgetId}
                widgets={widgets}
                widgetId={widgetId}
                loading={loading}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="col-lg-12">{children}</div>
      )}
    </div>
  );
}

export default DynamicColumnView;

DynamicColumnView.propTypes = {
  hasTwoColumns: PropTypes.bool,
  secondColumn: PropTypes.array,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

DynamicColumnView.defaultProps = {
  hasTwoColumns: false,
  secondColumn: [],
  children: null,
};
