import React from "react";
import LinearProgress from "material-ui/LinearProgress";

function Loading() {
  return (
    <div className="container">
      <LinearProgress mode="indeterminate" />
    </div>
  );
}

export { Loading };
