const noIndexRoutes = [
  { path: '/login' },
  { path: '/koszyk' },
  { path: '/zarejestruj' },
  { path: '/szukaj', pathPrefix: true },
];

export const setNoIndex = (pathname) => {
  const noIndexPath = noIndexRoutes.find(({ path }) => pathname.includes(path));
  if (noIndexPath) {
    const meta = document.createElement('meta');
    meta.name = 'robots';
    meta.content = 'noindex';

    if (!noIndexPath.pathPrefix && pathname !== noIndexPath.path) {
      return;
    }
    document.getElementsByTagName('head')[0].appendChild(meta);
  }
};
