import React, { useContext, useState } from 'react';
import { Card, CardText, RaisedButton } from 'material-ui';
import './userData.scss';
import { useMutation } from '@apollo/react-hooks';
import omit from 'lodash/omit';
import { UserForm } from '@ecrklex/components';
import { AppContext } from '../../common/AppContext';
import updateUserProfileMutation from '../../graphql/mutations/updateUserProfile.graphql';

export default function UserData() {
  const [saving, setSaving] = useState(false);
  const [editing, setEditing] = useState(false);
  const { user, setUser } = useContext(AppContext);
  const [updateUserProfile] = useMutation(updateUserProfileMutation, {
    onCompleted: (data) => {
      const newUser = { ...user, profile: data.updateUserProfile };
      setUser(newUser);
      setSaving(false);
    },
  });

  const submitData = async (userData) => {
    setSaving(true);
    setEditing(false);
    await updateUserProfile({
      variables: { input: userData.profile },
    });
  };

  const editUser = () => {
    setEditing(true);
  };

  const { profile } = user;
  const cleanProfile = omit(profile, ['__typename']);
  if (cleanProfile.buyerData) {
    cleanProfile.buyerData = omit(cleanProfile.buyerData, '__typename');
  }

  return (
    <Card>
      <CardText>
        <UserForm
          user={{ profile: cleanProfile }}
          onSubmit={submitData}
          saving={saving}
          submitText="Zapisz"
          submitButton={editing}
          displayOnly={!editing}
        />
        {editing ? null : (
          <RaisedButton primary label="Edytuj" onClick={editUser} />
        )}
      </CardText>
    </Card>
  );
}
