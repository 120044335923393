import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import { Loading } from '../../utils/loading';
import searchQuery from '../../graphql/queries/search.graphql';
import SearchResult from './SearchResult';

function Search(props) {
  const { searchString } = props.params;
  if (!searchString) {
    browserHistory.push('/');
  }

  const { data, loading } = useQuery(searchQuery, {
    variables: { searchString },
  });
  const products = get(data, 'search', []);

  const documents = products.filter((product) => product.type === 'document');
  const trainings = products.filter((product) => product.type === 'training');

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <React.Fragment>
          <h4>Wyniki wyszukiwania dla: {searchString}</h4>
          <hr />
          {trainings.length ? (
            <div>
              <h5>E-szkolenia</h5>
              {trainings.map((result) => (
                <SearchResult
                  key={result._id}
                  title={result.title}
                  slug={result.slug}
                  text={result.short}
                />
              ))}
            </div>
          ) : null}
          {documents.length ? (
            <div>
              <h5>Wzory dokumentów</h5>
              <hr />
              {documents.map((result) => (
                <SearchResult
                  key={result._id}
                  title={result.title}
                  slug={result.slug}
                  text={result.short}
                />
              ))}
            </div>
          ) : null}
          {!documents.length && !trainings.length ? 'Brak wyników...' : null}
        </React.Fragment>
      )}
    </div>
  );
}

export default Search;

Search.propTypes = {
  products: PropTypes.array.isRequired,
  searchString: PropTypes.string.isRequired,
  ready: PropTypes.bool.isRequired,
};
