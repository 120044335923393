import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardTitle,
  CardText,
  MenuItem,
  RaisedButton,
  SelectField,
  TextField,
} from 'material-ui';
import './schoolProductDetails.scss';
import { browserHistory } from 'react-router';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import { Loading } from '../../utils/loading';
import { getFixedPrice } from '../../utils/utils';
import { generateSubpageTitle } from '../../utils/generatePageTitle';
import { AppContext } from '../../common/AppContext';
import withGraphqlHook from '../../common/withGraphqlHook';
import getSchoolProductQuery from '../../graphql/queries/getSchoolProduct.graphql';
import getVatRatesQuery from '../../graphql/queries/getVatRatesQuery.graphql';

class SchoolProductDetails extends React.Component {
  state = {
    value: '',
    size: '',
    color: '',
    quantity: 1,
    errors: {
      quantity: '',
      size: '',
      color: '',
    },
  };

  componentDidUpdate() {
    if (this.props.product) {
      generateSubpageTitle(this.props.product.title);
    }
  }

  static contextType = AppContext;

  validateForm = () => {
    const errors = { ...this.state.errors };
    const { product } = this.props;
    let valid = true;
    if (product.options.size.length) {
      if (!this.state.size) {
        errors.size = 'Musisz wybrać rozmiar';
        valid = false;
      }
    }
    if (product.options.color.length) {
      if (!this.state.color) {
        errors.color = 'Musisz wybrać kolor';
        valid = false;
      }
    }
    this.setState({ errors });
    return valid;
  };

  changeValue = (event) => {
    const onlyDigitsValue = event.target.value.replace(/\D/g, '');
    this.setState({ quantity: onlyDigitsValue });
  };

  changeSize = (event, index, value) => {
    const { errors } = this.state;
    errors.size = '';
    this.setState({
      errors,
      size: value,
    });
  };

  changeColor = (event, index, value) => {
    const { errors } = this.state;
    errors.color = '';
    this.setState({
      errors,
      color: value,
    });
  };

  addToCart = () => {
    const cart = [...this.context.cart];
    if (!this.validateForm()) return;
    const productId = this.props.product._id;

    const cartProduct = {
      id: productId,
      type: this.props.product.type,
    };
    if (this.state.color) {
      cartProduct.color = this.state.color;
    }
    if (this.state.size) {
      cartProduct.size = this.state.size;
    }
    if (this.state.quantity) {
      cartProduct.quantity = this.state.quantity;
    }
    if (
      !cart.find((product) => {
        if (product.id !== cartProduct.id) {
          return false;
        }
        if (product.color && !product.size) {
          return product.color === cartProduct.color;
        }
        if (product.size && !product.color) {
          return product.size === cartProduct.size;
        }
        if (product.color && product.size) {
          return (
            product.color === cartProduct.color &&
            product.size === cartProduct.size
          );
        }
        return true;
      })
    ) {
      const currentCart = [...cart];
      currentCart.push(cartProduct);
      this.context.setCart(currentCart);
      browserHistory.push('/koszyk');
    } else {
      this.context.setMessage('Produkt jest już w koszyku');
    }
  };

  render() {
    if (this.props.loading) {
      return <Loading />;
    }
    const { product } = this.props;
    const priceToShow = (brutto) => {
      if (this.state.size && this.state.size.priceIncrease) {
        return brutto + this.state.size.priceIncrease;
      }
      if (this.state.color && this.state.color.priceIncrease) {
        return brutto + this.state.color.priceIncrease;
      }
      return getFixedPrice(brutto);
    };
    const price = product ? `${priceToShow(product.price)} zł brutto` : 0;

    return (
      <div>
        <Card>
          <br />
          <br />
          <div className={'document-title row'}>
            <div className="col-lg-5 hidden-md hidden-xs">
              <div className="spd-imagewrapper">
                <img className="spd-productimage" src={product.imageSrc} />
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <CardTitle title={product.title} className="title-text" />
              <CardText>
                <div className="price-tag">
                  Cena: <span className="price">{price}</span>
                </div>
                <div className="price-tag">Wysyłka do 7 dni roboczych</div>
                {!!product.options.size.length && (
                  <SelectField
                    style={{ marginRight: '2rem' }}
                    floatingLabelText="Rozmiar"
                    value={this.state.size}
                    onChange={this.changeSize}
                    errorText={this.state.errors.size}
                  >
                    {product.options.size.map((size) => (
                      <MenuItem
                        key={size.size}
                        value={size}
                        primaryText={size.size}
                      />
                    ))}
                  </SelectField>
                )}
                {!!product.options.color.length && (
                  <SelectField
                    required
                    floatingLabelText="Kolor"
                    value={this.state.color}
                    onChange={this.changeColor}
                    errorText={this.state.errors.color}
                  >
                    {product.options.color.map((color) => (
                      <MenuItem
                        key={color.color}
                        value={color}
                        primaryText={color.text}
                      >
                        <div
                          className="box"
                          style={{ background: color.color }}
                        />
                      </MenuItem>
                    ))}
                  </SelectField>
                )}
              </CardText>
              <div className="title-button">
                <RaisedButton
                  label="Do koszyka"
                  onClick={() => this.addToCart()}
                  primary
                  disabled={!!this.state.errors.quantity}
                  style={{ marginRight: '2rem' }}
                />{' '}
                <TextField
                  value={this.state.quantity}
                  style={{ maxWidth: '5rem' }}
                  floatingLabelText="Ilość"
                  onChange={this.changeValue}
                  aria-label="quantity"
                  name="quantity"
                />
                {'szt.'}
              </div>
            </div>
          </div>
          <CardText>
            <div dangerouslySetInnerHTML={{ __html: product.text }} />
            {product.trailer ? (
              <iframe
                width="560"
                height="315"
                src={`https://www.youtube.com/embed/${product.trailer}`}
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
                className="youtube"
              />
            ) : null}
          </CardText>
        </Card>
      </div>
    );
  }
}

export default withGraphqlHook((props) => {
  const { data: vatData, loading: vatLoading } = useQuery(getVatRatesQuery);
  const { data: productData, loading: productLoading } = useQuery(
    getSchoolProductQuery,
    {
      variables: { slug: props.params.productSlug },
    },
  );
  const vatRates = get(vatData, 'getVatRates', []);
  const product = get(productData, 'getSchoolProduct', null);

  const loading = vatLoading || productLoading;
  return { vatRates, loading, product };
}, SchoolProductDetails);

SchoolProductDetails.propTypes = {
  product: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  vatRates: PropTypes.array.isRequired,
};

SchoolProductDetails.defaultProps = {
  product: null,
};
