import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import moment from 'moment';
import SingleNews from '../singleNews/index';
import './index.scss';
import NewsContext from '../newsFilter/NewsFilterContext';
import withGraphqlHook from '../../common/withGraphqlHook';
import getNewsQuery from '../../graphql/queries/getNews.graphql';
import getPageSettingsQuery from '../../graphql/queries/getPageSettings.graphql';
import DynamicColumnView from '../../NewLayout/DynamicColumnView';

class NewsView extends React.Component {
  state = {
    filter: [],
  };

  changeFilter = (filterValue) => {
    const { filter } = this.state;
    if (filter.includes(filterValue)) {
      const index = filter.indexOf(filterValue);
      filter.splice(index, 1);
    } else {
      filter.push(filterValue);
    }
    this.setState({ filter });
  };

  render() {
    const news = this.props.news.filter((filteredNews) =>
      this.state.filter.length
        ? !filteredNews.categories.length ||
          filteredNews.categories.some((category) =>
            this.state.filter.includes(category),
          )
        : true,
    );
    return (
      <NewsContext.Provider
        value={{ filter: this.state.filter, changeFilter: this.changeFilter }}
      >
        <DynamicColumnView
          ready={this.props.ready}
          hasTwoColumns={
            this.props.settings && this.props.settings.hasTwoColumns
          }
          secondColumn={this.props.settings && this.props.settings.secondColumn}
        >
          <div className="newsGroup newsview-list">
            <div className="newsGroup__news-list">
              {news.map((singleNews) => (
                <div key={singleNews._id} className="news-list__news">
                  <SingleNews
                    {...singleNews}
                    createdAt={moment(singleNews.createdOn.unix, 'x').format(
                      'DD-MM-YYYY',
                    )}
                    link={`/news/${singleNews.slug}`}
                  />
                </div>
              ))}
            </div>
          </div>
        </DynamicColumnView>
      </NewsContext.Provider>
    );
  }
}

NewsView.propTypes = {
  news: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string,
      text: PropTypes.string.isRequired,
      imageSrc: PropTypes.string,
      slug: PropTypes.string,
    }),
  ).isRequired,
};

export default withGraphqlHook(() => {
  const { data: newsData, loading: newsLoading } = useQuery(getNewsQuery);
  const { data: settingsData, loading: settingsLoading } = useQuery(
    getPageSettingsQuery,
    {
      variables: { type: 'pageContent', page: 'news' },
    },
  );

  const loading = newsLoading || settingsLoading;
  const news = get(newsData, 'getNews', []);
  const pageSettings = get(settingsData, 'getPageSettings.settings', {});

  return { news, loading, settings: pageSettings };
}, NewsView);
