import React, { useState } from 'react';
import {
  Card,
  CardActions,
  CardHeader,
  CardText,
  DatePicker,
  RaisedButton,
} from 'material-ui';
import Update from 'material-ui/svg-icons/action/update';
import Intl from 'intl';
import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import updateDisplayDateMutation from '../../graphql/mutations/updateDisplayDate.graphql';
import { getAccessMessage } from './helpers/getAccessMessage';

export default function UserDocumentDatePicker(props) {
  const [displayDate, setDisplayDate] = useState(props.document.displayDate);
  const [changed, setChanged] = useState(false);
  const [changeDate] = useMutation(updateDisplayDateMutation, {
    onCompleted: () => {
      props.refetch();
      setChanged(false);
    },
  });
  const updateDate = async () => {
    await changeDate({
      variables: {
        displayDate,
        documentId: props.document._id,
      },
    });
  };

  const handleDisplayDateChange = (event, date) => {
    setDisplayDate(date);
    setChanged(true);
  };

  const buttonDisabled = !displayDate || !changed;
  const { product } = props;
  return (
    <Card>
      <CardHeader title={product.title} subtitle={product.short} />
      <CardText>
        Wyznaczona data {getAccessMessage(product.days, product.type)}:
        <DatePicker
          className="cart-single-product__datepicker"
          hintText="Data wyświetlenia"
          DateTimeFormat={Intl.DateTimeFormat}
          value={displayDate ? new Date(displayDate) : ''}
          onChange={handleDisplayDateChange}
          locale="pl"
          okLabel="Wybierz"
          cancelLabel="Anuluj"
          minDate={new Date()}
        />
      </CardText>
      <CardActions>
        <RaisedButton
          label={displayDate ? 'Zatwierdź zmianę' : 'Zmień datę'}
          onClick={updateDate}
          primary
          disabled={buttonDisabled}
          icon={<Update />}
        />
      </CardActions>
    </Card>
  );
}

UserDocumentDatePicker.propTypes = {
  document: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};
