import React from "react";
import PropTypes from "prop-types";
import DownArrowIcon from "material-ui/svg-icons/hardware/keyboard-arrow-down";
import UpArrowIcon from "material-ui/svg-icons/hardware/keyboard-arrow-up";
import "./Panel.scss";

export default function Panel(props) {
  return (
    <div className="panel-wrapper">
      <div
        className="panel-header"
        onClick={props.toggleShowFilters}
        style={{
          backgroundColor: props.backgroundColor,
          color: props.color,
        }}
      >
        {props.title}
        {props.isMobile && props.showFilters && (
          <UpArrowIcon className="filters-arrow" />
        )}
        {props.isMobile && !props.showFilters && (
          <DownArrowIcon className="filters-arrow" />
        )}
      </div>
      <div
        style={{ borderColor: props.backgroundColor }}
        className="panel-content"
      >
        {props.children}
      </div>
    </div>
  );
}

Panel.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  color: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  showFilters: PropTypes.bool,
  isMobile: PropTypes.bool,
  toggleShowFilters: PropTypes.func,
};

Panel.defaultProps = {
  color: "#fff",
  children: null,
  showFilters: false,
  isMobile: false,
  toggleShowFilters: t => t,
};
