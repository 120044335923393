import React from 'react';
import Reorder from 'material-ui/svg-icons/action/reorder';
import Description from 'material-ui/svg-icons/action/description';
import Airplay from 'material-ui/svg-icons/av/airplay';
import Style from 'material-ui/svg-icons/image/style';
import Assignment from 'material-ui/svg-icons/action/assignment';
import Book from 'material-ui/svg-icons/action/book';
import PeopleOutline from 'material-ui/svg-icons/social/people-outline';
import Public from 'material-ui/svg-icons/social/public';
import NoFilter from 'material-ui/svg-icons/image/filter-none';
import ImportantContact from 'material-ui/svg-icons/communication/import-contacts';
import SupervisorUser from 'material-ui/svg-icons/action/supervisor-account';
import QuestionAnswer from 'material-ui/svg-icons/action/question-answer';
import NewReleases from 'material-ui/svg-icons/av/new-releases';
import Cloud from 'material-ui/svg-icons/file/cloud';
import Bulb from 'material-ui/svg-icons/action/lightbulb-outline';
import School from 'material-ui/svg-icons/social/school';

const menuItems = [
  {
    label: 'Aktualności',
    link: '/aktualnosci',
    icon: <Reorder color="white" />,
  },
  {
    label: 'Wzory dokumentów',
    link: '/produkty/dokumenty-szkolne-wzory',
    icon: <Description color="white" />,
  },
  {
    label: 'E-szkolenia',
    link: '/produkty/e-szkolenia-dla-nauczycieli',
    icon: <Airplay color="white" />,
  },
  {
    label: 'Szkolenia otwarte',
    link: '/szkolenia-otwarte-dla-nauczycieli',
    icon: <Style color="white" />,
  },
  {
    label: 'Szkolenia zamknięte',
    link: '/szkolenia-zamkniete',
    icon: <Assignment color="white" />,
  },
  // {
  //   label: "Doradztwo",
  //   link: "/doradztwo",
  //   icon: <People color="white" />,
  // },
  {
    label: 'RODO',
    link: '/rodo',
    icon: <NoFilter color="white" />,
  },
  {
    label: 'Akty prawne',
    link: '/akty-prawne',
    icon: <ImportantContact color="white" />,
  },
  {
    label: 'Produkty szkolne',
    link: '/produkty-szkolne',
    icon: <Book color="white" />,
  },
  // {
  //   label: "Dzienniki szkolne",
  //   link: "http://edulex.com.pl",
  //   isLink: true,
  //   icon: <Book color="white" />,
  // },
  // {
  //   label: "Prolegis",
  //   link: "/kontrola-zarzadcza",
  //   icon: <PlaylistAdd color="white" />,
  // },
];

const lowerMenuItems = [
  {
    label: 'O nas',
    link: '/o-nas',
    icon: <SupervisorUser color="white" />,
  },
  {
    label: 'Rada Programowa',
    link: '/rada-programowa',
    icon: <PeopleOutline color="white" />,
  },
  {
    label: 'Opinie klientów',
    link: '/opinie',
    icon: <Public color="white" />,
  },
  {
    label: 'FAQ',
    link: '/faq',
    icon: <QuestionAnswer color="white" />,
  },
  {
    label: 'Statuty',
    link: '/produkty/statuty',
    icon: <NewReleases color="white" />,
  },
  {
    label: 'Szkolenia online',
    link: '/produkty/szkolenia-online',
    icon: <Cloud color="white" />,
    className: 'enhanced-button',
  },
  {
    label: 'Warto wiedzieć',
    link: '/warto-wiedziec',
    icon: <Bulb color="white" />,
    className: 'green-button',
    dropdownElements: [
      {
        label: 'Samorządy',
        link: '/warto-wiedziec/samorzady',
      },
      {
        label: 'Szkoły Publiczne',
        link: '/warto-wiedziec/szpubliczne',
      },
      {
        label: 'Kadry ',
        link: '/warto-wiedziec/kadry',
      },
    ],
  },
  {
    label: 'E-lekcje',
    link: '/produkty/e-lekcje',
    icon: <School color="white" />,
    className: 'yellow-button',
  },
];

export { menuItems, lowerMenuItems };
