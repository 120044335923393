import React, { useContext } from 'react';
import {
  Step,
  Stepper,
  StepContent,
  StepLabel,
  Checkbox,
  TextField,
} from 'material-ui';
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';
import { browserHistory } from 'react-router';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/react-hooks';
import './BasketView.scss';
import { UserForm, ShippingAddressForm } from '@ecrklex/components';
import { calculateGrossPrice } from '../../utils/product';
import { Loading } from '../../utils/loading';
import { AppContext } from '../../common/AppContext';
import withGraphqlHook from '../../common/withGraphqlHook';
import getProductsByIdsQuery from '../../graphql/queries/getProductsByIds.graphql';
import getSchoolProductsByIdsQuery from '../../graphql/queries/getSchoolProductsByIds.graphql';
import getVatRatesQuery from '../../graphql/queries/getVatRatesQuery.graphql';
import updateUserProfileMutation from '../../graphql/mutations/updateUserProfile.graphql';
import placeOrderMutation from '../../graphql/mutations/placeOrder.graphql';
import BasketProductsList from '../BasketComponents/BasketProductsList/BasketProductsList';
import BasketSummary from '../basketSummary';
import { cleanUserProfile } from '../../utils/user';

class BasketView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stepIndex: 0,
      items: this.props.items,
      editUser: !this.props.hasContactPerson,
      paperInvoice: false,
      orderNotes: '',
      ordering: false,
      shippingAddressValid: false,
      shippingMethod: {},
      shippingAdress: {
        name: '',
        zipCode: '',
        city: '',
        adress: '',
      },
      errors: {
        zipCode: '',
        name: '',
        city: '',
        adress: '',
      },
    };

    this.handleNext = this.handleNext.bind(this);
    this.handlePrev = this.handlePrev.bind(this);
    this.handleUserFormSubmit = this.handleUserFormSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { items } = nextProps;
    this.setState({ items });
  }

  static contextType = AppContext;

  setProductDate = (index, date) => {
    const items = [...this.state.items];

    items[index].displayDate = date;

    this.setState({ items });
  };

  validateShippingAddress = () => {
    const { shippingAdress, errors } = this.state;
    let isFormValid = true;
    Object.entries(shippingAdress).forEach(([key, value]) => {
      if (key) errors[key] = value ? '' : 'Pole wymagane';
    });

    Object.values(errors).forEach((value) => {
      if (value && isFormValid) {
        isFormValid = false;
      }
    });

    this.setState({ errors });
    return isFormValid;
  };

  changeShippingAddressValue = (event) => {
    const { value } = event.target;
    const key = event.target.name;
    const shippingAdress = { ...this.state.shippingAdress };
    const errors = { ...this.state.errors };

    shippingAdress[key] = value;
    errors[key] = '';

    this.setState({ errors, shippingAdress });
  };

  changeShippingAddress = (address) => {
    this.setState({ shippingAdress: address });
  };

  handleNext() {
    const { stepIndex } = this.state;
    const { hasSchoolProducts } = this.props;
    if (stepIndex === 0 && !this.props.user) {
      const redirectTo = window.location.pathname;
      this.context.setRedirectTo(redirectTo);
      browserHistory.push('/login');
    }

    if (stepIndex === 1) {
      if (!this.userForm.validateForm()) return;
      this.userForm.handleSubmit();
    }

    if (stepIndex === 2) {
      if (!this.validateShippingAddress()) return;
    }

    let nextStep = stepIndex + 1;
    if (stepIndex === 1 && !hasSchoolProducts) nextStep = stepIndex + 2;

    this.setState({
      stepIndex: nextStep,
    });
  }

  handlePrev() {
    const { stepIndex } = this.state;
    const { hasSchoolProducts } = this.props;

    if (stepIndex === 3 && !hasSchoolProducts) {
      this.setState({ stepIndex: stepIndex - 2 });
      return;
    }

    if (stepIndex > 0) {
      this.setState({ stepIndex: stepIndex - 1 });
    }
  }

  modifyCartItem = (index, item) => {
    const items = [...this.state.items];
    items[index] = item;
    this.setState({ items });
  };

  changeShippingMethod = (event, index, name) => {
    const shippingMethod = this.props.shippingMethods.find(
      (method) => method.name === name,
    );

    this.setState({
      shippingMethod,
    });
  };

  placeOrder = async () => {
    this.setState({
      ordering: true,
    });
    const orderItems = [...this.state.items].map((item) => {
      const { text, ...rest } = item;
      if (item.priceGross) {
        return rest;
      }
      return {
        ...rest,
        priceGross: item.price,
        vat: Math.round(item.vat * 100) / 100,
      };
    });

    await this.props.placeOrder({
      variables: {
        input: {
          items: orderItems,
          paperInvoice: this.state.paperInvoice,
          orderNotes: this.state.orderNotes,
          shippingMethod: this.state.shippingMethod,
          shippingAddress: this.state.shippingAdress,
        },
      },
    });
    browserHistory.push('/');
    this.context.setCart([]);
    this.context.setPack([]);

    this.context.setMessage('Zamówienie zostało złożone. Sprawdź Email');
  };

  handleUserFormSubmit = async (user) => {
    await this.props.updateUserProfile({
      variables: { input: user.profile },
    });
    this.setState({ editUser: false });
  };

  editUser = () => {
    this.setState({ editUser: true });
  };

  handleShippingAdressSubmit = (shippingAdress) => {
    this.setState({ shippingAdress });
    this.setState({ editShippingAdress: false });
  };

  editShippingAdress = () => {
    this.setState({ editShippingAdress: true });
  };

  setPaperInvoice = (event) => {
    this.setState({
      paperInvoice: !event.target.checked,
    });
  };

  changeOrderNotes = (event) => {
    this.setState({
      orderNotes: event.target.value,
    });
  };

  renderStepActions(stepIndex) {
    return (
      <div className="l-basket__actions">
        {stepIndex === 3 ? (
          <React.Fragment>
            <Checkbox
              label="Wyrażam zgodę na otrzymanie faktury w wersji elektronicznej, wystawionej przez ECRK Lex s.c. z siedzibą w Białymstoku przy al. Jana Pawła II 59/43 na dane podane w formularzu i wysłanie jej na adres email podany w zgłoszeniu."
              checked={!this.state.paperInvoice}
              onCheck={this.setPaperInvoice}
            />
            <br />
            <small>
              Faktura zostanie wysłana w oddzielnej wiadomość. Jeśli jej nie
              widzisz, sprawdź SPAM.
            </small>
            <small>
              RODO - ZMIANY W PRZEPISACH.
              <br />
              <br />
              Zgodnie z art. 13 ogólnego rozporządzenia o ochronie danych
              osobowych z dnia 27 kwietnia 2016 r. (Dz. Urz. UE L 119 z
              04.05.2016) informujemy, iż:
              <ol>
                <small>
                  <li>
                    Administratorem Pani/Pana danych osobowych jest ECRK Lex
                    s.c. z siedzibą w Białymstoku przy Al. Jana Pawła II 59/43.
                  </li>
                  <li>
                    Pani/Pana dane osobowe przetwarzane będą w celu realizacji
                    zamówienia - na podstawie Art. 6 ust. 1 lit. b ogólnego
                    rozporządzenia o ochronie danych osobowych z dnia 27
                    kwietnia 2016 r.
                  </li>
                  <li>
                    Odbiorcami Pani/Pana danych osobowych będą podmioty
                    uczestniczące w realizacji zamówienia - ECRK Lex s.c.,
                    EduLex, Prolegis Elżbieta Linowska.
                  </li>
                  <li>
                    Pani/Pana dane osobowe przechowywane będą przez okres 6 lat.
                  </li>
                  <li>
                    Posiada Pani/Pan prawo do żądania od administratora dostępu
                    do danych osobowych, prawo do ich sprostowania, usunięcia
                    lub ograniczenia przetwarzania oraz prawo do przenoszenia
                    danych.
                  </li>
                  <li>
                    Ma Pani/Pan prawo wniesienia skargi do organu nadzorczego.
                  </li>
                  <li>
                    Podanie danych osobowych jest dobrowolne, jednakże odmowa
                    podania danych może skutkować odmową realizacji zamówienia.
                  </li>
                </small>
              </ol>
            </small>
          </React.Fragment>
        ) : null}
        <div style={{ marginTop: 12 }}>
          {this.state.ordering ? (
            <Loading />
          ) : (
            <React.Fragment>
              <FlatButton
                label="Wstecz"
                disabled={stepIndex === 0}
                onClick={this.handlePrev}
                style={{ marginRight: 12 }}
              />
              {stepIndex === 1 || stepIndex === 2 ? (
                <RaisedButton
                  label="Edytuj"
                  primary
                  onClick={
                    stepIndex === 1 ? this.editUser : this.editShippingAdress
                  }
                  style={{ marginRight: 12 }}
                />
              ) : null}
              <RaisedButton
                label={stepIndex === 3 ? 'Zamawiam' : 'Dalej'}
                primary
                disabled={
                  (stepIndex === 0 && this.state.items.length === 0) ||
                  (this.props.hasSchoolProducts &&
                    !this.state.shippingMethod.name)
                }
                onClick={stepIndex === 3 ? this.placeOrder : this.handleNext}
              />
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }

  render() {
    const { stepIndex, items, shippingMethod } = this.state;
    const lastStep = stepIndex === 3;

    return (
      <div style={{ marginTop: 20 }}>
        <div>
          <div className="l-basket__content">
            <Stepper activeStep={stepIndex} orientation="vertical">
              <Step>
                <StepLabel>Koszyk</StepLabel>
                <StepContent>
                  <BasketProductsList
                    lastStep={lastStep}
                    shippingMethod={shippingMethod}
                    changeShippingMethod={this.changeShippingMethod}
                    shippingMethods={this.props.shippingMethods}
                    items={items}
                    loading={this.props.loading}
                    products={this.props.products}
                  />
                  {this.renderStepActions(0)}
                </StepContent>
              </Step>
              <Step>
                <StepLabel>Dane klienta</StepLabel>
                <StepContent>
                  <UserForm
                    submitButton={this.state.editUser}
                    ref={(node) => {
                      this.userForm = node;
                    }}
                    onSubmit={this.handleUserFormSubmit}
                    user={this.props.user}
                    displayOnly={!this.state.editUser}
                    submit
                  />
                  {this.state.editUser ? null : this.renderStepActions(1)}
                </StepContent>
              </Step>
              <Step>
                <StepLabel>Dane do wysyłki</StepLabel>
                <StepContent>
                  <ShippingAddressForm
                    user={this.props.user}
                    shippingAdress={this.state.shippingAdress}
                    ref={(node) => {
                      this.shippingAdressForm = node;
                    }}
                    changeShippingAddressValue={this.changeShippingAddressValue}
                    submit
                    errors={this.state.errors}
                    changeShippingAddress={this.changeShippingAddress}
                  />
                  <React.Fragment>
                    <FlatButton
                      label="Wstecz"
                      disabled={stepIndex === 0}
                      onClick={this.handlePrev}
                      style={{ marginRight: 12 }}
                    />
                    <RaisedButton
                      label={'Dalej'}
                      primary
                      disabled={this.state.shippingAddressValid}
                      onClick={this.handleNext}
                    />
                  </React.Fragment>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>Podsumowanie</StepLabel>
                <StepContent>
                  <BasketSummary
                    items={items}
                    user={this.props.user}
                    shippingMethod={shippingMethod}
                    products={this.props.products}
                  />
                  <TextField
                    floatingLabelText="Uwagi do zamówienia"
                    fullWidth
                    onChange={this.changeOrderNotes}
                    multiLine
                    name="orderNotes"
                    rows={4}
                    rowsMax={8}
                  />
                  {this.renderStepActions(3)}
                </StepContent>
              </Step>
            </Stepper>
          </div>
        </div>
      </div>
    );
  }
}

export default withGraphqlHook(() => {
  const { user: contextUser, cart, pack, currentDates, setUser } = useContext(
    AppContext,
  );
  const user = cleanUserProfile(contextUser);

  const [updateUserProfile] = useMutation(updateUserProfileMutation, {
    onCompleted: (data) => {
      const newUser = { ...contextUser, profile: data.updateUserProfile };
      setUser(newUser);
    },
  });

  const [placeOrder] = useMutation(placeOrderMutation);

  const hasContactPerson = user && !!user.profile.contactPersonName;
  const hasSchoolProducts = !!cart.find(
    (product) => product.type === 'schoolProduct',
  );
  const shippingMethods = [{ name: 'Kurier DHL', price: 19, vat: 3.55 }];
  const items = [];
  let products = [];
  const schoolProductIds = cart
    .filter((product) => product.type === 'schoolProduct')
    .map((product) => product.id);
  const productIds = cart
    .filter((product) => product.type !== 'schoolProduct')
    .map((product) => product.id)
    .concat(pack);

  const { data: productsData, loading: productsLoading } = useQuery(
    getProductsByIdsQuery,
    {
      variables: {
        ids: productIds,
      },
    },
  );

  const { data: schoolProductsData, loading: schoolProductsLoading } = useQuery(
    getSchoolProductsByIdsQuery,
    {
      variables: {
        ids: schoolProductIds,
      },
    },
  );

  const { data: vatData, loading: vatLoading } = useQuery(getVatRatesQuery);

  const loading = productsLoading || schoolProductsLoading || vatLoading;

  if (!loading) {
    cart.forEach((cartProduct) => {
      let originalProduct;
      if (cartProduct.type === 'schoolProduct') {
        originalProduct = schoolProductsData.getSchoolProductsByIds.find(
          (product) => product._id === cartProduct.id,
        );
      } else {
        originalProduct = productsData.getProductsByIds.find(
          (product) => product._id === cartProduct.id,
        );
      }

      const currentProduct = { ...originalProduct, ...cartProduct };
      const vat = vatData.getVatRates.find(
        (vatEntry) => vatEntry._id === currentProduct.vatRate,
      );
      const vatRate = vat ? vat.rate : 0;

      if (
        currentProduct.type === 'schoolProduct' &&
        currentProduct.size &&
        currentProduct.size.priceIncrease
      ) {
        currentProduct.price += currentProduct.size.priceIncrease;
      }
      if (currentProduct.type !== 'schoolProduct') {
        currentProduct.vat =
          parseInt(currentProduct.price * vatRate * 100, 10) / 100;
        currentProduct.priceGross = calculateGrossPrice({
          product: currentProduct,
          vatRates: [vat],
        });
      }

      if (currentProduct.type === 'pack') {
        currentProduct.content = pack;
        currentProduct.displayDates = {};
        currentProduct.content.forEach((item) => {
          currentProduct.displayDates[item] = currentDates[item];
        });
      }
      if (currentProduct.hasDisplayDate) currentProduct.displayDate = undefined;
      items.push(currentProduct);
    });
    products = [
      ...schoolProductsData.getSchoolProductsByIds,
      ...productsData.getProductsByIds,
    ];
  }

  return {
    items,
    loading,
    user,
    hasContactPerson,
    hasSchoolProducts,
    shippingMethods,
    products,
    updateUserProfile,
    placeOrder,
  };
}, BasketView);

BasketView.propTypes = {
  items: PropTypes.array.isRequired,
  user: PropTypes.object,
  hasContactPerson: PropTypes.bool,
  hasSchoolProducts: PropTypes.bool.isRequired,
  shippingMethods: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      price: PropTypes.number,
    }),
  ).isRequired,
  shippingAdress: PropTypes.shape({
    name: PropTypes.string,
    adress: PropTypes.string,
    city: PropTypes.string,
    zipCode: PropTypes.string,
  }),
};

BasketView.defaultProps = {
  hasContactPerson: false,
  user: null,
  shippingAdress: {
    name: '',
    adress: '',
    city: '',
    zipCode: '',
  },
};
