import React from 'react';
import './InfoPanel.scss';
import Info from 'material-ui/svg-icons/action/info-outline';
import Warning from 'material-ui/svg-icons/alert/warning';
import PropTypes from 'prop-types';

export default class InfoPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      additional: '',
    };
  }

  componentDidMount() {
    if (this.props.fade) {
      setTimeout(() => {
        this.setState({ additional: 'no-display' });
        if (this.props.clearErrorCb) {
          this.props.clearErrorCb();
        }
      }, 3000);
    }
  }

  render() {
    const { additional } = this.state;
    const icon = this.props.role === 'info' ? <Info /> : <Warning />;
    return (
      <div className={`panel ${this.props.role} ${additional}`}>
        {icon} {this.props.children}
      </div>
    );
  }
}

InfoPanel.propTypes = {
  role: PropTypes.string.isRequired,
  fade: PropTypes.bool,
  clearErrorCb: PropTypes.func,
};

InfoPanel.defaultProps = {
  fade: true,
  clearErrorCb: () => {},
};
