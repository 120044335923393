import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import RaisedButton from 'material-ui/RaisedButton';
import ShareIcon from 'material-ui/svg-icons/social/share';
import { CardText, CircularProgress } from 'material-ui';
import shareTrainingMutation from '../../graphql/mutations/shareTraining.graphql';

const TrainingShare = ({ shareKey, userDocumentId, refetch }) => {
  const [shareTraining, { loading }] = useMutation(shareTrainingMutation, {
    onCompleted: () => {
      refetch();
    },
    variables: { userDocumentId },
  });

  const url = `https://player.ecrklex.pl/#/${shareKey}`;

  if (shareKey) {
    return (
      <CardText style={{ marginTop: 30 }}>
        Link do udostępnienia:{' '}
        <a
          style={{ color: 'rgb(40, 174, 115)' }}
          href={url}
          target="_blank"
          rel="noreferrer"
        >
          {url}
        </a>
      </CardText>
    );
  }

  return (
    <CardText>
      <RaisedButton
        onClick={shareTraining}
        label="Udostępnij wideo"
        primary
        disabled={loading}
        icon={loading ? <CircularProgress size={14} /> : <ShareIcon />}
      />
    </CardText>
  );
};

TrainingShare.propTypes = {
  shareKey: PropTypes.string,
  userDocumentId: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
};

TrainingShare.defaultProps = {
  shareKey: null,
};

export default TrainingShare;
