import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { SingleParticipantCertificate } from '@ecrklex/components';
import { CardText } from 'material-ui';
import { aggregatorRoute } from '../../conf';
import { AppContext } from '../../common/AppContext';

const OnlineTrainingCertificates = ({ certificates }) => {
  const { user } = useContext(AppContext);

  return (
    <Fragment>
      <CardText style={{ fontWeight: 'bold' }}>
        Zaświadczenia o uczestnictwie w szkoleniu:
      </CardText>
      {certificates.map((cert, index) => {
        return (
          <SingleParticipantCertificate
            key={cert._id}
            index={index}
            cert={cert}
            aggregatorRoute={aggregatorRoute}
            userToken={user.token}
          />
        );
      })}
    </Fragment>
  );
};

OnlineTrainingCertificates.propTypes = {
  certificates: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      _id: PropTypes.string,
    }),
  ),
};

OnlineTrainingCertificates.defaultProps = {
  certificates: [],
};

export default OnlineTrainingCertificates;
