import React from 'react';
import PropTypes from 'prop-types';

export default function BasketPriceSummary(props) {
  const { brutSum, netSum, vatSum, shipping } = props.prices;
  return (
    <div className="col-lg-5 col-lg-offset-7">
      <div className="col-lg-2">
        <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
          Suma:
        </span>
        <br />
        <span style={{ fontWeight: 'bold' }}>Brutto</span>
        <br />
        <span style={{ fontWeight: 'bold' }}>Netto</span>
        <br />
        <span style={{ fontWeight: 'bold' }}>VAT</span>
        <br />
        <br />
        {!!shipping && <span style={{ fontWeight: 'bold' }}>Dostawa</span>}
      </div>
      <div className="col-lg-10">
        <br />
        <span>{brutSum} zł</span>
        <br />
        <span>{netSum} zł</span>
        <br />
        <span>{vatSum} zł</span>
        <br />
        <br />
        {!!shipping && <span>{shipping} zł</span>}
      </div>
    </div>
  );
}

BasketPriceSummary.propTypes = {
  prices: PropTypes.object.isRequired,
};
