import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import { UserForm } from '@ecrklex/components';
import updateUserProfileMutation from '../../../graphql/mutations/updateUserProfile.graphql';
import { AppContext } from '../../../common/AppContext';

const Step2 = ({
  isUserBeingEdited,
  setIsUserBeingEdited,
  stepActions,
  user,
}) => {
  const { setUser, user: contextUser } = useContext(AppContext);
  const [updateUserProfile] = useMutation(updateUserProfileMutation, {
    onCompleted: (data) => {
      const newUser = { ...contextUser, profile: data.updateUserProfile };
      setIsUserBeingEdited(false);
      setUser(newUser);
    },
  });

  const handleUserFormSubmit = async (editedUser) => {
    await updateUserProfile({ variables: { input: editedUser.profile } });
  };

  return (
    <>
      <UserForm
        submitButton={isUserBeingEdited}
        onSubmit={handleUserFormSubmit}
        user={user}
        displayOnly={!isUserBeingEdited}
        submit
      />
      {stepActions}
    </>
  );
};

Step2.propTypes = {
  isUserBeingEdited: PropTypes.bool.isRequired,
  setIsUserBeingEdited: PropTypes.func.isRequired,
  stepActions: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default Step2;
