import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import get from "lodash/get";
import moment from "moment";
import { Card, CardTitle, CardText } from "material-ui";
import { Loading } from "../../utils/loading";
import "./index.scss";
import FileList from "../fileList/fileList";
import useMetaKeyword from "../../hooks/useMetaKeyword";
import useGenerateSubpageTitle from "../../hooks/useGenerateSubpageTitle";
import getLegalActQuery from "../../graphql/queries/getLegalAct.graphql";

const SingleLegalActView = props => {
  const { data, loading } = useQuery(getLegalActQuery, {
    variables: { slug: props.params.legalActId },
  });
  const legalAct = get(data, "getLegalAct", null);

  useGenerateSubpageTitle(legalAct);
  useMetaKeyword(legalAct);

  if (loading) {
    return <Loading />;
  }

  return (
    <Card>
      <br />
      <br />
      <div className="legalact-title row">
        <div className="col-lg-7 col-md-12">
          <CardTitle
            title={legalAct.title}
            className="title-text"
            subtitle={
              legalAct.date
                ? `Data aktu: ${moment(legalAct.date).format("DD-MM-YYYY")}`
                : null
            }
          />
        </div>
        <div className="col-lg-5 hidden-md hidden-xs">
          <img src={legalAct.imageSrc} className="product-image" alt="image" />
        </div>
      </div>
      <CardText>
        <div dangerouslySetInnerHTML={{ __html: legalAct.text }} />
      </CardText>
      <FileList productId={legalAct._id} display isLegalAct={true} />
    </Card>
  );
};

export default SingleLegalActView;

SingleLegalActView.propTypes = {
  params: PropTypes.shape({
    legalActId: PropTypes.string,
  }).isRequired,
};
