import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import getGoodToKnowQuery from '../../graphql/queries/getGoodToKnow.graphql';
import { Loading } from '../../utils/loading';
import SingleNews from '../singleNews';

export const GoodToKnowPage = ({ params: { category } }) => {
  const { loading, data } = useQuery(getGoodToKnowQuery, {
    variables: { category },
  });
  if (loading) {
    return <Loading />;
  }

  return (
    <Fragment>
      {data &&
        data.getGoodToKnow.map((goodToKnowItem) => {
          return (
            <div key={goodToKnowItem._id} className="news-list__news">
              <SingleNews
                {...goodToKnowItem}
                createdAt={moment(goodToKnowItem.createdAt.unix, 'x').format(
                  'DD-MM-YYYY',
                )}
                link={`/warto-wiedziec/artykul/${goodToKnowItem.slug}`}
              />
            </div>
          );
        })}
    </Fragment>
  );
};

GoodToKnowPage.propTypes = {
  params: PropTypes.shape({
    category: PropTypes.string,
  }).isRequired,
};

GoodToKnowPage.defaultProps = {};
