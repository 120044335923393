import React from 'react';
import PropTypes from 'prop-types';
import { CardTitle } from 'material-ui/Card';
import BasketProductsList from '../BasketComponents/BasketProductsList/BasketProductsList';
import './index.scss';

const BasketSummary = ({ user, items, shippingMethod, products }) => (
  <div className="basket-summary">
    <div className="row">
      <div className="col-md-6">
        <CardTitle className="basket-summary__title">Dane odbiorcy</CardTitle>
        {user.profile.name}
        <br />
        {user.profile.address}
        <br />
        {user.profile.city} {user.profile.zipCode}
        <br />
        {user.profile.buyerData ? null : `NIP: ${user.profile.nip}`}
        <br />
        Tel: {user.profile.contactPersonPhone}
        <br />
        Email do faktury: {user.profile.invoiceEmail}
      </div>
      {user.profile.buyerData ? (
        <div className="col-md-6">
          <CardTitle className="basket-summary__title">Dane nabywcy</CardTitle>
          {user.profile.buyerData.name}
          <br />
          {user.profile.buyerData.address}
          <br />
          {user.profile.buyerData.city} {user.profile.buyerData.zipCode}
          <br />
          NIP: {user.profile.buyerData.nip}
          <br />
        </div>
      ) : null}
    </div>
    <div className="basket-summary__basket-products-list">
      <BasketProductsList
        items={items}
        lastStep={true}
        display
        shippingMethod={shippingMethod}
        products={products}
      />
    </div>
  </div>
);

BasketSummary.propTypes = {
  user: PropTypes.shape({
    profile: PropTypes.shape({
      name: PropTypes.string,
      address: PropTypes.string,
      city: PropTypes.string,
      zipCode: PropTypes.string,
      nip: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      phone: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      buyerData: PropTypes.shape({
        name: PropTypes.string,
        address: PropTypes.string,
        city: PropTypes.string,
        zipcode: PropTypes.string,
        nip: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }),
    }),
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
      price: PropTypes.number,
      vatRate: PropTypes.string,
      type: PropTypes.string,
      categories: PropTypes.arrayOf(PropTypes.string),
      thumbnail: PropTypes.string,
      preview: PropTypes.string,
    }),
  ),
};

BasketSummary.defaultProps = {
  items: [],
  user: null,
};

export default BasketSummary;
