import React from 'react';
import PropTypes from 'prop-types';
import { Divider, List } from 'material-ui';
import './SchoolProductsFilter.scss';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import CategoryContext from '../CategoriesFilter/CategoryContext';
import getCategoriesQuery from '../../graphql/queries/getCategoriesWithCount.graphql';
import { Category } from './Category';

function SchoolProductsFilter() {
  const { data } = useQuery(getCategoriesQuery, {
    variables: { type: 'schoolProduct' },
  });
  const categories = get(data, 'getCategories', []);
  const topLevel = categories.filter((category) => category.level === 1);
  const subcategories = categories.filter(
    (category) => category.level === 2 || category.level === 3,
  );
  return (
    <div className="categories-filter">
      <CategoryContext.Consumer>
        {(value) => (
          <List>
            {topLevel.map((category) => {
              return (
                <React.Fragment key={category._id}>
                  <Category
                    {...category}
                    categories={categories}
                    subcategories={subcategories}
                    key={category._id}
                    pathName={value.pathName}
                    amounts={category.productsCount}
                  />
                  <Divider />
                </React.Fragment>
              );
            })}
          </List>
        )}
      </CategoryContext.Consumer>
    </div>
  );
}

export default SchoolProductsFilter;

SchoolProductsFilter.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      children: PropTypes.arrayOf(PropTypes.string),
      level: PropTypes.number,
      name: PropTypes.string,
      slug: PropTypes.string,
      friendlySlugs: PropTypes.shape({
        slug: PropTypes.shape({
          base: PropTypes.string,
          index: PropTypes.number,
        }),
      }),
    }),
  ),
  productsAmountInCategory: PropTypes.object.isRequired,
};

SchoolProductsFilter.defaultProps = {
  categories: [],
};
