import React from 'react';
import { TextField } from 'material-ui';
import PropTypes from 'prop-types';
import {
  OnlineTrainingParticipants,
  ShippingAddressForm,
} from '@ecrklex/components';
import InfoPanel from '../../../utils/InfoPanel/InfoPanel';

const styles = {
  mainDiv: {
    marginTop: '12px',
    marginLeft: '12px',
  },
};

const Step3 = ({
  notice,
  setNotice,
  shippingAddress,
  setShippingAddress,
  stepActions,
  errors,
  setErrors,
  requireShippingAddress,
  participants,
  setParticipants,
  price,
  totalPrice,
  user,
  isProlegis,
}) => {
  const changeShippingAddressValue = (event) => {
    const { value, name } = event.target;
    const newShippingAddress = { ...shippingAddress };

    newShippingAddress[name] = value;

    setErrors({ ...errors, [name]: '' });
    setShippingAddress(newShippingAddress);
  };

  const changeShippingAddress = (address) => {
    setErrors({
      zipCode: '',
      name: '',
      city: '',
      address: '',
      email: errors.email,
    });
    setShippingAddress(address);
  };

  const clearParticipantsError = () => {
    setErrors({ ...errors, participants: '' });
  };

  return (
    <div style={styles.mainDiv}>
      {requireShippingAddress && (
        <>
          W ramach szkolenia, zostaną do Państwa wysłane fizyczne materiały
          szkoleniowe.
          <ShippingAddressForm
            user={user}
            shippingAdress={shippingAddress}
            changeShippingAddressValue={changeShippingAddressValue}
            submit
            errors={errors}
            changeShippingAddress={changeShippingAddress}
          />
        </>
      )}
      <br />
      <strong>Uczestnicy</strong>
      <OnlineTrainingParticipants
        changeParticipants={setParticipants}
        participants={participants}
        isProlegis={isProlegis}
      />
      {errors.participants && (
        <InfoPanel role="error" fade clearErrorCb={clearParticipantsError}>
          {errors.participants}
        </InfoPanel>
      )}
      <br /> <br />
      <div className="price-tag">
        Cena: {price}zł
        <hr />
        Cena końcowa: {totalPrice}zł
      </div>
      <TextField
        hintText="Uwagi"
        multiLine={true}
        rows={2}
        rowsMax={4}
        name="notice"
        fullWidth
        onChange={(e) => setNotice(e.target.value)}
        value={notice}
      />
      <br />
      {stepActions}
    </div>
  );
};

Step3.defaultProps = {
  user: {},
};

Step3.propTypes = {
  notice: PropTypes.string.isRequired,
  setNotice: PropTypes.func.isRequired,
  stepActions: PropTypes.object.isRequired,
  shippingAddress: PropTypes.object.isRequired,
  setShippingAddress: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    zipCode: PropTypes.string,
    name: PropTypes.string,
    city: PropTypes.string,
    address: PropTypes.string,
    email: PropTypes.string,
    participants: PropTypes.string,
    provider: PropTypes.string,
  }).isRequired,
  setErrors: PropTypes.func.isRequired,
  requireShippingAddress: PropTypes.bool.isRequired,
  participants: PropTypes.array.isRequired,
  setParticipants: PropTypes.func.isRequired,
  price: PropTypes.number.isRequired,
  totalPrice: PropTypes.number.isRequired,
  user: PropTypes.object,
  isProlegis: PropTypes.bool.isRequired,
};

export default Step3;
