import moment from "moment";

const defaultFormat = "DD-MM-YYYY HH:mm";

const getTrainingDateRange = (startDate, endDate) => {
  const start = moment(startDate, defaultFormat).format("HH:mm");
  const end = moment(endDate, defaultFormat).format("HH:mm");
  return `${start} - ${end}`;
};

export default getTrainingDateRange;
