import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { RaisedButton } from 'material-ui';
import { browserHistory } from 'react-router';
import PropTypes from 'prop-types';
import Panel from '../common/Panel';
import getLatestProductsQuery from '../../graphql/queries/getLatestProducts.graphql';
import loadingPublication from './LoadingPublications';

const HomeTrainings = ({ isMobile }) => {
  const { data, loading } = useQuery(getLatestProductsQuery, {
    variables: {
      type: 'training',
    },
  });
  if (!data) {
    return null;
  }
  const documents = data.getLatestProducts;

  return (
    <Panel title="Oferta e-szkoleń" backgroundColor="#7595c7">
      <div
        className="hp-wrapper"
        style={{ flexDirection: `${isMobile ? 'column' : 'row'}` }}
      >
        {!loading
          ? documents.map((document) => (
              <div
                className="hp-singlehome hp-singlehomethumbnail hp-singlehometraining"
                key={document._id}
                style={{ width: `${isMobile ? '100%' : '25%'}` }}
              >
                <div className="hp-publicationimage">
                  <img src={document.imageSrc} alt={document.title} />
                </div>
                <div className="hp-publicationtitle">{document.title}</div>
                <div className="hp-publicationdescription">
                  {document.short}
                </div>
                <RaisedButton
                  onClick={() =>
                    browserHistory.push(`/produkty/${document.slug}`)
                  }
                  label="Zobacz"
                  backgroundColor="#7595C7"
                  labelColor="#fff"
                />
              </div>
            ))
          : [...Array(4)].map((item, index) =>
              loadingPublication(index, isMobile),
            )}
      </div>
      <div className="hp-seemore">
        <RaisedButton
          backgroundColor="#7595C7"
          labelColor="#fff"
          label="Zobacz wszystkie"
          onClick={() => browserHistory.push('/produkty/e-szkolenia')}
        />
      </div>
    </Panel>
  );
};

HomeTrainings.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

export default HomeTrainings;
