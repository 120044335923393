import React from 'react';
import PropTypes from 'prop-types';

export default function RedNotificationDot({ offset }) {
  return (
    <div
      style={{
        position: 'absolute',
        textAlign: 'right',
        height: '10px',
        width: '10px',
        left: 'auto',
        right: `${offset}px`,
        top: `${offset}px`,
        borderRadius: '50%',
        backgroundColor: '#f12605',
      }}
    />
  );
}

RedNotificationDot.propTypes = {
  offset: PropTypes.number,
};

RedNotificationDot.defaultProps = {
  offset: 2,
};
