import PropTypes from 'prop-types';
import { Card, CardText, CardTitle } from 'material-ui';
import React, { useEffect } from 'react';
import get from 'lodash/get';
import { useQuery } from '@apollo/react-hooks';
import { Loading } from '../../utils/loading';
import { generateSubpageTitle } from '../../utils/generatePageTitle';
import useMetaKeyword from '../../hooks/useMetaKeyword';
import getSingleNewsQuery from '../../graphql/queries/getSingleNews.graphql';
import './index.scss';

const SingleNewsView = (props) => {
  useMetaKeyword(props.news);

  useEffect(() => {
    if (props.news) {
      generateSubpageTitle(this.props.news.title);
    }
  }, []);

  const { data, loading } = useQuery(getSingleNewsQuery, {
    variables: { slug: props.params.newsSlug },
  });
  const news = get(data, 'getSingleNews', null);

  if (loading) return <Loading />;

  const imageSrc = news && news.imageSrc;
  const title = news && news.title;
  const text = news && news.text;

  return (
    <div style={{ marginTop: 20 }}>
      <Card>
        <br />
        <br />
        <div className="legalact-title row">
          <div className="col-lg-7 col-md-12">
            <CardTitle title={title} className="title-text" />
          </div>
          <div className="col-lg-5 hidden-md hidden-xs">
            <img src={imageSrc} className="product-image" alt="image" />
          </div>
        </div>
        <CardText
          className="single-news-content"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </Card>
    </div>
  );
};

export default SingleNewsView;

SingleNewsView.propTypes = {
  params: PropTypes.shape({
    newsSlug: PropTypes.string,
  }).isRequired,
};
