import React, { useContext, useState } from 'react';
import { Step, StepLabel, StepContent, Stepper } from 'material-ui';
import { useMutation, useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { Loading } from '../../utils/loading';
import useGenerateSubpageTitle from '../../hooks/useGenerateSubpageTitle';
import getOnlineTrainingWithDatesQuery from '../../graphql/queries/getOnlineTrainingWithDates.graphql';
import createOnlineTrainingRegistrationMutation from '../../graphql/mutations/createOnlineTrainingRegistration.graphql';
import { AppContext } from '../../common/AppContext';
import { cleanUserProfile } from '../../utils/user';
import StepActions from './Steps/StepActions';
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';
import Step4 from './Steps/Step4';
import './OnlineTrainingRegistrationForm.scss';

const vatRate = 0.23;
const providers = {
  prolegis: 'ODN PROLEGIS',
  ecrk: 'ECRK Lex',
};

const eInvoiceApprovals = {
  ecrk: 'Wyrażam zgodę na otrzymanie faktury elektronicznej, wystawionej przez ECRK Lex s.c z siedzibą w Białymstoku przy al. Jana Pawła II 59/43 na dane podane w formularzu i wysłanie jej na adres email podany w zgłoszeniu.',
  prolegis:
    'Wyrażam zgodę na otrzymanie faktury elektronicznej, wystawionej przez ODN Prolegis Elżbieta Linowska z siedzibą w Białymstoku przy al. Jana Pawła II 59/43 na dane podane w formularzu i wysłanie jej na adres email podany w zgłoszeniu.',
};

const requiredParticipantFields = {
  ecrk: ['name', 'phone', 'email'],
  prolegis: ['title', 'name', 'phone', 'email', 'dateOfBirth', 'placeOfBirth'],
};

const OnlineTrainingRegistrationForm = ({ routeParams: { slug, dateId } }) => {
  const [stepIndex, setStepIndex] = useState(0);
  const [isUserBeingEdited, setIsUserBeingEdited] = useState(false);
  const [notice, setNotice] = useState('');
  const [isPublic, setIsPublic] = useState(false);
  const [eInvoice, setEInvoice] = useState(true);
  const [participants, setParticipants] = useState([]);
  const [selectedProvider, setProvider] = useState('');
  const {
    user: contextUser,
    setMessage,
    setRedirectTo,
  } = useContext(AppContext);
  const user = cleanUserProfile(contextUser);
  const isProlegis = providers[selectedProvider] === providers.prolegis;

  const [errors, setErrors] = useState({
    zipCode: '',
    name: '',
    city: '',
    adress: '',
    email: '',
    provider: '',
  });
  const userAddress = user
    ? {
        zipCode: user.profile.zipCode,
        name: user.profile.name,
        city: user.profile.city,
        adress: user.profile.address,
      }
    : {
        zipCode: '',
        name: '',
        city: '',
        adress: '',
      };
  const [shippingAddress, setShippingAddress] = useState(userAddress);

  const { loading, data } = useQuery(getOnlineTrainingWithDatesQuery, {
    variables: {
      slug,
    },
    fetchPolicy: 'network-only',
  });

  const [createRegistration, { error, loading: inProgress }] = useMutation(
    createOnlineTrainingRegistrationMutation,
  );

  const training = data && data.getOnlineTrainingWithDates;
  useGenerateSubpageTitle(training);
  if (loading) {
    return <Loading />;
  }

  if ((!loading && !training) || (training && training.isArchived)) {
    browserHistory.push('/');
    return null;
  }

  const trainingDate =
    training.dates && training.dates.find((date) => date._id === dateId);

  if (trainingDate && (trainingDate.isArchived || !trainingDate.isActive)) {
    browserHistory.push('/');
    return null;
  }

  const handleNext = () => {
    if (stepIndex === 0 && !user) {
      const redirectTo = window.location.pathname;
      setRedirectTo(redirectTo);
      browserHistory.push('/login');
    }
    const newErrors = { ...errors };
    if (stepIndex === 0) {
      if (!selectedProvider) {
        newErrors.provider = 'Wybierz podmiot wystawiający fakturę';
      }
    }
    if (stepIndex === 2) {
      if (!participants.length) {
        newErrors.participants = 'Dodaj uczestników';
      }

      const areParticipantsComplete = participants.every((participant) =>
        requiredParticipantFields[selectedProvider].every(
          (requiredField) => !!participant[requiredField],
        ),
      );
      if (!areParticipantsComplete) {
        newErrors.participants = 'Uzupełnij dane zapisanych uczestników';
      }

      if (training.requireShippingAddress) {
        Object.keys(shippingAddress).forEach((key) => {
          if (!shippingAddress[key]) newErrors[key] = 'Pole wymagane';
        });
      }
    }
    const formValid = !Object.values(newErrors).some((value) => !!value);
    if (!formValid) {
      setErrors(newErrors);
      return;
    }
    setStepIndex(stepIndex + 1);
  };

  const registerForTraining = async () => {
    const registrationForm = {
      onlineTrainingId: training._id,
      onlineTrainingDateId: dateId,
      public: isPublic,
      notice,
      eInvoice,
      participants,
      provider: selectedProvider.toUpperCase(),
    };

    if (training.requireShippingAddress) {
      const { adress, ...rest } = shippingAddress;
      const fixedShippingAddress = { ...rest, address: adress };
      registrationForm.shippingAddress = fixedShippingAddress;
    }

    await createRegistration({ variables: { registration: registrationForm } });

    if (!error) {
      browserHistory.push('/');
      setMessage('Twoje zgłoszenie zostało wysłane pomyślnie');
    }
  };

  const handlePrev = () => {
    if (stepIndex > 0) {
      setStepIndex(stepIndex - 1);
    }
  };

  const stepActions = (
    <StepActions
      handlePrev={handlePrev}
      handleNext={handleNext}
      stepIndex={stepIndex}
      isUserBeingEdited={isUserBeingEdited}
      setIsUserBeingEdited={setIsUserBeingEdited}
      registerForTraining={registerForTraining}
      inProgress={inProgress}
    />
  );

  const calculatePrice = () => {
    if (isPublic) {
      return training.price;
    }
    const vatPrice = parseInt(training.price * vatRate * 100, 10) / 100;
    return vatPrice + training.price;
  };

  const calculateTotalPrice = () => {
    const unitPrice = calculatePrice();
    const participantsCount = participants.length;
    return unitPrice * participantsCount;
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div style={{ marginTop: 20 }}>
      <div>
        <div className="l-basket__content">
          <Stepper activeStep={stepIndex} orientation="vertical">
            <Step>
              <StepLabel>Szkolenie</StepLabel>
              <StepContent>
                <Step1
                  isPublic={isPublic}
                  setIsPublic={setIsPublic}
                  eInvoice={eInvoice}
                  setEInvoice={setEInvoice}
                  training={training}
                  trainingDate={trainingDate}
                  stepActions={stepActions}
                  price={calculatePrice()}
                  selectedProvider={selectedProvider}
                  setProvider={setProvider}
                  providers={providers}
                  eInvoiceApprovalText={
                    eInvoiceApprovals[selectedProvider] || ''
                  }
                  errors={errors}
                  setErrors={setErrors}
                />
              </StepContent>
            </Step>
            <Step>
              <StepLabel>Dane klienta</StepLabel>
              <StepContent>
                <Step2
                  isUserBeingEdited={isUserBeingEdited}
                  setIsUserBeingEdited={setIsUserBeingEdited}
                  stepActions={stepActions}
                  user={user}
                />
              </StepContent>
            </Step>
            <Step>
              <StepLabel>Dane kontaktowe i uczestnicy</StepLabel>
              <StepContent>
                <Step3
                  stepActions={stepActions}
                  notice={notice}
                  setNotice={setNotice}
                  shippingAddress={shippingAddress}
                  setShippingAddress={setShippingAddress}
                  errors={errors}
                  setErrors={setErrors}
                  requireShippingAddress={training.requireShippingAddress}
                  participants={participants}
                  setParticipants={setParticipants}
                  price={calculatePrice()}
                  totalPrice={calculateTotalPrice()}
                  user={user}
                  isProlegis={isProlegis}
                />
              </StepContent>
            </Step>
            <Step>
              <StepLabel>Podsumowanie</StepLabel>
              <StepContent>
                <Step4
                  shippingAddress={shippingAddress}
                  training={training}
                  stepActions={stepActions}
                  price={calculateTotalPrice()}
                  participants={participants}
                  trainingDate={trainingDate}
                  displayShippingAddress={training.requireShippingAddress}
                  isProlegis={isProlegis}
                />
              </StepContent>
            </Step>
          </Stepper>
        </div>
      </div>
    </div>
  );
};

OnlineTrainingRegistrationForm.propTypes = {
  routeParams: PropTypes.object.isRequired,
};

export default OnlineTrainingRegistrationForm;
