import React, { useState } from 'react';
import { AutoComplete, DatePicker, Dialog, FlatButton } from 'material-ui';
import PropTypes from 'prop-types';
import Intl from 'intl';
import { useMutation } from '@apollo/react-hooks';
import changePackContentMutation from '../../graphql/mutations/changePackContent.graphql';
import InfoPanel from '../../utils/InfoPanel/InfoPanel';

function ProductChangeDialog(props) {
  const [pickedProduct, setPickedProduct] = useState('');
  const [pickedDate, setPickedDate] = useState(null);
  const [currentError, setCurrentError] = useState('');
  const [changePackContent, { loading }] = useMutation(
    changePackContentMutation,
    {
      onError: (err) => {
        setCurrentError(err.message);
      },
      onCompleted: () => {
        setPickedDate(null);
        props.closeCb();
      },
    },
  );

  const handleClose = () => {
    setPickedProduct('');
    setPickedDate(null);
    props.closeCb();
  };

  const changeDate = (date) => {
    setPickedDate(date);
  };

  const handleChange = (product) => {
    if (product.value) {
      setPickedProduct(product.value);
    }
  };

  const changeProduct = async () => {
    await changePackContent({
      variables: {
        orderId: props.orderId,
        orderProductId: props.orderProductId,
        packProductId: props.packProductId,
        pickedProduct,
        pickedDate,
      },
    });
  };

  const clearError = () => {
    setCurrentError('');
  };

  const actions = [
    <FlatButton
      label="Anuluj"
      primary={true}
      onClick={handleClose}
      key="cancel"
    />,
    <FlatButton
      label="Zmień"
      primary={true}
      keyboardFocused={true}
      onClick={changeProduct}
      disabled={loading}
      key="change"
    />,
  ];
  const productsList = props.products
    .filter((product) => product.type === 'training')
    .map((product) => ({
      text: product.title,
      value: product._id,
    }));
  const currentProduct = props.products.find(
    (product) => product._id === props.orderProductId,
  );
  if (currentProduct) {
    return (
      <Dialog
        title="Zmień produkt"
        modal={false}
        open={props.open}
        onRequestClose={handleClose}
        actions={actions}
      >
        {currentError ? (
          <InfoPanel role="error" fade clearErrorCb={clearError}>
            {currentError}
          </InfoPanel>
        ) : null}
        Zmiana produktu: {currentProduct.title}
        <br />
        <br />
        <AutoComplete
          floatingLabelText="Wpisz nazwę produktu"
          filter={AutoComplete.caseInsensitiveFilter}
          dataSource={productsList}
          fullWidth
          onNewRequest={handleChange}
        />
        {pickedProduct && props.orderStatus === 'COMPLETE' ? (
          <DatePicker
            className="cart-single-product__datepicker"
            hintText="Data wyświetlenia"
            DateTimeFormat={Intl.DateTimeFormat}
            value={pickedDate}
            onChange={(event, date) => changeDate(date)}
            locale="pl"
            okLabel="Wybierz"
            cancelLabel="Anuluj"
            floatingLabelText="Data wyświetlenia"
          />
        ) : null}
      </Dialog>
    );
  }
  return null;
}

export default ProductChangeDialog;

ProductChangeDialog.propTypes = {
  closeCb: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
