import React from 'react';
import './VideoPlayer.scss';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import getFilesForUserDocumentQuery from '../../graphql/queries/getFilesForUserDocument.graphql';
import { Loading } from '../../utils/loading';

function VideoPlayer({ video, userDocumentId }) {
  const { data, loading } = useQuery(getFilesForUserDocumentQuery, {
    variables: { userDocumentId },
  });
  const files = get(data, 'getFilesForUserDocument', []);
  const videoFile = files.find((file) => file._id === video);

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="trainingVideo">
        <video
          className="video-js"
          id="videoPlayerId"
          controls
          controlsList="nodownload noremoteplayback"
          onContextMenu={(e) => e.preventDefault()}
        >
          <source src={videoFile.url} type="video/mp4" />
        </video>
      </div>
    </div>
  );
}

VideoPlayer.propTypes = {
  userDocumentId: PropTypes.string.isRequired,
  video: PropTypes.string.isRequired,
};

export default VideoPlayer;
