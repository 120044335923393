import React from 'react';
import { List, Subheader } from 'material-ui';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import withGraphqlHook from '../../common/withGraphqlHook';
import getProductQuery from '../../graphql/queries/getProduct.graphql';
import getFilesForUserDocumentQuery from '../../graphql/queries/getFilesForUserDocument.graphql';
import getFilesForLegalActQuery from '../../graphql/queries/getFilesForLegalAct.graphql';
import SingleFile from './singleFile';

function FileList(props) {
  return (
    <div>
      {props.files.length ? (
        <List>
          <Subheader inset>Pliki</Subheader>
          {props.files.map((file) => (
            <SingleFile file={file} key={file._id} display={props.display} />
          ))}
        </List>
      ) : null}
    </div>
  );
}

export default withGraphqlHook((props) => {
  const { data: productData, loading: productLoading } = useQuery(
    getProductQuery,
    {
      variables: { id: props.productId },
    },
  );

  let query = getFilesForUserDocumentQuery;
  let variables = { userDocumentId: props.userDocumentId };
  let path = 'getFilesForUserDocument';

  if (props.isLegalAct) {
    query = getFilesForLegalActQuery;
    variables = { legalActId: props.productId };
    path = 'getFilesForLegalAct';
  }

  const { data: filesData, loading: filesLoading } = useQuery(query, {
    variables,
  });

  const product = get(productData, 'getProduct', null);
  let files = get(filesData, path, []);

  if (product) {
    files = files.filter((file) => file._id !== product.video);
  }

  const loading = productLoading || filesLoading;
  return { files, loading };
}, FileList);
