import React from 'react';
import { Card, CardTitle, CardText, List, TextField } from 'material-ui';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import { Loading } from '../../utils/loading';
import { productSlugToType } from '../../utils/product';
import OpenTrainingsMap from '../OpenTrainingsMap/OpenTrainingsMap';
import getPageContentQuery from '../../graphql/queries/getPageContent.graphql';
import getOpenTrainingsWithDatesQuery from '../../graphql/queries/getOpenTrainingsWithDates.graphql';
import withGraphqlHook from '../../common/withGraphqlHook';
import SingleOpenTrainingListEntry from './SingleOpenTrainingListEntry';

const descriptions = {
  zachodniopomorskie: 'Zachodniopomorskie',
  pomorskie: 'Pomorskie',
  warminskoMazurskie: 'Warmińskomazurskie',
  podlaskie: 'Podlaskie',
  lubuskie: 'Lubuskie',
  wielkopolskie: 'Wielkopolskie',
  lodzkie: 'Łódzkie',
  kujawskoPomorskie: 'Kujawskopomorskie',
  mazowieckie: 'Mazowieckie',
  dolnoslaskie: 'Dolnośląskie',
  opolskie: 'Opolskie',
  slaskie: 'Śląskie',
  swietokrzyskie: 'Świętokrzyskie',
  lubelskie: 'Lubelskie',
  malopolskie: 'Małopolskie',
  podkarpackie: 'Podkarpackie',
};

class OpenTrainings extends React.Component {
  state = {
    search: '',
  };

  changeValue = (event) => {
    this.setState({
      search: event.target.value,
    });
  };

  render() {
    const { pageContent, openTrainingDates } = this.props;
    const filter = this.state.search.toLowerCase();
    const products = this.props.products.filter(
      (product) => product.title.toLowerCase().search(filter) >= 0,
    );

    return this.props.loading ? (
      <Loading />
    ) : (
      <div className="row row--flex">
        <div className="col-lg-12">
          {this.props.loading ? (
            <Loading />
          ) : pageContent ? (
            <Card>
              <h1 className="pagetitle">{pageContent.title}</h1>
              <CardText
                dangerouslySetInnerHTML={{ __html: pageContent.content }}
              />
            </Card>
          ) : null}
          <br />
          <Card>
            <CardText>
              <div className="row">
                <div className="col-lg-6">
                  <OpenTrainingsMap dates={openTrainingDates} />
                </div>
                <div className="col-lg-6">
                  <CardTitle>Tematy szkoleń</CardTitle>
                  <TextField
                    value={this.state.search}
                    floatingLabelText="Szukaj..."
                    fullWidth
                    onChange={this.changeValue}
                    name="search"
                  />
                  <List
                    style={{
                      height: '400px',
                      overflow: 'scroll',
                    }}
                  >
                    {products.map((product) => (
                      <SingleOpenTrainingListEntry
                        key={product._id}
                        product={product}
                      />
                    ))}
                  </List>
                </div>
              </div>
            </CardText>
          </Card>
        </div>
      </div>
    );
  }
}

export default withGraphqlHook((props) => {
  const type = productSlugToType(props.location.pathname);
  let pageContent = null;
  let products = [];
  let openTrainingDates = [];

  const { data: pageContentData, loading: pageContentLoading } = useQuery(
    getPageContentQuery,
    {
      variables: {
        page: type,
      },
    },
  );
  const { data: productsData, loading: productsLoading } = useQuery(
    getOpenTrainingsWithDatesQuery,
  );

  const loading = pageContentLoading || productsLoading;

  if (!loading) {
    pageContent = pageContentData.getPageContent;
    products = productsData.getOpenTrainingsWithDates;

    openTrainingDates = products.map((product) =>
      get(product, 'dates', []).map((openTrainingDate) => {
        const openTraining = products.find(
          (training) => training._id === openTrainingDate.openTrainingId,
        );
        const districtName = descriptions[openTrainingDate.district];
        return { ...openTraining, districtName, ...openTrainingDate };
      }),
    );

    if (openTrainingDates.length) {
      // eslint-disable-next-line prefer-destructuring
      openTrainingDates = openTrainingDates[0];
    }
  }

  return {
    products,
    loading,
    pageContent,
    openTrainingDates,
  };
}, OpenTrainings);
