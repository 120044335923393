import React from 'react';
import { List } from 'material-ui';
import './CategoriesFilter.scss';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import getCategoriesQuery from '../../graphql/queries/getCategories.graphql';
import CategoryContext from './CategoryContext';
import { Category } from './Category';

function CategoriesFilter() {
  const { data: categoriesData } = useQuery(getCategoriesQuery);
  const fetchedCategories = get(categoriesData, 'getCategories', []);
  // TODO: refactor to fetch only the right categories
  const categories = fetchedCategories.filter(
    ({ type }) => type !== 'schoolProduct',
  );

  const topLevel = categories.filter((category) => category.level === 1);
  const secondLevel = categories.filter((category) => category.level === 2);

  return (
    <div className="categories-filter">
      <CategoryContext.Consumer>
        {(value) => (
          <List>
            {topLevel.map((category) => (
              <Category
                {...category}
                categories={categories}
                subcategories={secondLevel}
                key={category._id}
                updateFilters={value.changeFilter}
                currentFilter={value.filter}
              />
            ))}
          </List>
        )}
      </CategoryContext.Consumer>
    </div>
  );
}

export default CategoriesFilter;
