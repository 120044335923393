/* eslint-disable max-lines */
import React from 'react';
import {
  Checkbox,
  DatePicker,
  Dialog,
  IconButton,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui';
import PropTypes from 'prop-types';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import Intl from 'intl';
import 'intl/locale-data/jsonp/pl-PL';
import Calendar from 'material-ui/svg-icons/action/today';
import { Loading } from '../../../utils/loading';
import './index.scss';
import { AppContext } from '../../../common/AppContext';
import { getAccessMessage } from '../../UserDocumentDatePicker/helpers/getAccessMessage';

class BasketSingleTraining extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: 0,
      displayDateModalOpen: false,
      settingDateFor: null,
    };
    this.index = this.props.itemIndex;
  }

  static contextType = AppContext;

  deleteFromCart = (id) => {
    const currentCart = [...this.context.cart];
    const index = currentCart.findIndex((product) => product.id === id);
    currentCart.splice(index, 1);
    this.context.setCart(currentCart);
    if (this.props.item.type === 'pack') {
      this.context.setPack([]);
    }
  };

  handleDisplayDateChange = (event, date) => {
    const { id } = this.props.item;
    const currentCart = [...this.context.cart];
    const index = currentCart.findIndex((product) => product.id === id);
    currentCart[index].displayDate = date;
    this.context.setCart(currentCart);
  };

  togglePublic = () => {
    const { id } = this.props.item;
    const currentCart = [...this.context.cart];
    const index = currentCart.findIndex((product) => product.id === id);
    currentCart[index].public = !currentCart[index].public;
    this.context.setCart(currentCart);
  };

  renderDate = () => {
    const { item, lastStep, display } = this.props;
    const hintStyle = {
      borderColor: 'rgb(40, 174, 115)',
      color: 'rgb(40, 174, 115)',
    };

    if (display) {
      if (typeof item.displayDate) {
        return item.displayDate
          ? new Intl.DateTimeFormat('pl-PL').format(item.displayDate)
          : null;
      }
      return null;
    }
    return (
      <>
        <DatePicker
          className="cart-single-product__datepicker"
          hintText={
            item.type === 'lesson' ? 'Data aktywacji' : 'Data wyświetlenia'
          }
          hintStyle={hintStyle}
          disabled={lastStep}
          DateTimeFormat={Intl.DateTimeFormat}
          value={item.displayDate}
          onChange={this.handleDisplayDateChange}
          locale="pl"
          okLabel="Wybierz"
          cancelLabel="Anuluj"
        />
        {getAccessMessage(item.days, item.type)}
      </>
    );
  };

  renderPackDate = (productId) => {
    const { item } = this.props;
    const currentDate = item.displayDates[productId];
    const hintStyle = {
      borderColor: 'rgb(40, 174, 115)',
      color: 'rgb(40, 174, 115)',
    };
    if (this.props.display) {
      if (currentDate) {
        return new Intl.DateTimeFormat('pl-PL').format(currentDate);
      }
      return null;
    }
    return (
      <>
        <DatePicker
          className="cart-single-product__datepicker"
          hintText="Data wyświetlenia"
          hintStyle={hintStyle}
          DateTimeFormat={Intl.DateTimeFormat}
          value={currentDate}
          onChange={(event, date) =>
            this.handlePackDisplayDateChange(date, productId)
          }
          locale="pl"
          okLabel="Wybierz"
          cancelLabel="Anuluj"
        />
        {getAccessMessage(item.days, item.type)}
      </>
    );
  };

  deletePackItem = (id) => {
    const currentPack = [...this.context.pack];
    const index = currentPack.indexOf(id);
    currentPack.splice(index, 1);
    this.context.setPack(currentPack);
  };

  handlePackDisplayDateChange = (date, productId) => {
    const currentDates = { ...this.context.currentDates };
    currentDates[productId] = date;
    this.context.setCurrentDates(currentDates);
    this.closeDisplayDateModal();
  };

  displayPackDisplayDateModal = (productId) => {
    this.setState({
      settingDateFor: productId,
      displayDateModalOpen: true,
    });
  };

  closeDisplayDateModal = () => {
    this.setState({
      settingDateFor: null,
      displayDateModalOpen: false,
    });
  };

  renderPackContent = () => {
    if (this.props.item.type !== 'pack') {
      return;
    }
    const currentPackContent = [...this.context.pack];
    const currentPack = this.props.item;
    const modalDate = this.context.currentDates[this.state.settingDateFor];
    const productsLeft =
      parseInt(currentPack.contains, 10) - currentPackContent.length;

    if (productsLeft === 0) {
      this.context.setPackFull(true);
    } else {
      this.context.setPackFull(false);
    }

    if (
      currentPackContent &&
      currentPackContent.length &&
      this.props.products &&
      this.props.products.length
    ) {
      const currentPackDetails = currentPackContent.map((pack) =>
        this.props.products.find((product) => product._id === pack),
      );
      return (
        <React.Fragment>
          {currentPackDetails.map((product) => {
            const currentDate = this.context.currentDates[product._id]
              ? Intl.DateTimeFormat('pl-PL').format(
                  this.context.currentDates[product._id],
                )
              : null;
            return (
              <div className="bst-singlepackitem row" key={product._id}>
                <div className="col-lg-10">{product.title}</div>
                {this.props.lastStep ? (
                  <div className="col-lg-2">{currentDate}</div>
                ) : (
                  <React.Fragment>
                    <div className="col-lg-1">
                      <IconButton
                        onClick={() =>
                          this.displayPackDisplayDateModal(product._id)
                        }
                        className="deleteButton"
                        tooltipPosition="top-left"
                        tooltip={
                          currentDate
                            ? `Data wyświetlenia: ${currentDate}`
                            : 'Wybierz datę wyświetlenia'
                        }
                      >
                        <Calendar />
                      </IconButton>
                    </div>
                    <div className="col-lg-1">
                      <IconButton
                        onClick={() => this.deletePackItem(product._id)}
                        className="deleteButton"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </React.Fragment>
                )}
              </div>
            );
          })}
          <br />
          Liczba szkoleń możliwych do dobrania: {productsLeft} /{' '}
          {currentPack.contains}
          <Dialog
            title="Data wyświetlenia szkolenia"
            modal={false}
            open={this.state.displayDateModalOpen}
            onRequestClose={this.closeDisplayDateModal}
          >
            <DatePicker
              className="cart-single-product__datepicker"
              hintText="Data wyświetlenia"
              DateTimeFormat={Intl.DateTimeFormat}
              value={modalDate}
              onChange={(event, date) =>
                this.handlePackDisplayDateChange(
                  date,
                  this.state.settingDateFor,
                )
              }
              locale="pl"
              okLabel="Wybierz"
              cancelLabel="Anuluj"
            />
          </Dialog>
        </React.Fragment>
      );
    }
    return [];
  };

  render() {
    const { item, lastStep } = this.props;
    const netSum = item.price;
    const vat = item.public ? 0 : item.vat;
    const vatSum = vat;

    if (this.props.loading) {
      return (
        <TableRow>
          <TableHeaderColumn colSpan={5}>
            <Loading />
          </TableHeaderColumn>
        </TableRow>
      );
    }
    return (
      <TableRow
        key={`${item.title}-desktop-row`}
        className={`${item.type === 'pack' ? 'packContent' : ''}`}
      >
        <TableRowColumn className="basket-products-list__cell name-cell">
          {item.title}
          <div className="bst-packwrapper">{this.renderPackContent()}</div>
          <div>{item.type === 'pack' ? null : this.renderDate()}</div>
          {['training', 'pack'].includes(item.type) ? (
            <div className="row row--flex" style={{ marginTop: '1rem' }}>
              <Checkbox
                disabled={lastStep}
                style={{ marginTop: '1rem', marginLeft: '1rem' }}
                label="Finansowane ze środków publicznych"
                checked={item.public}
                onCheck={this.togglePublic}
              />
            </div>
          ) : null}
        </TableRowColumn>
        <TableRowColumn className="basket-products-list__cell price-cell">
          {item.public ? netSum : `${Math.round(netSum * 100) / 100} zł`}
        </TableRowColumn>
        <TableRowColumn className="basket-products-list__cell price-cell">
          {item.public ? 'zw.' : `${Math.round(vatSum * 100) / 100} zł`}
        </TableRowColumn>
        <TableRowColumn className="basket-products-list__cell price-cell">
          {item.public
            ? netSum
            : `${Math.round((netSum + vatSum) * 100) / 100} zł`}
        </TableRowColumn>
        {!lastStep && (
          <TableRowColumn className="basket-products-list__cell price-cell">
            <DeleteIcon onClick={() => this.deleteFromCart(item._id)} />
          </TableRowColumn>
        )}
      </TableRow>
    );
  }
}

export default BasketSingleTraining;

BasketSingleTraining.propTypes = {
  itemIndex: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  pack: PropTypes.object,
  lastStep: PropTypes.bool,
  products: PropTypes.array.isRequired,
};

BasketSingleTraining.defaultProps = {
  lastStep: false,
  pack: {},
};
