import React from 'react';
import { TableHeaderColumn, TableRow, TableRowColumn } from 'material-ui';
import PropTypes from 'prop-types';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import { Loading } from '../../../utils/loading';
import { AppContext } from '../../../common/AppContext';

class BasketSingleDocument extends React.Component {
  constructor(props) {
    super(props);
    this.state = { quantity: 0 };
    this.index = this.props.itemIndex;
  }

  static contextType = AppContext;

  deleteFromCart = (id) => {
    const currentCart = [...this.context.cart];
    const index = currentCart.findIndex((product) => product.id === id);
    currentCart.splice(index, 1);
    this.context.setCart(currentCart);
    if (this.props.item.type === 'pack') {
      this.context.setPack([]);
    }
  };

  render() {
    const { item, lastStep } = this.props;
    let vatSum = 0;
    let netSum = 0;
    const vat = item.public ? 0 : item.vat;
    if (item.quantity) {
      netSum += item.quantity * item.priceNet;
      vatSum += item.quantity * item.vat;
    } else {
      netSum += item.price;
      vatSum += vat;
    }

    if (this.props.loading) {
      return (
        <TableRow>
          <TableHeaderColumn colSpan={5}>
            <Loading />
          </TableHeaderColumn>
        </TableRow>
      );
    }
    return (
      <TableRow key={`${item.title}-desktop-row`}>
        <TableRowColumn className="basket-products-list__cell name-cell">
          {item.title}
          {!!item.color && (
            <span className="product-details">kolor: {item.color.text}</span>
          )}
          {!!item.size && (
            <span className="product-details">rozmiar: {item.size.size}</span>
          )}
        </TableRowColumn>
        <TableRowColumn className="basket-products-list__cell price-cell">
          {Math.round(netSum * 100) / 100} zł
        </TableRowColumn>
        <TableRowColumn className="basket-products-list__cell price-cell">
          {Math.round(vatSum * 100) / 100} zł
        </TableRowColumn>
        <TableRowColumn className="basket-products-list__cell price-cell">
          {Math.round((netSum + vatSum) * 100) / 100} zł
        </TableRowColumn>
        {!lastStep && (
          <TableRowColumn className="basket-products-list__cell price-cell">
            <DeleteIcon onClick={() => this.deleteFromCart(item._id)} />
          </TableRowColumn>
        )}
      </TableRow>
    );
  }
}

export default BasketSingleDocument;

BasketSingleDocument.propTypes = {
  itemIndex: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  lastStep: PropTypes.bool.isRequired,
};

BasketSingleDocument.defaultProps = {
  pack: {},
};
