import React, { useState } from 'react';
import {
  Card,
  CardTitle,
  CardText,
  RaisedButton,
  SelectField,
  MenuItem,
  CardActions,
} from 'material-ui';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { browserHistory } from 'react-router';
import sortBy from 'lodash/sortBy';
import { Loading } from '../../utils/loading';
import useMetaKeyword from '../../hooks/useMetaKeyword';
import useGenerateSubpageTitle from '../../hooks/useGenerateSubpageTitle';
import getOnlineTrainingWithDatesQuery from '../../graphql/queries/getOnlineTrainingWithDates.graphql';
import './index.scss';

const SingleOnlineTraining = ({ routeParams: { slug } }) => {
  const [selectedDate, setSelectedDate] = useState('');

  const { loading, data, error } = useQuery(getOnlineTrainingWithDatesQuery, {
    variables: {
      slug,
    },
    fetchPolicy: 'network-only',
  });

  const training = data && data.getOnlineTrainingWithDates;

  useMetaKeyword(training);
  useGenerateSubpageTitle(training);

  if (!loading && !training) {
    browserHistory.push('/404');
    return null;
  }

  if (error) {
    return error.message;
  }
  if (loading) {
    return <Loading />;
  }

  const changeOnlineTrainingDate = (event, index, value) => {
    setSelectedDate(value);
  };

  const filteredTrainingDates = sortBy(
    training.dates.filter((date) => !date.isArchived),
    (date) => moment(date.trainingDate, 'DD-MM-YYYY').format('x'),
  );

  const goToRegistration = () =>
    browserHistory.push(
      `/produkty/szkolenia-online/zapisy/${training.slug}/${selectedDate}`,
    );

  const getTrainingDisplayDate = (trainingDate) => {
    const day = moment(trainingDate.trainingDate, 'DD-MM-YYYY').format(
      'DD-MM-YYYY',
    );
    const getHour = (date) => moment(date, 'DD-MM-YYYY HH-mm').format('HH:mm');
    const startHour = getHour(trainingDate.startDate);
    const endHour = getHour(trainingDate.endDate);
    return `${day} - ${startHour} - ${endHour}`;
  };

  return (
    <div style={{ marginTop: 20 }}>
      <Card>
        <div className="row training-title">
          <div className="col-lg-7 col-md-12">
            <h1 className="pagetitle">{training.title}</h1>
            <div
              className="subtitle"
              dangerouslySetInnerHTML={{ __html: training.subtitle }}
            />
          </div>
          <div className="col-lg-5 hidden-md hidden-xs">
            {training.imageSrc ? (
              <img src={training.imageSrc} className="product-image" />
            ) : null}
          </div>
        </div>
        <CardText>
          <div className="row">
            <div className="col-lg-6">
              <div dangerouslySetInnerHTML={{ __html: training.text }} />
            </div>
            <div className="col-lg-6">
              <CardTitle title="Wybierz termin" />
              <SelectField
                floatingLabelText="Termin"
                value={selectedDate}
                onChange={changeOnlineTrainingDate}
                fullWidth
              >
                {filteredTrainingDates.map((date) => (
                  <MenuItem
                    disabled={!date.isActive}
                    value={date._id}
                    key={date._id}
                    primaryText={getTrainingDisplayDate(date)}
                  />
                ))}
              </SelectField>
              <br />
              <br />
              <CardActions>
                <RaisedButton
                  primary
                  disabled={!selectedDate}
                  label="Zapisy online"
                  onClick={goToRegistration}
                />
              </CardActions>
            </div>
          </div>
        </CardText>
      </Card>
    </div>
  );
};

SingleOnlineTraining.defaultProps = {
  districts: [],
  openTraining: {},
  openTrainingDates: [],
  routeParams: {
    slug: '',
  },
};

SingleOnlineTraining.propTypes = {
  districts: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  openTraining: PropTypes.object,
  openTrainingDates: PropTypes.array,
  routeParams: PropTypes.object,
};

export default SingleOnlineTraining;
