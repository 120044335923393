import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import getOnlineTrainingsQuery from '../../graphql/queries/getOnlineTrainings.graphql';
import NewProductsList from '../NewProductsList/NewProductsList';

const OnlineTrainings = () => {
  const { loading, data, error } = useQuery(getOnlineTrainingsQuery, {
    fetchPolicy: 'network-only',
  });

  const pageSettings = {
    title: 'Szkolenia online',
    content:
      '<p>Nasza oferta szkoleń online jest skierowana przede wszystkim do Rad Pedagogicznych, ale także do Dyrektorów szkół i pracowników administracji</p>',
  };

  if (error) {
    return error.message;
  }

  const fetchedList = (!loading && data.getOnlineTrainings) || [];

  return (
    <NewProductsList
      products={fetchedList}
      loading={loading}
      pageSettings={pageSettings}
    />
  );
};

export default OnlineTrainings;
