import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { IconMenu, MenuItem } from 'material-ui';

const LowerMenuElement = ({ menuItem, isMobile }) => {
  const onClickHandler = () => {
    if (!menuItem.dropdownElements) {
      browserHistory.push(menuItem.link);
    }
  };
  const menuElement = (
    <div
      onClick={onClickHandler}
      key={menuItem.label}
      className={`${
        menuItem.className ? menuItem.className : ''
      } lowermenu-menuitem`}
      style={{
        height: isMobile ? 40 : '100%',
        width: isMobile ? '100%' : 'auto',
        maxWidth: isMobile ? '100%' : '220px',
      }}
    >
      <div>{menuItem.icon}</div>
      <span>{menuItem.label}</span>
    </div>
  );

  return menuItem.dropdownElements ? (
    <IconMenu iconButtonElement={menuElement} style={{ height: '100%' }}>
      {menuItem.dropdownElements &&
        menuItem.dropdownElements.map((element) => (
          <MenuItem
            value="1"
            key={element.label}
            primaryText={element.label}
            onClick={() => browserHistory.push(element.link)}
          />
        ))}
    </IconMenu>
  ) : (
    menuElement
  );
};

LowerMenuElement.propTypes = {
  menuItem: PropTypes.shape({
    link: PropTypes.string,
    label: PropTypes.string,
    className: PropTypes.string,
    icon: PropTypes.object,
    dropdownElements: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        link: PropTypes.string,
      }),
    ),
  }).isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default LowerMenuElement;
