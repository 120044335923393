import React from 'react';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from 'material-ui/Table';
import PropTypes from 'prop-types';
import BasketSingleDocument from '../BasketSingleDocument/BasketSingleDocument';

export default function BasketSchoolProducts(props) {
  return (
    <React.Fragment>
      Wzory dokumentów
      <Table selectable={false}>
        <TableHeader adjustForCheckbox={false} displaySelectAll={false}>
          <TableRow>
            <TableHeaderColumn
              style={{ borderRight: 0 }}
              className="basket-products-list__cell name-cell"
            >
              Produkt
            </TableHeaderColumn>
            <TableHeaderColumn className="basket-products-list__cell price-cell">
              Netto
            </TableHeaderColumn>
            <TableHeaderColumn className="basket-products-list__cell price-cell">
              Vat
            </TableHeaderColumn>
            <TableHeaderColumn className="basket-products-list__cell price-cell">
              Brutto
            </TableHeaderColumn>
            {!props.lastStep && (
              <TableHeaderColumn className="basket-products-list__cell price-cell">
                Usuń
              </TableHeaderColumn>
            )}
          </TableRow>
        </TableHeader>
        <TableBody displayRowCheckbox={false}>
          {props.items.map((item, index) => (
            <BasketSingleDocument
              key={item._id + String(index)}
              item={item}
              lastStep={props.lastStep}
              itemIndex={index}
            />
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}

BasketSchoolProducts.propTypes = {
  lastStep: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
};
