import React from 'react';
import Account from 'material-ui/svg-icons/action/account-box';
import PropTypes from 'prop-types';
import './CouncilMember.scss';

function CouncilMember(props) {
  return (
    <div className="council-wrapper">
      <h2>{props.name}</h2>
      <hr />
      <div className="council-member">
        <div className="council-memberimage">
          {props.imageSrc ? (
            <img src={props.imageSrc} alt={props.name} />
          ) : (
            <Account
              style={{
                width: '210px',
                height: '160px',
                color: '#DDDDDD',
              }}
            />
          )}
        </div>
        <div
          className="council-memberdesc"
          dangerouslySetInnerHTML={{ __html: props.text }}
        />
      </div>
      <hr />
    </div>
  );
}

CouncilMember.propTypes = {
  name: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default CouncilMember;
