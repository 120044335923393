import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Dialog, RaisedButton, TextField } from 'material-ui';
import { browserHistory } from 'react-router';
import { useMutation } from '@apollo/react-hooks';
import { validateEmail } from '@ecrklex/components';
import { AppContext } from '../../common/AppContext';
import withGraphqlHook from '../../common/withGraphqlHook';
import setUserInvoiceMailMutation from '../../graphql/mutations/setUserInvoiceMail.graphql';

class InvoiceEmailConfirm extends React.Component {
  state = {
    email: '',
    showInput: false,
    defaultChecked: false,
  };

  static contextType = AppContext;

  useDefaultEmail = () => {
    this.setState({
      defaultChecked: true,
      showInput: false,
      email: this.context.user.username,
      emailError: '',
    });
  };

  toggleInput = () => {
    this.setState({
      defaultChecked: false,
      showInput: true,
      email: '',
    });
  };

  changeEmail = (event) => {
    this.setState({
      emailError: '',
      email: event.target.value,
    });
  };

  handleSave = async () => {
    const isEmailValid = validateEmail(this.state.email);
    if (!isEmailValid) {
      this.setState({
        emailError: 'Podaj właściwy adres',
      });
      return;
    }

    try {
      await this.props.setEmail({
        variables: {
          email: this.state.email,
        },
      });
    } catch (e) {
      // TODO: add error handling
    }

    const { redirectTo, setRedirectTo } = this.context;
    if (redirectTo) {
      browserHistory.push(redirectTo);
      setRedirectTo('');
    } else {
      browserHistory.push('/');
    }
  };

  render() {
    const { isOpen, handleClose } = this.props;
    const { showInput } = this.state;
    const { user } = this.context;
    return (
      <Dialog
        modal={true}
        open={isOpen}
        onRequestClose={handleClose}
        autoScrollBodyContent
      >
        Aby kontynuować, prosimy o wybranie adresu do wysyłki faktur
        <br />
        {user ? (
          <Checkbox
            style={{ marginTop: '1rem', marginLeft: '1rem' }}
            label={user.username}
            checked={this.state.defaultChecked}
            onCheck={this.useDefaultEmail}
            data-testid="defaultInvoiceEmail"
          />
        ) : null}
        {showInput ? null : (
          <Checkbox
            style={{ marginTop: '1rem', marginLeft: '1rem' }}
            label="inny"
            checked={this.showInput}
            onCheck={this.toggleInput}
          />
        )}
        <br />
        {showInput ? (
          <TextField
            value={this.state.email}
            floatingLabelText="E-mail do faktur"
            fullWidth
            onChange={this.changeEmail}
            name="invoiceEmail"
            errorText={this.state.emailError}
          />
        ) : null}
        <RaisedButton
          disabled={!this.state.email || this.props.loading}
          label="Zapisz"
          onClick={this.handleSave}
          primary
          type="button"
          data-testid="saveInvoice"
        />
      </Dialog>
    );
  }
}

export default withGraphqlHook(() => {
  const [setEmail, { loading }] = useMutation(setUserInvoiceMailMutation);

  return { setEmail, loading };
}, InvoiceEmailConfirm);

InvoiceEmailConfirm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  setEmail: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
