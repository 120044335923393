import React from 'react';
import './HomePublications.scss';

const loadingPublication = (index, isMobile) => (
  <div
    className="hp-singlehome hp-singlehomepublication"
    key={index}
    style={{
      flexGrow: `${isMobile ? '1' : '0.25'}`,
    }}
  >
    <div
      className="hp-publicationimage"
      style={{
        backgroundColor: '#DDDDDD',
      }}
    />
    <div className="hp-publicationtitle">
      <div className="loading" />
      <div className="loading" />
      <div className="loading" />
    </div>
    <div className="hp-publicationdescription">
      <div className="loading" />
      <div className="loading" />
      <div className="loading" />
      <div className="loading" />
      <div className="loading" />
    </div>
    <div className="loadingpublications-fakebutton" />
  </div>
);

export default loadingPublication;
