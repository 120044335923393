import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { RaisedButton } from 'material-ui';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import getLatestProductsQuery from '../../graphql/queries/getLatestProducts.graphql';
import Panel from '../common/Panel';
import loadingPublication from './LoadingPublications';
import './HomePublications.scss';

export default function HomePublications(props) {
  const { data, loading } = useQuery(getLatestProductsQuery, {
    variables: {
      type: 'document',
    },
  });
  const documents = get(data, 'getLatestProducts', []);
  const ready = !loading;
  const { isMobile } = props;
  return (
    <Panel title="Nasze publikacje" backgroundColor="#2AA96C">
      <div
        className="hp-wrapper"
        style={{ flexDirection: `${isMobile ? 'column' : 'row'}` }}
      >
        {ready
          ? documents.map((document) => (
              <div
                className="hp-singlehome hp-singlehomethumbnail hp-singlehomepublication"
                key={document._id}
                style={{ width: `${isMobile ? '100%' : '25%'}` }}
              >
                <div className="hp-publicationimage">
                  <img src={document.imageSrc} alt={document.title} />
                </div>
                <div className="hp-publicationtitle">{document.title}</div>
                <div className="hp-publicationdescription">
                  {document.short}
                </div>
                <RaisedButton
                  primary
                  onClick={() =>
                    browserHistory.push(`/produkty/${document.slug}`)
                  }
                  label="Zobacz"
                />
              </div>
            ))
          : [...Array(4)].map((item, index) =>
              loadingPublication(index, isMobile),
            )}
      </div>
      <div className="hp-seemore">
        <RaisedButton
          primary
          label="Zobacz wszystkie"
          onClick={() => browserHistory.push('/produkty/dokumenty')}
        />
      </div>
    </Panel>
  );
}

HomePublications.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};
