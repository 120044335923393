import React, { useState } from 'react';
import { MuiThemeProvider } from 'material-ui';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import ApolloClient, { InMemoryCache } from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import { browserHistory } from 'react-router';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { logout } from './common/methods/logout';
import { AppContext } from './common/AppContext';
import NewLayout from './NewLayout/NewLayout';
import { aggregatorRoute } from './conf';
import UserDownloadStatus from './UserDownloadStatus';

const muiTheme = getMuiTheme({
  palette: {
    primary1Color: '#28ae73',
    primary2Color: '#7498c8',
    // primary3Color: grey400,
    // accent1Color: pinkA200,
    // accent2Color: pinkA200,
    // accent3Color: grey500,
    // textColor: darkBlack,
    // alternateTextColor: white,
    // canvasColor: white,
    // borderColor: grey300,
    // disabledColor: fade(darkBlack, 0.3),
    // pickerHeaderColor: cyan500,
    // clockCircleColor: fade(darkBlack, 0.07),
    // shadowColor: fullBlack,
  },
  appBar: {
    height: 50,
  },
  fontFamily: 'Open Sans',
});

const cache = new InMemoryCache();

const App = ({ children }) => {
  const currentUser = JSON.parse(localStorage.getItem('ecrk_user'));
  const [currentMessage, setMessage] = useState('');
  const [cart, setCart] = useState([]);
  const [pack, setPack] = useState([]);
  const [packFull, setPackFull] = useState(false);
  const [currentDates, setCurrentDates] = useState({});
  const [redirectTo, setRedirectTo] = useState('');
  const [user, setContextUser] = useState(currentUser);

  const setUser = (userInput) => {
    localStorage.setItem('ecrk_user', JSON.stringify(userInput));
    if (userInput) {
      const context = { userId: userInput._id } || null;
      Sentry.setContext('user', context);
    }

    setContextUser(userInput);
  };
  const headers = {};

  if (user && user.token) {
    headers.authorization = user.token;
    headers.newAuth = true;
  }
  const client = new ApolloClient({
    onError: (err) => {
      if (
        err &&
        err.graphQLErrors &&
        err.graphQLErrors[0] &&
        err.graphQLErrors[0].message === 'Unauthorized'
      ) {
        logout(() => {
          setUser(null);
        });
        browserHistory.push('/login');
      }
    },
    uri: `${aggregatorRoute}/graphql`,
    request: (operation) => {
      if (operation && operation.operationName) {
        Sentry.setContext('operation', {
          lastOperationName: operation.operationName,
        });
      }

      return operation.setContext(() => ({
        headers,
      }));
    },
    cache,
  });

  const context = {
    currentMessage,
    setMessage,
    cart,
    setCart,
    pack,
    setPack,
    packFull,
    setPackFull,
    currentDates,
    setCurrentDates,
    redirectTo,
    setRedirectTo,
    user,
    setUser,
  };

  return (
    <AppContext.Provider value={context}>
      <ApolloProvider client={client}>
        <MuiThemeProvider muiTheme={muiTheme}>
          <UserDownloadStatus />
          <NewLayout>{children}</NewLayout>
        </MuiThemeProvider>
      </ApolloProvider>
    </AppContext.Provider>
  );
};

App.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

App.defaultProps = {
  children: null,
};

export default App;
