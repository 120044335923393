import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import UserSingleProduct from '../UserSingleProduct/UserSingleProduct';
import InfoPanel from '../../utils/InfoPanel/InfoPanel';
import getUserDocumentsQuery from '../../graphql/queries/getUserDocuments.graphql';
import { Loading } from '../../utils/loading';
import { getProductTypeByPath } from '../../utils/utils';

function UserProducts(props) {
  const productType = getProductTypeByPath(props.location.pathname);
  const { data, loading } = useQuery(getUserDocumentsQuery, {
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <Loading />;
  }

  const documents = get(data, 'getUserDocuments', []).filter(
    (document) =>
      document && document.product && document.product.type === productType,
  );
  return (
    <div>
      {documents.length > 0 ? (
        sortBy(documents, 'product.title').map((document) => (
          <UserSingleProduct document={document} key={document._id} />
        ))
      ) : (
        <InfoPanel fade={false} role="info">
          Brak produktów do wyświetlenia
        </InfoPanel>
      )}
    </div>
  );
}

UserProducts.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default UserProducts;
