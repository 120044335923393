import React from 'react';
import Smartphone from 'material-ui/svg-icons/hardware/smartphone';
import LocalPhone from 'material-ui/svg-icons/maps/local-phone';
import MailOutline from 'material-ui/svg-icons/communication/mail-outline';
import './Footer.scss';

export default function Footer() {
  return (
    <div className="footer-wrapper">
      <div className="container footer-container">
        <div className="row">
          <div className="col-lg-3">
            <div className="footer-address">
              <Smartphone color="#fff" />
              +48 666 025 333
            </div>
            <div className="footer-address">
              <LocalPhone color="#fff" />
              (85) 30 70 999, fax (85) 87 30 058
            </div>
            <div className="footer-address">
              <MailOutline color="#fff" />
              <a href="mailto:biuro@ecrklex.pl">biuro@ecrklex.pl</a>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="footer-address">
              ECRK Lex s.c.
              <br />
              Al. Jana Pawła II 59/43
              <br />
              15-704 Białystok
              <br />
              (85) 30 70 999
              <br />
              NIP: 951-244-22-72
              <br />
              REGON: 367849596
            </div>
          </div>
          <div className="col-lg-3">
            <div className="footer-address">
              Oddział Warszawa:
              <br />
              ul. Stryjeńskich 15a/34
              <br />
              02-791 Warszawa
              <br />
              +48 661 601 020
            </div>
          </div>
          <div className="col-lg-3">
            <div className="footer-tandc">
              <a href="/regulamin">Regulamin serwisu</a>
            </div>
          </div>
        </div>
        <div className="footer-partners">
          <a href="http://hsp.waw.pl" rel="nofollow noreferrer" target="_blank">
            <img
              src="/hsp-bw.png"
              alt="HSP - Jarosław Skierski"
              width={93}
              height={60}
            />
          </a>
          <a
            href="http://e-ksiegarnia.net.pl"
            rel="nofollow noreferrer"
            target="_blank"
          >
            <img
              src="/prymat-bw.png"
              alt="Drukarnia Prymat"
              width={200}
              height={60}
            />
          </a>
          <a
            href="http://edulex.com.pl"
            rel="nofollow noreferrer"
            target="_blank"
          >
            <img
              src="/edulex-bw.png"
              alt="Wydawnictwo EduLex"
              width={125}
              height={60}
            />
          </a>
        </div>
        <span className="footerText">
          Copyright (C) {new Date().getFullYear()} Korzystanie z materiałów
          redakcyjnych ECRK Lex s.c. wymaga wcześniejszej zgody oraz zawarcia
          stosownej umowy licencyjnej. Na podstawie art. 25 ust. 1 pkt. 1 b)
          ustawy o prawie autorskim i prawach pokrewnych ECRK Lex s.c. wyraźnie
          zastrzega, że dalsze rozpowszechnianie treści zamieszczonych na
          stronie internetowej jest zabronione.
        </span>
      </div>
    </div>
  );
}
