import React from 'react';
import PropTypes from 'prop-types';
import { CardTitle, CardText } from 'material-ui/Card';
import RaisedButton from 'material-ui/RaisedButton';
import './Product.scss';
import { browserHistory } from 'react-router';

const longTitleStyle = {
  fontSize: '20px',
  lineHeight: '22px',
};

const shortTitleStyle = {
  lineHeight: '30px',
};

const SchoolProduct = ({ title, short, imageSrc, slug, type }) => (
  <div className="product">
    {imageSrc && (
      <div className="schoolproduct-imagewrapper">
        <img src={imageSrc} style={{ maxHeight: 150 }} />
      </div>
    )}
    <CardTitle
      title={title}
      titleStyle={title.length > 50 ? longTitleStyle : shortTitleStyle}
    />
    <CardText className="shortText">
      {short}
      <div className="product__button-wrapper">
        <RaisedButton
          primary
          label="Zobacz"
          onClick={(event) => {
            event.preventDefault();
            if (type === 'schoolProduct') {
              browserHistory.push(`/produkty-szkolne/produkt/${slug}`);
            } else {
              browserHistory.push(`/produkty/${slug}`);
            }
          }}
          href={`/produkty/${slug}`}
        />
      </div>
    </CardText>
  </div>
);

SchoolProduct.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  imageSrc: PropTypes.string,
};

export default SchoolProduct;
