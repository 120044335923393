import React from "react";
import PropTypes from "prop-types";
import { RaisedButton } from "material-ui";
import { browserHistory } from "react-router";

export default function SearchResult(props) {
  return (
    <div>
      <h6>{props.title}</h6>
      {props.text}
      <div style={{ marginTop: 10 }}>
        <RaisedButton
          primary
          label="Zobacz"
          onClick={() => browserHistory.push(`/produkty/${props.slug}`)}
        />
      </div>
      <hr />
    </div>
  );
}

SearchResult.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
};
