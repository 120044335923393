/* eslint-disable max-lines */
import React, { useContext } from 'react';
import {
  Step,
  Stepper,
  StepContent,
  StepLabel,
  Checkbox,
  RaisedButton,
  FlatButton,
  TextField,
} from 'material-ui';
import { browserHistory } from 'react-router';
import moment from 'moment';
import { useMutation, useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import { UserForm } from '@ecrklex/components';
import { Loading } from '../../utils/loading';
import InfoPanel from '../../utils/InfoPanel/InfoPanel';
import { calculateGrossPrice } from '../../utils/product';
import { generateSubpageTitle } from '../../utils/generatePageTitle';
import { AppContext } from '../../common/AppContext';
import withGraphqlHook from '../../common/withGraphqlHook';
import getOpenTrainingWithDateQuery from '../../graphql/queries/getOpenTrainingWithDate.graphql';
import getVatRatesQuery from '../../graphql/queries/getVatRatesQuery.graphql';
import updateUserProfileMutation from '../../graphql/mutations/updateUserProfile.graphql';
import registerForTrainingMutation from '../../graphql/mutations/registerForOpenTraining.graphql';
import { cleanUserProfile } from '../../utils/user';
import OpenTrainingParticipants from './OpenTrainingParticipants';

class OpenTrainingRegistrationForm extends React.Component {
  state = {
    stepIndex: 0,
    items: this.props.items,
    editUser: !this.props.hasContactPerson,
    orderNotes: '',
    participants: [],
    participantsError: '',
    public: false,
    notice: '',
    eInvoice: true,
  };

  componentDidUpdate() {
    if (this.props.openTraining) {
      generateSubpageTitle(this.props.openTraining.title);
    }
  }

  static contextType = AppContext;

  handleUserFormSubmit = async (user) => {
    await this.props.updateUserProfile({
      variables: { input: user.profile },
    });
    this.setState({ editUser: false });
  };

  editUser = () => {
    this.setState({ editUser: true });
  };

  handleNext = () => {
    const { stepIndex } = this.state;
    let participantsError = '';
    if (stepIndex === 0 && !this.props.user) {
      const redirectTo = window.location.pathname;
      this.context.setRedirectTo(redirectTo);
      browserHistory.push('/login');
    }

    if (stepIndex === 1) {
      if (!this.userForm.validateForm()) return;
      this.userForm.handleSubmit();
    }

    if (stepIndex === 2) {
      if (!this.state.participants.length) {
        participantsError = 'Dodaj conajmniej jednego uczestnika!';
      }
    }

    this.setState({
      stepIndex: participantsError ? stepIndex : stepIndex + 1,
      participantsError,
    });
  };

  handlePrev = () => {
    const { stepIndex } = this.state;
    if (stepIndex > 0) {
      this.setState({ stepIndex: stepIndex - 1 });
    }
  };

  registerForTraining = async () => {
    const registrationForm = {
      participants: this.state.participants,
      price: this.calculatePrice(),
      discount: this.calculateDiscount(),
      openTrainingId: this.props.openTraining._id,
      openTrainingDateId: this.props.openTrainingDate._id,
      public: this.state.public,
      notice: this.state.notice,
      eInvoice: this.state.eInvoice,
    };

    await this.props.registerForTraining({
      variables: {
        input: registrationForm,
      },
    });
    this.context.setMessage('Twoje zgłoszenie zostało wysłane pomyślnie');
    browserHistory.push('/');
  };

  changeNotice = (event) => {
    this.setState({
      notice: event.target.value,
    });
  };

  calculateFinalPrice = () =>
    Math.round((this.calculatePrice() - this.calculateDiscount()) * 100) / 100;

  clearParticipantsError = () => {
    this.setState({
      participantsError: '',
    });
  };

  togglePublic = () => {
    this.setState((state) => ({
      public: !state.public,
    }));
  };

  calculatePrice = () => {
    let price = 0;
    const participantsCount = this.state.participants.length;
    const singlePrice = this.state.public
      ? this.props.openTrainingDate.price
      : calculateGrossPrice({
          product: this.props.openTrainingDate,
          vatRates: [this.props.vat],
        });
    if (participantsCount) {
      price = this.state.participants.length * singlePrice;
    }
    return Math.round(price * 100) / 100;
  };

  calculateDiscount = () => {
    // const price = this.state.public
    //   ? this.props.openTrainingDate.price
    //   : calculateGrossPrice({
    //       product: this.props.openTrainingDate,
    //       vatRates: [this.props.vat],
    //     });
    // const participantsCount = this.state.participants.length;
    const discount = 0;
    // TODO: discuss discounts with the client
    // if (participantsCount === 2) {
    //   discount = price * 0.2;
    // } else if (participantsCount >= 3) {
    //   discount = price * 0.5 * (participantsCount - 2);
    // }
    return Math.round(discount * 100) / 100;
  };

  changeParticipants = (participants) => {
    this.setState({
      participants,
    });
  };

  setEInvoice = (event) => {
    this.setState({
      eInvoice: event.target.checked,
    });
  };

  renderStepActions = (stepIndex) => (
    <div className="l-basket__actions">
      <div style={{ marginTop: 12 }}>
        <FlatButton
          label="Wstecz"
          disabled={stepIndex === 0}
          onClick={this.handlePrev}
          style={{ marginRight: 12 }}
        />
        {stepIndex === 1 ? (
          <RaisedButton
            label="Edytuj"
            primary
            onClick={this.editUser}
            style={{ marginRight: 12 }}
          />
        ) : null}
        <RaisedButton
          label={stepIndex === 3 ? 'Wyślij' : 'Dalej'}
          primary
          onClick={stepIndex === 3 ? this.registerForTraining : this.handleNext}
        />
      </div>
    </div>
  );

  render() {
    const { stepIndex } = this.state;
    const { openTraining, openTrainingDate } = this.props;
    return this.props.loading ? (
      <Loading />
    ) : (
      <div style={{ marginTop: 20 }}>
        <div>
          <div className="l-basket__content">
            <Stepper activeStep={stepIndex} orientation="vertical">
              <Step>
                <StepLabel>Szkolenie</StepLabel>
                <StepContent>
                  <h2>{openTraining && openTraining.title}</h2>
                  Szkolenie odbędzie się{' '}
                  {moment(openTrainingDate.trainingDate, 'x').format(
                    'DD-MM-YYYY',
                  )}
                  <br />
                  {openTrainingDate.place}, {openTrainingDate.city}
                  <br />
                  <br />
                  <div className="price-tag">
                    Cena:{' '}
                    {this.state.public
                      ? openTrainingDate.price
                      : calculateGrossPrice({
                          product: openTrainingDate,
                          vatRates: [this.props.vat],
                        })}{' '}
                    za uczestnika
                  </div>
                  <br />
                  <Checkbox
                    label="Finansowane ze środków publicznych"
                    checked={this.state.public}
                    onCheck={this.togglePublic}
                  />
                  <Checkbox
                    label="Wyrażam zgodę na otrzymanie faktury w wersji elektronicznej, wystawionej przez ECRK Lex s.c. z siedzibą w Białymstoku przy al. Jana Pawła II 59/43 na dane podane w formularzu i wysłanie jej na adres email podany w zgłoszeniu."
                    checked={this.state.eInvoice}
                    onCheck={this.setEInvoice}
                  />
                  <br />
                  <br />
                  {this.renderStepActions(0)}
                </StepContent>
              </Step>
              <Step>
                <StepLabel>Dane klienta</StepLabel>
                <StepContent>
                  <UserForm
                    submitButton={this.state.editUser}
                    ref={(node) => {
                      this.userForm = node;
                    }}
                    onSubmit={this.handleUserFormSubmit}
                    user={this.props.user}
                    displayOnly={!this.state.editUser}
                    submit
                  />
                  {this.state.editUser ? null : this.renderStepActions(1)}
                </StepContent>
              </Step>
              <Step>
                <StepLabel>Zapisane osoby</StepLabel>
                <StepContent>
                  <OpenTrainingParticipants
                    changeParticipants={this.changeParticipants}
                    participants={this.state.participants}
                  />
                  <br /> <br />
                  <div className="price-tag">
                    Cena: {this.calculatePrice()}zł
                    <br />
                    {/* Rabat: {this.calculateDiscount()}zł */}
                    {/* <br /> */}
                    <hr />
                    Cena końcowa: {this.calculateFinalPrice()}zł
                  </div>
                  <TextField
                    hintText="Uwagi"
                    multiLine={true}
                    rows={2}
                    rowsMax={4}
                    name="notice"
                    fullWidth
                    onChange={this.changeNotice}
                    value={this.state.notice}
                  />
                  <br />
                  <br />
                  <br />
                  Zgodnie z art. 13 ogólnego rozporządzenia o ochronie danych
                  osobowych z dnia 27 kwietnia 2016 r. (Dz. Urz. UE L 119 z
                  04.05.2016) informuję, iż:
                  <ol>
                    <small>
                      <li>
                        Administratorem Pani/Pana danych osobowych jest ECRK Lex
                        s.c. z siedzibą w Białymstoku przy Al. Jana Pawła II
                        59/43.
                      </li>
                      <li>
                        Pani/Pana dane osobowe przetwarzane będą w celu
                        realizacji zamówienia - na podstawie Art. 6 ust. 1 lit.
                        b ogólnego rozporządzenia o ochronie danych osobowych z
                        dnia 27 kwietnia 2016 r.
                      </li>
                      <li>
                        Odbiorcami Pani/Pana danych osobowych będą podmioty
                        uczestniczące w realizacji zamówienia - ECRK Lex s.c.,
                        EduLex, Prolegis Elżbieta Linowska.
                      </li>
                      <li>
                        Pani/Pana dane osobowe przechowywane będą przez okres 6
                        lat.
                      </li>
                      <li>
                        Posiada Pani/Pan prawo do żądania od administratora
                        dostępu do danych osobowych, prawo do ich sprostowania,
                        usunięcia lub ograniczenia przetwarzania oraz prawo do
                        przenoszenia danych.
                      </li>
                      <li>
                        Ma Pani/Pan prawo wniesienia skargi do organu
                        nadzorczego.
                      </li>
                      <li>
                        Podanie danych osobowych jest dobrowline, jednakże
                        odmowa podania danych może skutkować odmową realizacji
                        zamówienia.
                      </li>
                    </small>
                  </ol>
                  {this.state.participantsError ? (
                    <InfoPanel
                      role="error"
                      fade
                      clearErrorCb={this.clearParticipantsError}
                    >
                      {this.state.participantsError}
                    </InfoPanel>
                  ) : null}
                  <br />
                  {this.renderStepActions(2)}
                </StepContent>
              </Step>
              <Step>
                <StepLabel>Podsumowanie</StepLabel>
                <StepContent>
                  <h2>{openTraining && openTraining.title}</h2>
                  Szkolenie odbędzie się{' '}
                  {moment(openTrainingDate.trainingDate, 'x').format(
                    'DD-MM-YYYY',
                  )}
                  <br />
                  {openTrainingDate.place}, {openTrainingDate.city}
                  <br />
                  <br />
                  <h2>Uczestnicy:</h2>
                  <OpenTrainingParticipants
                    changeParticipants={this.changeParticipants}
                    participants={this.state.participants}
                    displayOnly
                  />
                  <h2>Cena końcowa</h2>
                  Cena: {this.calculatePrice()}zł
                  <br />
                  {/* Rabat: {this.calculateDiscount()}zł */}
                  {/* <br /> */}
                  <hr />
                  Cena końcowa {this.calculateFinalPrice()}zł
                  {this.renderStepActions(3)}
                </StepContent>
              </Step>
            </Stepper>
          </div>
        </div>
      </div>
    );
  }
}

export default withGraphqlHook((props) => {
  const { user: contextUser, setUser } = useContext(AppContext);

  const user = cleanUserProfile(contextUser);

  const [updateUserProfile] = useMutation(updateUserProfileMutation, {
    onCompleted: (data) => {
      const newUser = { ...contextUser, profile: data.updateUserProfile };
      setUser(newUser);
    },
  });

  const [registerForTraining] = useMutation(registerForTrainingMutation);

  const hasContactPerson =
    contextUser && !!contextUser.profile.contactPersonName;

  const { data: openTrainingData, loading: openTrainingLoading } = useQuery(
    getOpenTrainingWithDateQuery,
    {
      variables: {
        openTrainingSlug: props.params.slug,
        openTrainingDateId: props.params.dateId,
      },
    },
  );

  const { data: vatData, loading: vatLoading } = useQuery(getVatRatesQuery);

  const loading = openTrainingLoading || vatLoading;

  const openTraining = get(openTrainingData, 'getOpenTrainingWithDate', null);
  const vatRates = get(vatData, 'getVatRates', []);
  let vat = null;
  let openTrainingDate = null;

  if (openTraining) {
    [openTrainingDate] = openTraining.dates;
    vat = vatRates.find((rate) => rate._id === openTrainingDate.vatRate);
  }

  return {
    loading,
    user,
    hasContactPerson,
    openTraining,
    openTrainingDate,
    vat,
    updateUserProfile,
    registerForTraining,
  };
}, OpenTrainingRegistrationForm);

OpenTrainingRegistrationForm.propTypes = {};
