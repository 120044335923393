import React from "react";
import { DatePicker } from "material-ui";
import PropTypes from "prop-types";

export const getAdditionalFieldsErrors = (
  additionalFieldsErrors,
  form,
  pageContent,
) => {
  const errors = additionalFieldsErrors;
  if (!form.serviceType) {
    return {};
  }

  const chosenField = pageContent.additionalFields.find(
    field => field.name === form.serviceType,
  );

  const requiredFields = chosenField.addFields.filter(
    field => field.isRequired,
  );

  if (!requiredFields.length) {
    return {};
  }

  requiredFields.forEach(field => {
    const valueToCheck = form.additionalFields[field.name];
    errors[field.name] = valueToCheck ? "" : "Pole wymagane";
  });

  return errors;
};

export default function AdditionalFields({
  errors,
  form,
  options,
  changeDate,
}) {
  const getSelectedOptions = () =>
    options.find(option => option.name === form.serviceType).addFields;

  return (
    <>
      <br />
      <br />
      {getSelectedOptions().map(selectedOption => (
        <DatePicker
          key={selectedOption.name}
          hintText={selectedOption.displayName}
          DateTimeFormat={Intl.DateTimeFormat}
          value={form.additionalFields[selectedOption.name]}
          onChange={(event, date) =>
            changeDate(event, date, selectedOption.name, true)
          }
          locale="pl"
          okLabel="Wybierz"
          cancelLabel="Anuluj"
          errorText={errors.additionalFields[selectedOption.name]}
          fullWidth
        />
      ))}
    </>
  );
}

AdditionalFields.propTypes = {
  changeDate: PropTypes.func.isRequired,
  changeSelectValue: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
      addFields: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
          name: PropTypes.string,
          value: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
};
