import React from 'react';
import {
  Router,
  Route,
  IndexRoute,
  browserHistory,
  Redirect,
} from 'react-router';
import App from './App';
import generatePageTitle from './utils/generatePageTitle';
import Home from './components/Home/Home';
import NotFound from './utils/notFound';
import NewsView from './components/newsView';
import SingleNewsView from './components/SingleNewsView/SingleNewsView';
import ProductDetails from './components/productDetails/productDetails';
import ProductsList from './components/productsList/ProductsList';
import SingleOpenTrainingDate from './components/OpenTrainings/SingleOpenTrainingDate';
import OpenTrainings from './components/OpenTrainings/OpenTrainings';
import SingleOpenTraining from './components/OpenTrainings/SingleOpenTraining';
import BasketView from './components/basketView/BasketView';
import Login from './components/user/login';
import SchoolProductDetails from './components/SchoolProductDetails/SchoolProductDetails';
import Search from './components/Search/Search';
import OpenTrainingRegistrationForm from './components/OpenTrainings/OpenTrainingRegistrationForm';
import ClosedTrainings from './components/ClosedTrainings/ClosedTrainingsList';
import SingleLegalActView from './components/LegalActs/SingleLegalActView';
import LegalActs from './components/LegalActs/LegalActs';
import Council from './components/Council/CouncilDisplay';
import About from './components/about/About';
import Manual from './components/Manual/manual';
import Regulations from './components/regulations/Regulations';
import Contact from './components/Contact/Contact';
import OnlineTrainings from './components/OnlineTrainings/OnlineTrainings';
import SingleOnlineTraining from './components/SingleOnlineTraining/SingleOnlineTraining';
import OnlineTrainingRegistrationForm from './components/OnlineTrainingRegistrationForm/OnlineTrainingRegistrationForm';
import SignUp from './components/user/signup';
import GDPR from './components/GDPR/GDPR';
import Recommendations from './components/Recommendations/Recommendations';
import FAQ from './components/FAQ/FAQ';
import UserProfile from './components/userProfile/userProfile';
import isLoggedIn from './utils/isLoggedIn';
import UserProducts from './components/userProducts/userProducts';
import UserOpenTrainings from './components/UserOpenTrainings/UserOpenTrainings';
import UserOnlineTrainings from './components/UserOnlineTrainings/UserOnlineTrainings';
import UserSingleDocumentPage from './components/UserSingleDocumentPage/UserSingleDocumentPage';
import UserOrders from './components/userOrders/userOrders';
import UserData from './components/userData/userData';
import { GoodToKnowPage } from './components/GoodToKnow';
import GoodToKnowDetails from './components/GoodToKnow/GoodToKnow.details';
import { setNoIndex } from './utils/setNoIndex';

function loggedIn() {
  if (!isLoggedIn()) {
    browserHistory.push('/login');
  }
}

const checkTrailingSlash = (pathname) => {
  if (pathname.charAt(pathname.length - 1) === '/' && pathname.length > 1) {
    browserHistory.push(pathname.slice(0, -1));
  }
};

const updatePageTitle = (pathname) => {
  generatePageTitle(pathname);
};

const onRouteUpdate = () => {
  window.scrollTo(0, 0);
  const { pathname } = browserHistory.getCurrentLocation();
  checkTrailingSlash(pathname);
  updatePageTitle(pathname);
  setNoIndex(pathname);
};

const Routes = () => {
  return (
    <Router history={browserHistory} onUpdate={onRouteUpdate}>
      <Route exact path="/" component={App}>
        <IndexRoute name="index" component={Home} />
        <Route path="aktualnosci" component={NewsView} />
        <Route path="news/:newsSlug" component={SingleNewsView} />
        <Route
          path="produkty/dokumenty-szkolne-wzory(/:pageNo)"
          component={ProductsList}
        />
        <Redirect
          from="produkty/dokumenty(/:pageNo)"
          to="produkty/dokumenty-szkolne-wzory(/:pageNo)"
        />
        <Route
          path="produkty/e-szkolenia-dla-nauczycieli(/:pageNo)"
          component={ProductsList}
        />
        <Redirect
          from="produkty/e-szkolenia(/:pageNo)"
          to="produkty/e-szkolenia-dla-nauczycieli(/:pageNo)"
        />
        <Route path="produkty/statuty" component={ProductsList} />
        <Route path="produkty/statuty(/:pageNo)" component={ProductsList} />
        <Route path="produkty/szkolenia-online" component={OnlineTrainings} />
        <Route path="produkty/e-lekcje" component={ProductsList} />
        <Route path="produkty/e-lekcje(/:pageNo)" component={ProductsList} />
        <Route path="produkty/:productSlug" component={ProductDetails} />
        <Route path="produkty-szkolne" component={ProductsList} />
        <Route
          path="produkty-szkolne/produkt/:productSlug"
          component={SchoolProductDetails}
        />
        <Route
          path="produkty-szkolne/:productCategory(/:pageNo)"
          component={ProductsList}
        />
        <Route
          path="szkolenia-otwarte-dla-nauczycieli"
          component={OpenTrainings}
        />
        <Route
          path="szkolenia-otwarte-dla-nauczycieli/:slug"
          component={SingleOpenTraining}
        />
        <Route
          path="szkolenia-otwarte-dla-nauczycieli/:slug/:dateId"
          component={SingleOpenTrainingDate}
        />

        <Redirect
          from="szkolenia-otwarte"
          to="szkolenia-otwarte-dla-nauczycieli"
        />
        <Redirect
          from="szkolenia-otwarte/:slug"
          to="szkolenia-otwarte-dla-nauczycieli/:slug"
        />
        <Redirect
          from="szkolenia-otwarte/:slug/:dateId"
          to="szkolenia-otwarte-dla-nauczycieli/:slug/:dateId"
        />

        <Route path="koszyk" component={BasketView} />
        <Route path="login" component={Login} />
        <Route path="login/:token" component={Login} />
        <Route path="szukaj/" component={Search} />
        <Route path="szukaj/:searchString" component={Search} />
        <Route
          path="zapisy-online/:slug/:dateId"
          component={OpenTrainingRegistrationForm}
        />
        <Route
          path="szkolenia-zamkniete-dla-nauczycieli"
          component={ClosedTrainings}
        />
        <Redirect
          from="szkolenia-zamkniete"
          to="szkolenia-zamkniete-dla-nauczycieli"
        />
        <Route
          path="akty-prawne/akt/:legalActId"
          component={SingleLegalActView}
        />
        <Route path="akty-prawne(/:pageNo)" component={LegalActs} />
        <Route path="rada-programowa" component={Council} />
        <Route path="o-nas" component={About} />
        <Route path="instrukcja-pobrania-dokumentow" component={Manual} />
        <Route path="instrukcja-e-szkolenia" component={Manual} />
        <Route path="instrukcja-e-lekcje" component={Manual} />
        <Route path="regulamin" component={Regulations} />
        <Route path="kontakt" component={Contact} />
        <Route
          path="produkty/szkolenia-online/:slug"
          component={SingleOnlineTraining}
        />
        <Route
          path="produkty/szkolenia-online/zapisy/:slug/:dateId"
          component={OnlineTrainingRegistrationForm}
        />
        <Route path="zarejestruj" component={SignUp} />
        <Route path="rodo" component={GDPR} />
        <Route path="opinie" component={Recommendations} />
        <Route path="faq" component={FAQ} />
        {/* <Route path="kontrola-zarzadcza" component={ManagementVerification} /> */}

        <Route path="profil" component={UserProfile} onEnter={loggedIn}>
          <IndexRoute name="E-szkolenia" component={UserProducts} />
          <Route
            name="E-szkolenia"
            path="e-szkolenia"
            component={UserProducts}
          />
          <Route name="E-lekcje" path="e-lekcje" component={UserProducts} />
          <Route
            name="Szkolenia"
            path="szkolenia-otwarte"
            component={UserOpenTrainings}
          />
          <Route
            name="Szkolenia"
            path="szkolenia-online"
            component={UserOnlineTrainings}
          />
          <Route name="Dokumenty" path="dokumenty" component={UserProducts} />
          <Route
            name="Produkt"
            path="produkty/:documentId"
            component={UserSingleDocumentPage}
          />
          <Route name="Zamówienia" path="zamowienia" component={UserOrders} />
          <Route name="Dane" path="dane" component={UserData} />
        </Route>
        {/* HOLD: */}
        {/* <Route path="doradztwo" component={Advisory} /> */}
        <Route path="warto-wiedziec/:category" component={GoodToKnowPage} />
        <Route
          path="warto-wiedziec/artykul/:slug"
          component={GoodToKnowDetails}
        />
        <Route path="*" component={NotFound} />
      </Route>
    </Router>
  );
};

Routes.propTypes = {};

Routes.defaultProps = {};

export default Routes;
