import React, { useState } from 'react';
import { RaisedButton, TextField } from 'material-ui';
import PropTypes from 'prop-types';

export default function ChangePasswordForm(props) {
  const [password, setPassword] = useState('');
  const [passwordConf, setPasswordConf] = useState('');
  const [pwdError, setPwdError] = useState('');

  const changeValue = (event) => {
    if (event.target.name === 'password') {
      setPassword(event.target.value);
    } else {
      setPasswordConf(event.target.value);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (password && password.length > 4 && password === passwordConf) {
      await props.changePassword({
        variables: { password, token: props.token },
      });
    } else {
      setPwdError(
        'Podane hasła nie są identyczne, bądź mają mniej niż 5 znaków',
      );
    }
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        onKeyPress={(event) =>
          event.key === 'Enter' ? event.preventDefault() : ''
        }
      >
        <TextField
          value={password}
          floatingLabelText="Hasło"
          fullWidth
          onChange={changeValue}
          name="password"
          type="password"
          errorText={pwdError}
        />
        <TextField
          value={passwordConf}
          floatingLabelText="Powtórz hasło"
          fullWidth
          onChange={changeValue}
          name="passwordConf"
          type="password"
        />
        <br />
        <RaisedButton primary label="Zmień" type="submit" />
      </form>
    </div>
  );
}

ChangePasswordForm.propTypes = {
  changePassword: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};
