import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import {
  Card,
  CardText,
  CardTitle,
  List,
  ListItem,
  RaisedButton,
  TextField,
} from 'material-ui';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import ReactPaginate from 'react-paginate';
import { browserHistory } from 'react-router';
import { Loading } from '../../utils/loading';
import getLegalActsQuery from '../../graphql/queries/getLegalActs.graphql';
import getPageSettingsQuery from '../../graphql/queries/getPageSettings.graphql';
import DynamicColumnView from '../../NewLayout/DynamicColumnView';
import LegalAct from './LegalAct';

const baseUrl = '/akty-prawne';
const LegalActsList = ({ params }) => {
  const currentPage = parseInt(params.pageNo, 10) || 0;
  const [search, setSearch] = useState(''); // TODO use search value somewhere?
  const [queryFilter, setQueryFilter] = useState('');
  useEffect(() => {
    browserHistory.push(baseUrl);
  }, [queryFilter]);
  const { data: legalActsData, loading: legalActsLoading } = useQuery(
    getLegalActsQuery,
    {
      variables: { page: currentPage, filter: queryFilter },
    },
  );
  const { data, loading: settingsLoading } = useQuery(getPageSettingsQuery, {
    variables: { type: 'pageContent', page: 'legalActs' },
  });
  const [windowWidth, setWindowWidth] = useState();
  const setNewWindowSize = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', setNewWindowSize);
    return () => {
      window.onresize = null;
    };
  }, []);

  const pageSettings = get(data, 'getPageSettings.settings', null);
  const legalActs = get(legalActsData, 'getLegalActs.list', []);
  const pagesCount = get(legalActsData, 'getLegalActs.pages', 1);
  const loading = legalActsLoading || settingsLoading;

  const searchChange = (event) => {
    setSearch(event.target.value);
  };
  const handlePageClick = (page) => {
    if (page.selected === 0) {
      browserHistory.push(baseUrl);
    } else if (currentPage !== page.selected) {
      browserHistory.push(`${baseUrl}/${page.selected}`);
    }
  };
  const isMobile = windowWidth < 720;
  const resultRender = legalActs.map((legalAct) => (
    <LegalAct {...legalAct} key={legalAct._id} />
  ));

  return (
    <DynamicColumnView
      isMobile={isMobile}
      style={{ minWidth: '310px' }}
      ready={!loading}
      {...pageSettings}
    >
      <div className="newsGroup">
        <div className="row row--flex">
          <div className="col-lg-12" style={{ width: '100%' }}>
            {loading ? (
              <Loading />
            ) : (
              <Card>
                <CardTitle title={pageSettings.title} />
                {pageSettings.content ? (
                  <CardText
                    dangerouslySetInnerHTML={{
                      __html: pageSettings.content,
                    }}
                  />
                ) : null}
              </Card>
            )}
          </div>
        </div>
        <div className="row row--flex">
          <div
            className="col-sm-8 col-lg-12"
            style={{ marginTop: '20px', width: '100%' }}
          >
            <Card>
              <List>
                <ListItem disabled>
                  <TextField
                    fullWidth
                    hintText="Szukaj..."
                    onChange={searchChange}
                    value={search}
                    onKeyPress={(ev) => {
                      if (ev.key === 'Enter') {
                        ev.preventDefault();
                        setQueryFilter(search);
                      }
                    }}
                  />
                  <RaisedButton
                    primary={true}
                    label={'Szukaj'}
                    onClick={() => setQueryFilter(search)}
                  />
                </ListItem>
                {resultRender}
              </List>
            </Card>
          </div>
        </div>
      </div>
      <div className="paginationwrapper" style={{ marginTop: '20px' }}>
        {pagesCount > 1 ? (
          <ReactPaginate
            pageCount={pagesCount}
            nextLabel={'Następna'}
            previousLabel={'Poprzednia'}
            onPageChange={handlePageClick}
            disableInitialCallback
            activeClassName={'activePaginate'}
            forcePage={currentPage}
          />
        ) : null}
      </div>
    </DynamicColumnView>
  );
};

export default LegalActsList;
LegalActsList.propTypes = {
  params: PropTypes.shape({
    pageNo: PropTypes.string,
  }).isRequired,
};

LegalActsList.defaultProps = {
  categories: [],
};
