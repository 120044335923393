import React, { useContext, useState } from 'react';
import {
  Card,
  CardActions,
  CardText,
  CardTitle,
  RaisedButton,
  TextField,
} from 'material-ui';
import './styles.scss';
import { useMutation } from '@apollo/react-hooks';
import InfoPanel from '../../utils/InfoPanel/InfoPanel';
import sendContactMessageMutation from '../../graphql/mutations/sendContactMessage.graphql';
import { AppContext } from '../../common/AppContext';

export default function ContactForm() {
  const [mail, setMail] = useState('');
  const [content, setContent] = useState('');
  const [mailError, setMailError] = useState('');
  const [contentError, setContentError] = useState('');
  const [isSent, setSent] = useState(false);
  const { setMessage } = useContext(AppContext);
  const [sendMessage] = useMutation(sendContactMessageMutation, {
    onCompleted: () => {
      setSent(true);
      setMail('');
      setContent('');
    },
  });

  const changeValue = (event) => {
    if (event.target.name === 'mail') {
      setMailError('');
    }
    if (event.target.name === 'content') {
      setContentError('');
    }
    if (event.target.name === 'mail') {
      setMail(event.target.value);
    } else {
      setContent(event.target.value);
    }
  };

  const send = async () => {
    if (!mail) {
      setMailError('Podaj adres e-mail');
      return;
    }
    if (!content) {
      setContentError('Podaj treść');
      return;
    }

    if (!mailError && !contentError) {
      await sendMessage({ variables: { email: mail, content } });
      setMessage('Wiadomość wysłana!');
    }
  };

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-md-6 col-md-offset-3">
          <Card>
            <CardTitle title="Formularz kontaktowy" className="cfTitle" />
            {isSent ? (
              <InfoPanel role="info">Wiadomość została wysłana</InfoPanel>
            ) : (
              <React.Fragment>
                <CardText>
                  <TextField
                    floatingLabelText="Podaj e-mail"
                    fullWidth
                    onChange={changeValue}
                    name="mail"
                    errorText={mailError}
                  />
                  <TextField
                    floatingLabelText="Treść wiadomości"
                    fullWidth
                    onChange={changeValue}
                    multiLine
                    name="content"
                    rows={8}
                    rowsMax={8}
                    errorText={contentError}
                  />
                </CardText>
                <CardActions>
                  <RaisedButton label="Wyślij" primary onClick={send} />
                </CardActions>
              </React.Fragment>
            )}
          </Card>
          <br />
          <br />
        </div>
      </div>
    </React.Fragment>
  );
}
