/* eslint-disable max-lines */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardActions,
  CardText,
  RaisedButton,
  Subheader,
  SelectField,
  MenuItem,
  TextField,
  LinearProgress,
} from 'material-ui';
import './index.scss';
import { useMutation } from '@apollo/react-hooks';
import schoolTypes from '../../utils/schoolTypes';
import USER_SHAPE from '../common/shapes/USER_SHAPE';
import { AppContext } from '../../common/AppContext';
import withGraphqlHook from '../../common/withGraphqlHook';
import sendGDPRFormMutation from '../../graphql/mutations/sendGDPRForm.graphql';
import AdditionalFields, {
  getAdditionalFieldsErrors,
} from './AdditionalFields';

const getDefaultFormState = (props) => ({
  place: '',
  schoolType: '',
  contactPersonEmail: props.user ? props.user.username : '',
  contactPersonName: props.user ? props.user.profile.contactPersonName : '',
  contactPersonPhone: props.user ? props.user.profile.contactPersonPhone : '',
  comment: '',
  serviceType: '',
  additionalFields: {},
});

class GDPRForm extends React.Component {
  state = {
    form: getDefaultFormState(this.props),
    errors: {
      place: '',
      schoolType: '',
      contactPersonName: '',
      contactPersonEmail: '',
      contactPersonPhone: '',
      additionalFields: {},
    },
    saving: null,
  };

  static contextType = AppContext;

  updateFormValue = (name, value, isAdditionalField) => {
    const form = { ...this.state.form };
    const errors = { ...this.state.errors };

    if (isAdditionalField) {
      form.additionalFields = {
        ...this.state.form.additionalFields,
        [name]: value,
      };
      errors.additionalFields = {
        ...this.state.errors.additionalFields,
        [name]: '',
      };
    } else {
      form[name] = value;
      errors[name] = '';
    }

    this.setState({ form, errors });
  };

  changeDate = (event, value, name, isAdditionalField = false) => {
    this.updateFormValue(name, value, isAdditionalField);
  };

  changeInputValue = (event, index, isAdditionalField = false) => {
    const { name, value } = event.target;
    this.updateFormValue(name, value, isAdditionalField);
  };

  changeSelectValue = (
    event,
    index,
    value,
    name,
    isAdditionalField = false,
  ) => {
    this.updateFormValue(name, value, isAdditionalField);
  };

  formHasErrors = (errors) => {
    let isFormValid = true;

    const checkErrors = (errorsToCheck) => {
      Object.entries(errorsToCheck).forEach(([key, value]) => {
        if (key === 'additionalFields') {
          checkErrors(errors.additionalFields);
        } else if (value && isFormValid) {
          isFormValid = false;
        }
      });
    };

    checkErrors(errors);

    return isFormValid;
  };

  getErrors = () => {
    const { form } = this.state;
    const errors = { ...this.state.errors };

    Object.entries(form).forEach(([key, value]) => {
      if (key === 'comment' || key === 'additionalFields') {
        return;
      }

      // potential bug with values nested inside objects or dates

      errors[key] = value ? '' : 'Pole wymagane';
    });

    return errors;
  };

  validateForm = () => {
    const additionalFieldsErrors = { ...this.state.errors.additionalFields };
    const { form } = this.state;
    const { pageContent } = this.props;

    const updatedErrors = {
      ...this.getErrors(),
      additionalFields: {
        ...getAdditionalFieldsErrors(additionalFieldsErrors, form, pageContent),
      },
    };
    this.setState((previousState) => ({
      ...previousState,
      errors: updatedErrors,
    }));

    return this.formHasErrors(updatedErrors);
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { form } = this.state;
    const isFormValid = this.validateForm();

    if (isFormValid) {
      this.setState({
        saving: true,
      });

      await this.props.sendGDPRForm({ variables: { input: form } });
      this.setState({ saving: false });
      this.context.setMessage('Zgłoszenie wysłane');
      this.setState({ form: getDefaultFormState(this.props) });
    }
  };

  render() {
    const { errors, form } = this.state;
    const { pageContent } = this.props;
    return (
      <Card>
        <CardText>
          <div className="row">
            <form onSubmit={this.handleSubmit}>
              <CardText>
                <div className="row">
                  <div className="col-md-6">
                    <Subheader className="form__subheader">Dane</Subheader>

                    <TextField
                      value={form.place}
                      floatingLabelText="Miejsce"
                      errorText={errors.place}
                      fullWidth
                      onChange={this.changeInputValue}
                      aria-label="place"
                      name="place"
                    />

                    <SelectField
                      floatingLabelText="Rodzaj placówki"
                      value={form.schoolType}
                      onChange={(event, index, value) =>
                        this.changeSelectValue(
                          event,
                          index,
                          value,
                          'schoolType',
                        )
                      }
                      errorText={errors.schoolType}
                      fullWidth
                    >
                      {schoolTypes.map((school) => (
                        <MenuItem
                          key={school.value}
                          value={school.value}
                          primaryText={school.name}
                        />
                      ))}
                    </SelectField>

                    <SelectField
                      floatingLabelText="Rodzaj usługi"
                      value={form.serviceType}
                      onChange={(event, index, value) =>
                        this.changeSelectValue(
                          event,
                          index,
                          value,
                          'serviceType',
                        )
                      }
                      errorText={errors.serviceType}
                      fullWidth
                    >
                      {pageContent.additionalFields.map((field) => (
                        <MenuItem
                          key={field.name}
                          value={field.name}
                          primaryText={field.displayName}
                        />
                      ))}
                    </SelectField>

                    {form.serviceType && (
                      <AdditionalFields
                        errors={errors}
                        form={form}
                        options={pageContent.additionalFields}
                        changeDate={this.changeDate}
                        changeSelectValue={this.changeSelectValue}
                      />
                    )}

                    <TextField
                      value={form.comment}
                      multiLine
                      rows={2}
                      rowsMax={4}
                      floatingLabelText="Komentarz"
                      fullWidth
                      onChange={this.changeInputValue}
                      aria-label="comment"
                      name="comment"
                    />
                    <br />
                  </div>

                  <div className="col-md-6">
                    <Subheader className="form__subheader">
                      Osoba kontaktowa
                    </Subheader>
                    <TextField
                      value={form.contactPersonName}
                      floatingLabelText="Imię i nazwisko"
                      fullWidth
                      onChange={this.changeInputValue}
                      aria-label="registration-contactPersonName"
                      name="contactPersonName"
                      errorText={
                        errors.contactPersonName ? errors.contactPersonName : ''
                      }
                    />
                    <br />
                    <TextField
                      value={form.contactPersonEmail}
                      floatingLabelText="E-mail kontaktowy"
                      fullWidth
                      onChange={this.changeInputValue}
                      aria-label="registration-contactPersonEmail"
                      name="contactPersonEmail"
                      errorText={
                        errors.contactPersonEmail
                          ? errors.contactPersonEmail
                          : ''
                      }
                    />
                    <TextField
                      value={form.contactPersonPhone}
                      floatingLabelText="Telefon kontaktowy"
                      fullWidth
                      onChange={this.changeInputValue}
                      aria-label="registration-contactPersonPhone"
                      name="contactPersonPhone"
                      errorText={
                        errors.contactPersonPhone
                          ? errors.contactPersonPhone
                          : ''
                      }
                    />
                  </div>
                </div>
              </CardText>

              <CardActions className="form__actions">
                <RaisedButton
                  type="submit"
                  primary
                  disabled={this.state.saving}
                  label={
                    this.state.saving ? (
                      <span>
                        ...
                        <LinearProgress size={10} color="#fff" />
                      </span>
                    ) : (
                      'Wyślij'
                    )
                  }
                />
              </CardActions>

              <CardText style={{ fontSize: '12px' }}>
                Zgodnie z art. 13 ogólnego rozporządzenia o ochronie danych
                osobowych z dnia 27 kwietnia 2016 r. (Dz. Urz. UE L 119 z
                04.05.2016) informujemy, iż:
                <ol style={{ fontSize: '12px' }}>
                  <li>
                    Administratorem Pani/Pana danych osobowych jest ECRK Lex
                    s.c. z siedzibą w Białymstoku przy Al. Jana Pawła II 59/43.
                  </li>
                  <li>
                    Pani/Pana dane osobowe przetwarzane będą w celu realizacji
                    zamówienia - na podstawie Art. 6 ust. 1 lit. b ogólnego
                    rozporządzenia o ochronie danych osobowych z dnia 27
                    kwietnia 2016 r.
                  </li>
                  <li>
                    Odbiorcami Pani/Pana danych osobowych będą podmioty
                    uczestniczące w realizacji zamówienia - ECRK Lex s.c.,
                    EduLex, Prolegis Elżbieta Linowska.
                  </li>
                  <li>
                    Pani/Pana dane osobowe przechowywane będą przez okres 6 lat.
                  </li>
                  <li>
                    Posiada Pani/Pan prawo do żądania od administratora dostępu
                    do danych osobowych, prawo do ich sprostowania, usunięcia
                    lub ograniczenia przetwarzania oraz prawo do przenoszenia
                    danych.
                  </li>
                  <li>
                    Ma Pani/Pan prawo wniesienia skargi do organu nadzorczego.
                  </li>
                  <li>
                    Podanie danych osobowych jest dobrowolne, jednakże odmowa
                    podania danych może skutkować odmową realizacji zamówienia.
                  </li>
                </ol>
                Administrator danych osobowych ECRK Lex S.C.
              </CardText>
            </form>
          </div>
        </CardText>
      </Card>
    );
  }
}

export default withGraphqlHook(() => {
  const [sendGDPRForm] = useMutation(sendGDPRFormMutation);
  return { sendGDPRForm };
}, GDPRForm);

GDPRForm.defaultProps = {
  user: null,
};

GDPRForm.propTypes = {
  user: USER_SHAPE,
  pageContent: PropTypes.shape({
    _id: PropTypes.string,
    content: PropTypes.string,
    hasTwoColumns: PropTypes.bool,
    secondColumn: PropTypes.array,
    title: PropTypes.string,
    type: PropTypes.string,
    additionalFields: PropTypes.array,
  }).isRequired,
};
