import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { useApolloClient } from '@apollo/react-hooks';
import Navbar from '../components/Home/Navbar';
import LowerMenu from '../components/Home/LowerMenu';
import { menuItems, lowerMenuItems } from '../components/Home/menuItem';
import Footer from '../components/Home/Footer';
import TopHeader from '../components/Home/TopHeader';
import Notifier from '../components/notifier/Notifier';
import './NewLayout.scss';
import { AppContext } from '../common/AppContext';
import { logout } from '../common/methods/logout';

export const Home = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [stickTopMenu, setStickTopMenu] = useState(false);
  const [stickBottomMenu, setStickBottomMenu] = useState(false);

  const setNewWindowSize = () => {
    setWindowWidth(window.innerWidth);
  };

  const updateStickyMenu = () => {
    setStickTopMenu(window.pageYOffset > 140);
    setStickBottomMenu(window.pageYOffset > 155);
  };

  useEffect(() => {
    window.addEventListener('resize', setNewWindowSize);
    window.onscroll = updateStickyMenu;

    return () => {
      window.onresize = null;
      window.onscroll = null;
    };
  }, []);

  const isMobile = windowWidth < 720;
  const navbarContent = isMobile
    ? [...menuItems, ...lowerMenuItems]
    : menuItems;
  const { user, setUser, setMessage } = useContext(AppContext);
  const client = useApolloClient();

  const handleLogout = () => {
    browserHistory.push('/');
    client.cache.reset();
    setUser(null);
    logout(() => setMessage('Wylogowano'));
  };

  return (
    <div className="newlayout-wrapper">
      <div>
        <div className="container">
          <TopHeader
            isMobile={isMobile}
            user={user}
            handleLogout={handleLogout}
          />
        </div>

        <Navbar
          menuItems={navbarContent}
          isMobile={isMobile}
          loggedIn={!!user}
          stickMenu={stickTopMenu}
          handleLogout={handleLogout}
        />

        {isMobile ? null : (
          <LowerMenu isMobile={isMobile} stickMenu={stickBottomMenu} />
        )}
        <div className="container">{props.children}</div>
      </div>
      <Notifier />
      <Footer />
    </div>
  );
};

Home.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Home;
