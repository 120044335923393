import customTitles from './customTitles';

export default function generatePageTitle(pathname) {
  let pageMeta = customTitles[0];
  const el = document.querySelector("meta[name='description']");

  const customTitle = customTitles.find(({ path }) => pathname === path);
  if (customTitle) {
    pageMeta = customTitle;
  }

  document.title = pageMeta.title;
  el.setAttribute('content', pageMeta.description);
}

export function generateSubpageTitle(productName, overrideLength = false) {
  const titleSuffix = '- ECRK Lex';
  const allowedLength = 60 - titleSuffix.length;
  let title = `${productName} ${titleSuffix}`;
  if (productName.length > allowedLength && !overrideLength) {
    title = `${productName.substring(0, allowedLength)}... ${titleSuffix}`;
  }

  const el = document.querySelector("meta[name='description']");
  document.title = title;
  el.setAttribute(
    'content',
    `${productName} ➤ Odkryj naszą ofertę produktów edukacyjnych ⭐ Kup teraz i zapewnij sobie najlepsze wsparcie w nauczaniu!`,
  );
}
