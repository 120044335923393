import React from 'react';
import { ListItem, Toggle } from 'material-ui';

export class Category extends React.Component {
  handleToggle = (event) => {
    this.props.updateFilters(event.target.name);
  };

  toggleChecked = (slug) => this.props.currentFilter.includes(slug);

  render() {
    const thirdLevel = this.props.categories.filter(
      (category) => category.level === 3,
    );
    // TODO: should be done as a recurrency, but something goes wrong
    const subcategories = this.props.children.map((categorySlug) => {
      const currentCategory = this.props.subcategories.find(
        (subcategory) => subcategory.slug === categorySlug,
      );
      if (!currentCategory) {
        return null;
      }
      return (
        <ListItem
          primaryText={currentCategory.name}
          style={{ fontSize: '12px', padding: '10px' }}
          rightToggle={
            <Toggle
              onToggle={this.handleToggle}
              name={currentCategory.slug}
              toggled={this.toggleChecked(currentCategory.slug)}
            />
          }
          key={currentCategory._id}
          open
          nestedItems={currentCategory.children.map((subcategorySlug) => {
            const innerCategory = thirdLevel.find(
              (cat) => cat.slug === subcategorySlug,
            );
            return (
              <ListItem
                primaryText={innerCategory.name}
                style={{ fontSize: '12px', padding: '10px' }}
                rightToggle={
                  <Toggle
                    onToggle={this.handleToggle}
                    name={innerCategory.slug}
                    toggled={this.toggleChecked(innerCategory.slug)}
                  />
                }
                key={innerCategory._id}
              />
            );
          })}
        />
      );
    });
    return (
      <ListItem
        primaryText={this.props.name}
        style={{ fontSize: '12px', padding: '10px' }}
        rightToggle={
          <Toggle
            onToggle={this.handleToggle}
            name={this.props.slug}
            toggled={this.toggleChecked(this.props.slug)}
          />
        }
        key={this.props._id}
        open
        nestedItems={subcategories}
      />
    );
  }
}
