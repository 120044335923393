import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useQuery } from '@apollo/react-hooks';
import Panel from '../common/Panel';
import Recommendation from '../common/Recommendation';
import RecommendationLoading from '../common/RecommendationLoading';
import withGraphqlHook from '../../common/withGraphqlHook';
import getRecommendationsQuery from '../../graphql/queries/getRecommendations.graphql';

function Recommendations({ recommendations, loading }) {
  if (loading) return <RecommendationLoading />;

  return (
    !!recommendations.length && (
      <Panel backgroundColor="#1B4079" title="Opinie" color="#fff">
        <div className="homereco-wrapper">
          {recommendations.map((recommendation) => (
            <Recommendation
              recommendation={recommendation}
              key={recommendation._id}
            />
          ))}
        </div>
      </Panel>
    )
  );
}

export default withGraphqlHook(() => {
  const { data, loading } = useQuery(getRecommendationsQuery);
  const recommendations = get(data, 'getRecommendations', []);

  return { recommendations, loading };
}, Recommendations);

Recommendations.propTypes = {
  recommendations: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};
