import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import { Loading } from '../../utils/loading';
import getPageSettingsQuery from '../../graphql/queries/getPageSettings.graphql';
import getCouncilMembersQuery from '../../graphql/queries/getCouncilMembers.graphql';
import DynamicColumnView from '../../NewLayout/DynamicColumnView';
import CouncilMember from './CouncilMember';

const Council = () => {
  const { data: settingsData, loading: settingsLoading } = useQuery(
    getPageSettingsQuery,
    {
      variables: { type: 'pageContent', page: 'council' },
    },
  );

  const { data: councilData, loading: councilLoading } = useQuery(
    getCouncilMembersQuery,
    {
      variables: { type: 'pageContent', page: 'council' },
    },
  );

  const pageContent = get(settingsData, 'getPageSettings.settings', null);
  const council = get(councilData, 'getCouncilMembers', []);

  const loading = settingsLoading || councilLoading;

  if (loading) {
    return <Loading />;
  }

  return (
    <DynamicColumnView ready={!loading} {...pageContent}>
      <div style={{ marginBottom: 50 }}>
        <h1>Rada Programowa</h1>
        <div dangerouslySetInnerHTML={{ __html: pageContent.content }} />
        {council.length &&
          council.map((member) => (
            <CouncilMember {...member} key={member._id} />
          ))}
      </div>
    </DynamicColumnView>
  );
};

export default Council;
Council.propTypes = {
  council: PropTypes.array,
  ready: PropTypes.bool.isRequired,
  pageContent: PropTypes.object.isRequired,
};

Council.defaultProps = {
  council: [],
};
