import get from 'lodash/get';
import omit from 'lodash/omit';

const emptyUserProfile = {
  profile: {
    name: '',
    city: '',
    address: '',
    zipCode: '',
    phone: '',
    nip: '',
    contactPersonPhone: '',
    contactPersonName: '',
    contactPersonEmail: '',
    invoiceEmail: '',
  },
};

const cleanUserProfile = (contextUser) => {
  const profile = get(contextUser, 'profile', null);
  const cleanProfile = omit(profile, ['__typename']);
  if (cleanProfile.buyerData) {
    cleanProfile.buyerData = omit(cleanProfile.buyerData, '__typename');
  }

  return profile ? { profile: cleanProfile } : null;
};

export { emptyUserProfile, cleanUserProfile };
