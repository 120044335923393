const districts = [
  {
    slug: 'pomorskie',
    name: 'Pomorskie',
  },
  {
    slug: 'zachodniopomorskie',
    name: 'Zachodniopomorskie',
  },
  {
    slug: 'podlaskie',
    name: 'Podlaskie',
  },
  {
    slug: 'warminskoMazurskie',
    name: 'Warmińsko-Mazurskie',
  },
  {
    slug: 'kujawskoPomorskie',
    name: 'Kujawsko-Pomorskie',
  },
  {
    slug: 'wielkopolskie',
    name: 'Wielkopolskie',
  },
  {
    slug: 'mazowieckie',
    name: 'Mazowieckie',
  },
  {
    slug: 'lubuskie',
    name: 'Lubuskie',
  },
  {
    slug: 'lodzkie',
    name: 'Łodzkie',
  },
  {
    slug: 'lubelskie',
    name: 'Lubelskie',
  },
  {
    slug: 'dolnoslaskie',
    name: 'Dolnośląskie',
  },
  {
    slug: 'swietokrzyskie',
    name: 'Świętokrzyskie',
  },
  {
    slug: 'opolskie',
    name: 'Opolskie',
  },
  {
    slug: 'slaskie',
    name: 'Śląskie',
  },
  {
    slug: 'podkarpackie',
    name: 'Podkarpackie',
  },
  {
    slug: 'malopolskie',
    name: 'Małopolskie',
  },
];

export default districts;
