import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const VimeoPlayer = ({ id }) => {
  return (
    <Fragment>
      <iframe
        src={`https://player.vimeo.com/video/${id}`}
        width="640"
        height="360"
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
      />
    </Fragment>
  );
};

VimeoPlayer.propTypes = {
  id: PropTypes.string.isRequired,
};

export default VimeoPlayer;
