import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { CardText, List } from 'material-ui';
import SingleOnlineRegistrationFile from './SingleOnlineRegistrationFile';
import { fileShape } from './utils';

const OnlineRegistrationFiles = ({ files, registrationId }) => {
  const list = files.length ? (
    <List>
      {files.map((file) => (
        <SingleOnlineRegistrationFile
          key={file._id}
          file={file}
          registrationId={registrationId}
        />
      ))}
    </List>
  ) : null;

  return (
    <Fragment>
      <CardText style={{ fontWeight: 'bold' }}>
        Materiały szkoleniowe
        <br />
        <small style={{ fontWeight: 300 }}>
          Materiały można pobrać dwukrotnie, na dwa dni przed szkoleniem lub do
          20 dni po szkoleniu
        </small>
      </CardText>
      {list}
    </Fragment>
  );
};

OnlineRegistrationFiles.propTypes = {
  registrationId: PropTypes.string.isRequired,
  files: PropTypes.arrayOf(fileShape),
};

OnlineRegistrationFiles.defaultProps = {
  files: [],
};

export default OnlineRegistrationFiles;
