import React from 'react';
import HighlightedNews from './HighlightedNews';
import HomePublications from './HomePublications';
import HomeRecommendations from './HomeRecommendations';
import HomeLegalActs from './HomeLegalActs';
import './Home.scss';
import HomeTrainings from './HomeTrainings';

export default class Home extends React.Component {
  state = {
    windowWidth: window.innerWidth,
  };

  componentDidMount() {
    window.addEventListener('resize', this.setNewWindowSize);
  }

  // eslint-disable-next-line class-methods-use-this
  componentWillUnmount() {
    window.onresize = null;
  }

  setNewWindowSize = () => {
    this.setState({
      windowWidth: window.innerWidth,
    });
  };

  render() {
    const isMobile = this.state.windowWidth < 720;
    return (
      <div className="home-wrapper" data-testid="homepage">
        <h1 className="pagetitle">
          Szkolenia dla oświaty - ECRK LEX - Elżbieta Linowska
        </h1>
        <HighlightedNews isMobile={isMobile} />
        <HomePublications isMobile={isMobile} />
        <HomeTrainings isMobile={isMobile} />

        <div className="row">
          <div className="col-lg-8 col-xs-12">
            <HomeRecommendations />
          </div>
          <div className="col-lg-4 col-xs-12">
            <HomeLegalActs />
          </div>
        </div>
      </div>
    );
  }
}
