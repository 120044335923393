import React from 'react';
import PropTypes from 'prop-types';

export default function Amount(props) {
  return (
    <div style={{ color: 'grey', position: 'relative', float: 'right' }}>
      {props.amount}
    </div>
  );
}

Amount.propTypes = {
  amount: PropTypes.number,
};

Amount.defaultProps = {
  amount: 0,
};
