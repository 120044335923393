import React, { useContext, useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  CardText,
  Dialog,
  RaisedButton,
  TextField,
} from 'material-ui';
import { browserHistory } from 'react-router';
import { useMutation } from '@apollo/react-hooks';
import get from 'lodash/get';
import InfoPanel from '../../utils/InfoPanel/InfoPanel';
import InvoiceEmailConfirm from '../InvoiceEmailConfirm/InvoiceEmailConfirm';
import { Loading } from '../../utils/loading';
import { AppContext } from '../../common/AppContext';
import loginMutation from '../../graphql/mutations/login.graphql';
import requestPasswordChangeMutation from '../../graphql/mutations/requestPasswordChange.graphql';
import changeUserPasswordMutation from '../../graphql/mutations/changeUserPassword.graphql';
import ChangePasswordForm from './ChangePasswordForm';

export default function Login(props) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [inProgress, setInProgress] = useState(false);
  const [isConfirmationModalOpen, setConfirmationModal] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [noUsername, setNoUsername] = useState(false);
  const [dialogOpen, setDialog] = useState(false);
  const { setMessage, redirectTo, setRedirectTo, setUser } = useContext(
    AppContext,
  );

  const [requestPasswordChange] = useMutation(requestPasswordChangeMutation, {
    onCompleted: () => {
      setInProgress(false);
    },
  });

  const [changePassword] = useMutation(changeUserPasswordMutation, {
    onCompleted: () => {
      setDialog(false);
      setMessage('Hasło zmienione!');
      browserHistory.push('/');
    },
    onError: (err) => {
      if (err.message === 'GraphQL error: User not found!') {
        setMessage('Token nieprawidłowy!');
      }
      if (err.message === 'GraphQL error: Token invalid!') {
        setMessage('Token stracił ważność!');
      }
      if (err) {
        setMessage('Błąd przy zmianie hasła!');
      }
      setDialog(false);
      browserHistory.push('/');
    },
  });

  useEffect(() => {
    if (!dialogOpen && props.params.token) {
      setDialog(true);
    }
  }, [dialogOpen]);

  const [login] = useMutation(loginMutation, {
    onCompleted: (data) => {
      const user = get(data, 'login', null);
      setInProgress(false);
      if (user) {
        setUser(user);

        if (!user.profile.invoiceEmailConfirmed) {
          setConfirmationModal(true);
          return;
        }

        if (redirectTo) {
          browserHistory.push(redirectTo);
          setRedirectTo('');
        } else {
          browserHistory.push('/');
        }
        setMessage('Zalogowano pomyślnie');
      }
    },
    onError: () => {
      setInProgress(false);
      setLoginError('Błąd logowania - podaj właściwy email i hasło');
    },
  });

  const handleLogin = async (event) => {
    event.preventDefault();
    if (username && password) {
      setInProgress(true);
      await login({
        variables: {
          username,
          password,
        },
      });
    }
  };

  const redirectToSignIn = (event) => {
    event.preventDefault();
    browserHistory.push('/zarejestruj');
  };

  const forgotPassword = async (event) => {
    event.preventDefault();
    if (username) {
      setInProgress(true);
      await requestPasswordChange({ variables: { email: username } });
      setMessage('Wiadomość została wysłana na podany e-mail');
    } else {
      setNoUsername(true);
    }
  };

  const clearPasswordReset = () => {
    setLoginError('');
  };

  const closeConfirmationModal = () => {
    setConfirmationModal(false);
  };

  return (
    <div style={{ marginTop: 10 }}>
      {inProgress && <Loading />}
      <div className="row">
        <div className="col-lg-6 col-md-12 col-lg-offset-3">
          <Card>
            <CardHeader
              title="Witaj"
              subtitle="Podaj login i hasło, lub zarejestruj się"
            />
            <CardText>
              <form onSubmit={handleLogin}>
                <TextField
                  name="username"
                  aria-label="login-username"
                  hintText="Podaj e-mail"
                  floatingLabelText="e-mail"
                  fullWidth
                  onChange={(event, newValue) => setUsername(newValue)}
                  errorText={noUsername ? 'Podaj e-mail' : ''}
                />
                <br />
                <TextField
                  type="password"
                  aria-label="login-password"
                  name="password"
                  hintText="Podaj hasło"
                  floatingLabelText="hasło"
                  fullWidth
                  onChange={(event, newValue) => setPassword(newValue)}
                />
                <br />
                <br />
                <RaisedButton
                  type="submit"
                  label="Zaloguj"
                  disabled={inProgress}
                  data-testid="login-button"
                  primary={true}
                  fullWidth
                />
                <br />
                <br />
                <a href="" onClick={forgotPassword}>
                  Nie pamiętam hasła
                </a>
                {loginError ? (
                  <InfoPanel
                    role="error"
                    fade
                    clearErrorCb={clearPasswordReset}
                  >
                    {loginError}
                  </InfoPanel>
                ) : null}
                <br />
                <br />
                Nie masz konta?{' '}
                <a href="" onClick={redirectToSignIn}>
                  Zarejestruj się
                </a>
              </form>
            </CardText>
            <CardText style={{ fontSize: '12px' }}>
              Zgodnie z art. 13 ogólnego rozporządzenia o ochronie danych
              osobowych z dnia 27 kwietnia 2016 r. (Dz. Urz. UE L 119 z
              04.05.2016) informujemy, iż:
              <ol style={{ fontSize: '12px' }}>
                <li>
                  Administratorem Pani/Pana danych osobowych jest ECRK Lex s.c.
                  z siedzibą w Białymstoku przy Al. Jana Pawła II 59/43.
                </li>
                <li>
                  Pani/Pana dane osobowe przetwarzane będą w celu realizacji
                  zamówienia - na podstawie Art. 6 ust. 1 lit. b ogólnego
                  rozporządzenia o ochronie danych osobowych z dnia 27 kwietnia
                  2016 r.
                </li>
                <li>
                  Odbiorcami Pani/Pana danych osobowych będą podmioty
                  uczestniczące w realizacji zamówienia - ECRK Lex s.c., EduLex,
                  Prolegis Elżbieta Linowska.
                </li>
                <li>
                  Pani/Pana dane osobowe przechowywane będą przez okres 6 lat.
                </li>
                <li>
                  Posiada Pani/Pan prawo do żądania od administratora dostępu do
                  danych osobowych, prawo do ich sprostowania, usunięcia lub
                  ograniczenia przetwarzania oraz prawo do przenoszenia danych.
                </li>
                <li>
                  Ma Pani/Pan prawo wniesienia skargi do organu nadzorczego.
                </li>
                <li>
                  Podanie danych osobowych jest dobrowolne, jednakże odmowa
                  podania danych może skutkować odmową realizacji zamówienia.
                </li>
              </ol>
              Administrator danych osobowych ECRK Lex S.C.
            </CardText>
          </Card>
        </div>
      </div>
      <Dialog title="Zmień hasło" modal={false} open={dialogOpen}>
        <ChangePasswordForm
          token={props.params.token}
          changePassword={changePassword}
        />
      </Dialog>
      <InvoiceEmailConfirm
        isOpen={isConfirmationModalOpen}
        handleClose={closeConfirmationModal}
      />
    </div>
  );
}
