import { createContext } from 'react';

// eslint-disable-next-line import/prefer-default-export
export const AppContext = createContext({
  currentMessage: '',
  cart: [],
  pack: [],
  packFull: false,
  currentDates: {},
  redirectTo: '',
  user: null,
});
