import React from 'react';
import { browserHistory } from 'react-router';
import { CardTitle, CardText, RaisedButton } from 'material-ui';
import { Loading } from '../../utils/loading';

class UserSingleProduct extends React.Component {
  render() {
    const { document } = this.props;
    const { product } = document;
    if (!product) {
      return <Loading />;
    }
    return (
      <div className="order">
        <div
          className="order__inner"
          ref={(node) => {
            this.innerNode = node;
          }}
        >
          <CardTitle title={product.title} />
          <CardText>
            {product.short}
            <div className="order__button-wrapper">
              <RaisedButton
                primary
                label={`Zobacz`}
                onClick={() =>
                  browserHistory.push(`/profil/produkty/${document._id}`)
                }
              />
            </div>
          </CardText>
        </div>
      </div>
    );
  }
}

export default UserSingleProduct;
