import React from 'react';
import PropTypes from 'prop-types';
import { SelectField, MenuItem } from 'material-ui';

export default function ShippingMethodSelect(props) {
  return (
    <SelectField
      floatingLabelText="Sposób dostawy"
      floatingLabelStyle={{ color: 'red' }}
      value={props.shippingMethod}
      onChange={props.changeShippingMethod}
      fullWidth
    >
      {props.shippingMethods &&
        props.shippingMethods.map((method) => (
          <MenuItem
            key={method.name}
            value={method.name}
            primaryText={method.name}
          />
        ))}
    </SelectField>
  );
}

ShippingMethodSelect.propTypes = {
  changeShippingMethod: PropTypes.func.isRequired,
  shippingMethod: PropTypes.object.isRequired,
  shippingMethods: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
    }).isRequired,
  ),
};

ShippingMethodSelect.defaultProps = {
  shippingMethods: [],
};
