import React from 'react';
import { CardText, CardTitle, Divider } from 'material-ui';
import { useQuery } from '@apollo/react-hooks';
import InfoPanel from '../../utils/InfoPanel/InfoPanel';
import { Loading } from '../../utils/loading';
import getOnlineRegistrationsForUserQuery from '../../graphql/queries/getOnlineRegistrationsForUser.graphql';
import OnlineTrainingCertificates from './OnlineTrainingCertificates';
import OnlineRegistrationFiles from './OnlineRegistrationFiles';

const UserOnlineTrainings = () => {
  const { data, loading } = useQuery(getOnlineRegistrationsForUserQuery, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const registrations = (data && data.getOnlineRegistrationsForUser) || [];
  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      {registrations.length > 0 ? (
        registrations.map((registration) =>
          registration.trainingTitle ? (
            <div
              className={`order ${
                registration.trainingFiles.length ? 'order__with-files' : ''
              }`}
              key={registration._id}
            >
              <div className="order__inner">
                <CardTitle
                  title={registration.trainingTitle}
                  subtitle={registration.startDate}
                />
                <CardText>
                  Szczegóły dotyczące uczestnictwa w szkoleniu zostaną
                  dostarczone drogą mailową na wskazany podczas rejestracji
                  adres.
                </CardText>
                <OnlineRegistrationFiles
                  registrationId={registration._id}
                  files={registration.trainingFiles}
                />
                {registration.certificates.length ? (
                  <>
                    <Divider />
                    <OnlineTrainingCertificates
                      certificates={registration.certificates}
                    />
                  </>
                ) : null}
              </div>
            </div>
          ) : null,
        )
      ) : (
        <InfoPanel role="info">Brak produktów do wyświetlenia</InfoPanel>
      )}
    </div>
  );
};

UserOnlineTrainings.propTypes = {};

UserOnlineTrainings.defaultProps = {};

export default UserOnlineTrainings;
