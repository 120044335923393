import React from 'react';
import { Card, CardText } from 'material-ui';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import getPageSettingsQuery from '../../graphql/queries/getPageSettings.graphql';
import { Loading } from '../../utils/loading';

const getPage = (pathname) => {
  switch (pathname) {
    case '/instrukcja-pobrania-dokumentow':
      return 'documents';
    case '/instrukcja-e-szkolenia':
      return 'trainings';
    case '/instrukcja-e-lekcje':
      return 'lessons';
    default:
      return 'documents';
  }
};

const Manual = (props) => {
  const page = getPage(props.location.pathname);
  const { data, loading } = useQuery(getPageSettingsQuery, {
    variables: { type: 'manual', page },
  });
  const pageContent = get(data, 'getPageSettings.settings', null);

  if (loading) {
    return <Loading />;
  }
  return (
    <div style={{ marginTop: 20 }}>
      <Card>
        <CardText dangerouslySetInnerHTML={{ __html: pageContent.content }} />
      </Card>
    </div>
  );
};

export default Manual;

Manual.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};
