import Account from 'material-ui/svg-icons/action/account-box';
import React from 'react';
import './Recommentation.scss';

const RecommendationLoading = () => (
  <div className="recommendation-wrapper">
    <div className="recommendation-imagewrapper">
      <div
        className="recommendation-image"
        style={{
          backgroundImage: `url(/person.png})`,
        }}
      >
        <Account
          style={{
            width: '210px',
            height: '160px',
            color: '#DDDDDD',
          }}
        />
      </div>
    </div>
    <div className="recommendation-text">
      <div className="person">
        <div className="loading" />
      </div>
      <div className="place">
        <div className="loading" />
      </div>
      <div className="date">
        <div className="loading" />
      </div>
      <div className="text">
        <div className="loading" />
        <div className="loading" />
        <div className="loading" />
        <div className="loading" />
      </div>
    </div>
  </div>
);

export default RecommendationLoading;
