import React from 'react';
import PropTypes from 'prop-types';
import Panel from '../common/Panel';
import NewsFilter from '../newsFilter/NewsFilter';
import CategoriesFilter from '../CategoriesFilter/CategoriesFilter';
import SchoolProductsFilter from '../SchoolProductsFilter/SchoolProductsFilter';

export default class CustomWidget extends React.Component {
  state = {
    showFilters: false,
  };

  toggleShowFilters = () => {
    this.setState({ showFilters: !this.state.showFilters });
  };

  renderWidget = () => {
    const { isMobile, widget } = this.props;
    const { showFilters } = this.state;

    let widgetComponent;

    switch (widget.widgetName) {
      case 'newsFilter':
        widgetComponent = <NewsFilter isMobile={isMobile} />;
        break;
      case 'categoriesFilter':
        widgetComponent = <CategoriesFilter isMobile={isMobile} />;
        break;
      case 'schoolProductsFilter':
        widgetComponent = <SchoolProductsFilter isMobile={isMobile} />;
        break;
      default:
        widgetComponent = null;
    }

    if (!isMobile) return widgetComponent;
    if (showFilters) return widgetComponent;
    return null;
  };

  render() {
    let widgetComponent;
    const { isMobile, widget } = this.props;

    return (
      <Panel
        backgroundColor={widget.color}
        title={widget.title}
        isMobile={isMobile}
        showFilters={this.state.showFilters}
        toggleShowFilters={this.toggleShowFilters}
      >
        {this.renderWidget(widgetComponent)}
      </Panel>
    );
  }
}

CustomWidget.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  widget: PropTypes.object.isRequired,
};
