import React, { useContext } from 'react';
import Badge from 'material-ui/Badge';
import { RaisedButton } from 'material-ui';
import ActionAddShoppingCartIcon from 'material-ui/svg-icons/action/add-shopping-cart';
import { browserHistory } from 'react-router';
import './index.scss';
import { AppContext } from '../../common/AppContext';
import '../../main.scss';

const NavbarCart = () => {
  const { cart } = useContext(AppContext);
  return (
    <RaisedButton
      style={{ marginLeft: '2rem' }}
      label={'Koszyk'}
      backgroundColor="#E5CB20"
      labelColor="#fff"
      onClick={() => browserHistory.push('/koszyk')}
      icon={
        <ActionAddShoppingCartIcon style={{ transform: 'translateY(-2px)' }} />
      }
    >
      {cart && !!cart.length && (
        <Badge
          className="navbar-basket__badge"
          badgeContent={cart.length}
          secondary
        />
      )}
    </RaisedButton>
  );
};

export default NavbarCart;
