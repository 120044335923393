import React from 'react';
import PropTypes from 'prop-types';
import {
  List,
  ListItem,
  TextField,
  RaisedButton,
  IconButton,
} from 'material-ui';
import AccountCircle from 'material-ui/svg-icons/action/account-circle';
import AddCircle from 'material-ui/svg-icons/content/add-circle';
import Delete from 'material-ui/svg-icons/action/delete';

class OpenTrainingParticipants extends React.Component {
  state = {
    isAddParticipantOpen: false,
    newParticipantName: '',
    newParticipantPhone: '',
  };

  addParticipant = () => {
    this.props.changeParticipants([
      ...this.props.participants,
      {
        name: this.state.newParticipantName,
        phone: this.state.newParticipantPhone,
      },
    ]);
    this.setState({
      newParticipantName: '',
      newParticipantPhone: '',
      isAddParticipantOpen: false,
    });
  };

  openAddParticipant = () => {
    this.setState({
      isAddParticipantOpen: true,
    });
  };

  setNewParticipantName = (event) => {
    this.setState({
      newParticipantName: event.target.value,
    });
  };

  setNewParticipantPhone = (event) => {
    this.setState({
      newParticipantPhone: event.target.value,
    });
  };

  removeParticipant = (index) => {
    const currentParticipants = [...this.props.participants];
    currentParticipants.splice(index, 1);
    this.props.changeParticipants(currentParticipants);
  };

  render() {
    return (
      <List>
        {this.props.participants.map((participant, index) => (
          <ListItem
            key={`${participant[0]}${index}`}
            disabled={this.props.displayOnly}
            primaryText={`${participant.name} - ${participant.phone}`}
            leftIcon={<AccountCircle />}
            rightIconButton={
              this.props.displayOnly ? null : (
                <IconButton onClick={() => this.removeParticipant(index)}>
                  <Delete />
                </IconButton>
              )
            }
          />
        ))}

        {this.state.isAddParticipantOpen ? (
          <ListItem disabled>
            <TextField
              fullWidth
              onChange={this.setNewParticipantName}
              value={this.state.newParticipantName}
              floatingLabelText="Imię i nazwisko uczestnika"
            />
            <TextField
              fullWidth
              onChange={this.setNewParticipantPhone}
              value={this.state.newParticipantPhone}
              floatingLabelText="Telefon kontaktowy"
            />
            <RaisedButton label="Dodaj" onClick={this.addParticipant} />
          </ListItem>
        ) : !this.props.displayOnly ? (
          <ListItem
            onClick={this.openAddParticipant}
            primaryText="Dodaj uczestnika"
            leftIcon={<AddCircle />}
          />
        ) : null}
      </List>
    );
  }
}

export default OpenTrainingParticipants;

OpenTrainingParticipants.propTypes = {
  changeParticipants: PropTypes.func.isRequired,
  participants: PropTypes.array.isRequired,
  displayOnly: PropTypes.bool,
};
