import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ListItem, Divider, Avatar } from 'material-ui';
import './index.scss';
import { browserHistory } from 'react-router';
import FolderOpen from 'material-ui/svg-icons/file/folder-open';
import moment from 'moment';

const LegalAct = ({ title, slug, date }) => (
  <Fragment>
    <ListItem onClick={() => browserHistory.push(`/akty-prawne/akt/${slug}`)}>
      <div className="row">
        <div className="col-lg-2 legalact-iconwrapper">
          <Avatar icon={<FolderOpen />} style={{ padding: '15px 0' }} />
          <div className="legalact-date">
            {date ? moment(date).format('DD-MM-YYYY') : null}
          </div>
        </div>
        <div className="col-lg-10">{title}</div>
      </div>
    </ListItem>
    <Divider />
  </Fragment>
);

LegalAct.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  imageSrc: PropTypes.string,
  date: PropTypes.string,
  slug: PropTypes.string,
};

LegalAct.defaultProps = {
  imageSrc: '',
  date: null,
  slug: '',
};

export default LegalAct;
