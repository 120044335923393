import React from 'react';
import {
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
  TextField,
} from 'material-ui';
import './index.scss';
import PropTypes from 'prop-types';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import { Loading } from '../../../utils/loading';
import { getFixedPrice } from '../../../utils/utils';
import { AppContext } from '../../../common/AppContext';

class BasketSingleSchoolProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = { quantity: 0 };
    this.index = this.props.itemIndex;
  }

  static contextType = AppContext;

  changeQuantity = (event) => {
    const item = { ...this.props.item };
    let onlyDigitsValue = Number(event.target.value.replace(/\D/g, ''));
    let { quantity } = item;
    if (onlyDigitsValue < 1000 && onlyDigitsValue !== quantity) {
      if (onlyDigitsValue === 0) onlyDigitsValue = '';
      quantity = onlyDigitsValue;
      this.updateQuantity(quantity);
    }
  };

  quantityOnBlur = (event) => {
    if (!event.target.value) this.updateQuantity(1);
  };

  updateQuantity = (quantity) => {
    const { id } = this.props.item;
    const currentCart = [...this.context.cart];
    const index = currentCart.findIndex((product) => product.id === id);
    currentCart[index].quantity = quantity;
    this.context.setCart(currentCart);
  };

  deleteFromCart = (id) => {
    const currentCart = [...this.context.cart];
    const index = currentCart.findIndex((product) => product.id === id);
    currentCart.splice(index, 1);
    this.context.setCart(currentCart);
  };

  render() {
    const { item, lastStep } = this.props;
    let { price } = item;
    if (item.size && item.size.priceIncrease) price += item.size.priceIncrease;
    if (item.color && item.color.priceIncrease)
      price += item.color.priceIncrease;
    let total = price * item.quantity;
    total = getFixedPrice(total);

    if (this.props.loading) {
      return (
        <TableRow>
          <TableHeaderColumn colSpan={5}>
            <Loading />
          </TableHeaderColumn>
        </TableRow>
      );
    }
    return (
      <TableRow key={`${item.title}-desktop-row`}>
        <TableRowColumn className="basket-products-list__cell">
          <div className="bssp-imagewrapper">
            <img className="bssp-productimage" src={item.imageSrc} />
          </div>
        </TableRowColumn>
        <TableRowColumn className="basket-products-list__cell name-cell">
          {item.title}
          {!!item.color && (
            <span className="product-details">kolor: {item.color.text}</span>
          )}
          {!!item.size && (
            <span className="product-details">rozmiar: {item.size.size}</span>
          )}
        </TableRowColumn>
        <TableRowColumn className="basket-products-list__cell price-cell">
          <React.Fragment>
            <TextField
              disabled={lastStep}
              value={item.quantity}
              style={{ maxWidth: '2rem' }}
              onChange={this.changeQuantity}
              onBlur={this.quantityOnBlur}
              aria-label="quantity"
              name="quantity"
            />
            {'szt.'}
          </React.Fragment>
        </TableRowColumn>
        <TableRowColumn className="basket-products-list__cell price-cell">
          {getFixedPrice(price)} zł
        </TableRowColumn>
        <TableRowColumn className="basket-products-list__cell price-cell">
          {total} zł
        </TableRowColumn>
        {!lastStep && (
          <TableRowColumn className="basket-products-list__cell price-cell">
            <DeleteIcon onClick={() => this.deleteFromCart(item._id)} />
          </TableRowColumn>
        )}
      </TableRow>
    );
  }
}

export default BasketSingleSchoolProduct;

BasketSingleSchoolProduct.propTypes = {
  itemIndex: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  lastStep: PropTypes.bool,
};

BasketSingleSchoolProduct.defaultProps = {
  lastStep: false,
  pack: {},
};
