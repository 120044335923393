import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Account from 'material-ui/svg-icons/action/account-box';
import moment from 'moment';
import './Recommentation.scss';

const CHANGE_TIME = 5000;

export default function Recommendation({
  isHomeScreen,
  recommendation,
  startChangeInterval,
  clearChangeInterval,
}) {
  const [fade, setFade] = useState(true);
  const [fadeInInterval, setFadeInInterval] = useState(null);
  const [fadeOutInterval, setFadeOutInterval] = useState(null);

  const startFadeInInterval = () => {
    setFadeInInterval(
      setTimeout(() => {
        setFade(true);
      }, CHANGE_TIME + 100),
    );
  };

  const startFadeOutInterval = () => {
    setFadeOutInterval(
      setTimeout(() => {
        setFade(false);
      }, CHANGE_TIME - 400),
    );
  };

  const startAllIntervals = () => {
    startFadeInInterval();
    startFadeOutInterval();
    startChangeInterval(CHANGE_TIME);
  };

  const clearAllIntervals = () => {
    clearTimeout(fadeInInterval);
    clearTimeout(fadeOutInterval);
    clearChangeInterval();
  };

  useEffect(
    // eslint-disable-next-line consistent-return
    () => {
      if (isHomeScreen) {
        startAllIntervals();
      }
      return () => {
        clearAllIntervals();
      };
    },
    [recommendation],
  );

  const onMouseEnter = () => {
    if (isHomeScreen) {
      clearAllIntervals();
    }
  };

  const onMouseLeave = () => {
    if (isHomeScreen) {
      startAllIntervals();
    }
  };

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={`recommendation-wrapper ${
        (fade || !isHomeScreen) && 'recommendation-visible'
      }`}
    >
      <div className="recommendation-imagewrapper">
        <div
          className="recommendation-image"
          style={{
            backgroundImage: `url("${
              recommendation.imageSrc ? recommendation.imageSrc : '/person.png'
            }")`,
          }}
        >
          {recommendation.imageSrc ? null : (
            <Account
              style={{
                width: '210px',
                height: '160px',
                color: '#DDDDDD',
              }}
            />
          )}
        </div>
      </div>
      <div className="recommendation-text" style={{ flex: 1 }}>
        <div className="person">{recommendation.person}</div>
        <div className="place">{recommendation.place}</div>
        <div className="date">
          {moment(recommendation.date).format('DD-MM-YYYY')}
        </div>
        <div dangerouslySetInnerHTML={{ __html: recommendation.text }} />
      </div>
    </div>
  );
}

Recommendation.defaultProps = {
  isHomeScreen: false,
  isHovered: false,
  startChangeInterval: () => {},
  clearChangeInterval: () => {},
};

Recommendation.propTypes = {
  isHomeScreen: PropTypes.bool,
  recommendation: PropTypes.shape({
    _id: PropTypes.string,
    createdOn: PropTypes.shape({
      date: PropTypes.object,
      unix: PropTypes.number,
    }),
    date: PropTypes.string,
    imageSrc: PropTypes.string,
    person: PropTypes.string,
    place: PropTypes.string,
    sort: PropTypes.number,
    text: PropTypes.string,
  }).isRequired,
  isHovered: PropTypes.bool,
  startChangeInterval: PropTypes.func,
  clearChangeInterval: PropTypes.func,
};
