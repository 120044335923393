import React from "react";
import {
  Card,
  CardTitle,
  CardText,
  RaisedButton,
  CardActions,
} from "material-ui";
import moment from "moment/moment";
import { browserHistory } from "react-router";
import { useQuery } from "@apollo/react-hooks";
import { Loading } from "../../utils/loading";
import withGraphqlHook from "../../common/withGraphqlHook";
import getOpenTrainingWithDateQuery from "../../graphql/queries/getOpenTrainingWithDate.graphql";

class SingleOpenTrainingDate extends React.Component {
  downloadRegistrationForm = () => {
    window.open(this.props.openTrainingDate.registrationFileUrl);
  };

  render() {
    const { loading, openTraining, openTrainingDate } = this.props;
    const pageTitle = `${openTraining.title} - ${
      openTrainingDate.city
    } - ${moment(openTrainingDate.trainingDate, "x").format("DD-MM-YYYY")}`;
    return loading ? (
      <Loading />
    ) : (
      <div style={{ marginTop: 20 }}>
        <Card>
          <CardTitle title={pageTitle} />
          <CardText>
            <div dangerouslySetInnerHTML={{ __html: openTraining.text }} />
            <CardActions>
              {this.props.openTrainingDate.isActive ? (
                <React.Fragment>
                  <RaisedButton
                    primary
                    label="Zapisy online"
                    onClick={() => {
                      browserHistory.push(
                        `/zapisy-online/${this.props.openTraining.slug}/${
                          openTrainingDate._id
                        }`,
                      );
                    }}
                  />{" "}
                  <RaisedButton
                    primary
                    label="Pobierz formularz złoszeniowy"
                    onClick={this.downloadRegistrationForm}
                  />
                </React.Fragment>
              ) : (
                <div>
                  <strong>Zapisy na szkolenie zostały zamknięte</strong>
                </div>
              )}
            </CardActions>
          </CardText>
        </Card>
      </div>
    );
  }
}

export default withGraphqlHook(props => {
  const { slug } = props.params;
  let openTraining = {};
  let openTrainingDate = [];

  const { loading, data: openTrainingData } = useQuery(
    getOpenTrainingWithDateQuery,
    {
      variables: {
        openTrainingSlug: slug,
        openTrainingDateId: props.params.dateId,
      },
    },
  );

  if (!loading) {
    openTraining = openTrainingData.getOpenTrainingWithDate;
    // eslint-disable-next-line prefer-destructuring
    openTrainingDate = openTrainingData.getOpenTrainingWithDate.dates[0];
  }

  return {
    loading,
    openTraining,
    openTrainingDate,
  };
}, SingleOpenTrainingDate);

SingleOpenTrainingDate.propTypes = {};
