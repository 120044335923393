import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
} from 'material-ui/Table';
import BasketSingleTraining from '../BasketSingleTraining/BasketSingleTraining';

export const CartLessons = (props) => (
  <React.Fragment>
    E-lekcje
    <Table selectable={false}>
      <TableHeader adjustForCheckbox={false} displaySelectAll={false}>
        <TableRow>
          <TableHeaderColumn
            style={{ borderRight: 0 }}
            className="basket-products-list__cell name-cell"
          >
            Produkt
          </TableHeaderColumn>
          <TableHeaderColumn className="basket-products-list__cell price-cell">
            Netto
          </TableHeaderColumn>
          <TableHeaderColumn className="basket-products-list__cell price-cell">
            Vat
          </TableHeaderColumn>
          <TableHeaderColumn className="basket-products-list__cell price-cell">
            Brutto
          </TableHeaderColumn>
          {!props.lastStep && (
            <TableHeaderColumn className="basket-products-list__cell price-cell">
              Usuń
            </TableHeaderColumn>
          )}
        </TableRow>
      </TableHeader>
      <TableBody displayRowCheckbox={false}>
        {props.items.map((item, index) => (
          <BasketSingleTraining
            key={item._id + String(index)}
            item={item}
            lastStep={props.lastStep}
            itemIndex={index}
            products={props.products}
          />
        ))}
      </TableBody>
    </Table>
  </React.Fragment>
);

CartLessons.propTypes = {
  items: PropTypes.array.isRequired,
  lastStep: PropTypes.bool.isRequired,
  products: PropTypes.array.isRequired,
};
