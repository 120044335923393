import React from 'react';
import PropTypes from 'prop-types';
import FolderOpen from 'material-ui/svg-icons/file/folder-open';
import { browserHistory } from 'react-router';
import moment from 'moment';
import Panel from '../common/Panel';
import CustomWidget from './CustomWidget';
import './Widgets.scss';

const getRoute = (collection) => {
  switch (collection) {
    case 'LegalActs':
      return 'akty-prawne';
    case 'News':
      return 'news';
    default:
      return '404';
  }
};

class Widgets extends React.Component {
  state = {
    windowWidth: window.innerWidth,
  };

  componentDidMount() {
    window.addEventListener('resize', this.setNewWindowSize);
  }

  // eslint-disable-next-line class-methods-use-this
  componentWillUnmount() {
    window.onresize = null;
  }

  setNewWindowSize = () => {
    this.setState({
      windowWidth: window.innerWidth,
    });
  };

  render() {
    const isMobile = this.state.windowWidth < 990;
    const { loading, widgets, widgetId } = this.props;
    if (loading) {
      return null;
    }

    const widget = widgets.find(({ _id }) => _id === widgetId);
    if (!isMobile) {
      return widget.collection ? (
        <Panel title={widget.title} backgroundColor={widget.color}>
          <div className="widgets-wrapper">
            {widget.data.map((item) => {
              const date = item.date
                ? `${moment(item.date, 'x').format('DD-MM-YYYY')}: `
                : '';
              const title =
                widget.collection === 'LegalActs'
                  ? `${date}${item.title}`
                  : item.title;
              return (
                <a
                  onClick={() =>
                    browserHistory.push(
                      `/${getRoute(widget.collection)}/${item.slug}`,
                    )
                  }
                  href={`/${getRoute(widget.collection)}/${item.slug}`}
                  key={item.slug}
                >
                  <div className="widgets-widgetitem" key={item._id}>
                    <div className="widgets-iconcontainer">
                      <FolderOpen
                        style={{
                          width: '40px',
                          height: '40px',
                          color: widget.color,
                        }}
                      />
                    </div>
                    <div className="widgets-textcontainer">{title}</div>
                  </div>
                </a>
              );
            })}
          </div>
        </Panel>
      ) : (
        <CustomWidget widget={widget} isMobile={isMobile} />
      );
    }

    return null;
  }
}

export default Widgets;

Widgets.propTypes = {
  data: PropTypes.array,
};

Widgets.defaultProps = {
  data: [],
};
