import moment from "moment";
import flatten from "lodash/flatten";
import isArray from "lodash/isArray";

const getOrderStatus = status => {
  switch (status) {
    case "PENDING":
      return "oczekuje";
    case "CANCELLED":
      return "anulowane";
    case "COMPLETE":
      return "zrealizowane";
    case "CONFIRMED":
      return "potwierdzone";
    case "ARCHIVED":
      return "archiwalne";
    default:
      return "bląd";
  }
};

const getProductType = type => {
  switch (type) {
    case "document":
      return "dokument";
    case "schoolProduct":
      return "produkt szkolny";
    case "training":
      return "szkolenie";
    default:
      return "bląd";
  }
};

const getOrdersDataToExport = data =>
  data.map(entry => {
    const currentEntry = { ...entry };
    const user = Meteor.users.findOne(entry.userId);
    currentEntry.user = user.username;
    currentEntry.userName = user.profile.name;
    currentEntry.userObject = user;
    currentEntry.orderDate = moment(entry.created.date).format(
      "DD-MM-YYYY HH:mm",
    );
    if (entry.status) {
      currentEntry.status = getOrderStatus(entry.status);
    }
    currentEntry.orderValue = entry.items.reduce(
      (prev, { price, quantity = 1 }) => prev + price * quantity,
      0,
    );
    return currentEntry;
  });

const generateUserDocumentsData = (data, products) =>
  flatten(
    data.map(order => {
      const orderedProduct = products.find(
        productFromDb => productFromDb._id === order.productId,
      );
      if (orderedProduct) {
        const { profile } = order.userObject;
        return {
          Tytul_produktu: orderedProduct.title,
          Adres_email: order.userObject.emails[0].address,
          Nabywca: profile.contactPersonName,
          Adres_nabywcy: profile.city,
          NIP_nabywcy: profile.nip,
          Data_zamowienia: order.orderDate,
          Data_wyswietlenia: order.displayDate
            ? moment(order.displayDate).format("DD-MM-YYYY")
            : "-",
        };
      }
      return null;
    }),
  );

const createOrdersFromProducts = (item, orderInfo, products, type) => {
  if (item.type && item.type !== "pack" && item.type === type) {
    return {
      ...orderInfo,
      Tytul: item.title,
      Typ_zamowienia: getProductType(item.type),
      Cena: item.priceGross,
      Netto: item.price,
      Vat: item.vat,
      Stawka_vat: item.vatRate,
      Data_wyswietlenia: item.displayDate
        ? moment(item.displayDate).format("DD-MM-YYYY")
        : "-",
      Liczba_sztuk: item.type === "schoolProduct" ? item.quantity : "-",
      Kolor:
        item.type === "schoolProduct" && item.color ? item.color.text : "-",
    };
  }
  if (item.type === "pack") {
    return item.content.map(productId => {
      const productById = products.find(product => product._id === productId);
      if (productById) {
        return createOrdersFromProducts(productById, orderInfo, products, type);
      }
      return null;
    });
  }
  return null;
};

const generateOrdersData = (data, products, type) =>
  flatten(
    data.map(order => {
      const { profile } = order.userObject;
      const orderInfo = {
        Adres_email: order.userObject.emails[0].address,
        Nabywca: profile.contactPersonName,
        Adres_nabywcy: profile.city,
        NIP_nabywcy: profile.nip,
        Login: order.userName,
        Numer_zamowienia: order.orderNo,
        Data_zamowienia: order.orderDate,
        Status: order.status,
        Faktura: order.paperInvoice ? "Tak" : "Nie",
        Uwagi_do_zamowienia: order.orderNotes,
      };

      const ordersArr = [];
      order.items.forEach(item => {
        const row = createOrdersFromProducts(item, orderInfo, products, type);

        if (row || (isArray(row) && row.length)) {
          ordersArr.push(row);
        }
      });

      return flatten(ordersArr);
    }),
  );

export {
  getProductType,
  getOrderStatus,
  getOrdersDataToExport,
  generateOrdersData,
  generateUserDocumentsData,
};
