import React from 'react';
import { FlatButton, RaisedButton } from 'material-ui';
import PropTypes from 'prop-types';
import { Loading } from '../../../utils/loading';

const StepActions = ({
  isUserBeingEdited,
  setIsUserBeingEdited,
  handleNext,
  handlePrev,
  registerForTraining,
  stepIndex,
  inProgress,
}) =>
  !isUserBeingEdited && (
    <div className="l-basket__actions">
      <div style={{ marginTop: 12 }}>
        <FlatButton
          label="Wstecz"
          disabled={stepIndex === 0}
          onClick={handlePrev}
          style={{ marginRight: 12 }}
        />
        {stepIndex === 1 && (
          <RaisedButton
            label="Edytuj"
            primary
            onClick={() => setIsUserBeingEdited(true)}
            style={{ marginRight: 12 }}
          />
        )}
        {inProgress ? (
          <Loading />
        ) : (
          <RaisedButton
            label={stepIndex === 3 ? 'Wyślij' : 'Dalej'}
            primary
            onClick={stepIndex === 3 ? registerForTraining : handleNext}
          />
        )}
      </div>
    </div>
  );

StepActions.propTypes = {
  stepIndex: PropTypes.number.isRequired,
};

export default StepActions;
