import React, { useContext } from 'react';
import { Snackbar } from 'material-ui';
import { AppContext } from '../../common/AppContext';

function Notifier() {
  const { currentMessage, setMessage } = useContext(AppContext);
  const handleRequestClose = () => {
    setMessage('');
  };

  return (
    <Snackbar
      open={!!currentMessage}
      message={currentMessage}
      autoHideDuration={4000}
      onRequestClose={handleRequestClose}
    />
  );
}

export default Notifier;
