import React from 'react';
import { Card, CardText, CardTitle } from 'material-ui';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import { Loading } from '../../utils/loading';
import getPageSettingsQuery from '../../graphql/queries/getPageSettings.graphql';
import ContactForm from './ContactForm';

const Contact = () => {
  const { data, loading } = useQuery(getPageSettingsQuery, {
    variables: { type: 'pageContent', page: 'contact' },
  });
  const pageContent = get(data, 'getPageSettings.settings', null);
  if (loading) {
    return <Loading />;
  }
  return (
    <div style={{ marginTop: 20 }}>
      <div className="row row--flex">
        <div className="col-lg-12">
          {pageContent ? (
            <Card>
              <CardTitle title={pageContent.title} />
              <CardText
                dangerouslySetInnerHTML={{
                  __html: pageContent.content,
                }}
              />
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9573.269376661383!2d23.101242!3d53.140399!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ffd46680d4bdf%3A0x71ed811177e16700!2sECRK%20Lex%20s.c%20El%C5%BCbieta%20Linowska%20Grzegorz%20Linowski!5e0!3m2!1spl!2spl!4v1721900922986!5m2!1spl!2spl"
                height="250px"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
              <br />
              <ContactForm />
            </Card>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Contact;
