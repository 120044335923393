import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import { Card, CardText, CardTitle } from 'material-ui';
import useMetaKeyword from '../../hooks/useMetaKeyword';
import { generateSubpageTitle } from '../../utils/generatePageTitle';
import getGoodToKnowBySlugQuery from '../../graphql/queries/getGoodToKnowBySlug.graphql';
import { Loading } from '../../utils/loading';

const GoodToKnowDetails = ({ params }) => {
  const { slug } = params;
  const { data, loading } = useQuery(getGoodToKnowBySlugQuery, {
    variables: { slug },
  });
  const news = get(data, 'getGoodToKnowBySlug', null);

  useMetaKeyword(news);

  useEffect(() => {
    if (news) {
      generateSubpageTitle(news.title);
    }
  }, []);

  if (loading) return <Loading />;

  const imageSrc = news && news.imageSrc;
  const title = news && news.title;
  const text = news && news.text;

  return (
    <div style={{ marginTop: 20 }}>
      <Card>
        <br />
        <br />
        <div className="legalact-title row">
          <div className="col-lg-7 col-md-12">
            <CardTitle title={title} className="title-text" />
          </div>
          <div className="col-lg-5 hidden-md hidden-xs">
            <img src={imageSrc} className="product-image" alt="image" />
          </div>
        </div>
        <CardText dangerouslySetInnerHTML={{ __html: text }} />
      </Card>
    </div>
  );
};

GoodToKnowDetails.propTypes = {
  params: PropTypes.shape({
    slug: PropTypes.string,
  }).isRequired,
};

export default GoodToKnowDetails;
