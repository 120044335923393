const productSlugToType = (slug) => {
  if (slug.includes('/produkty-szkolne')) {
    return 'schoolProduct';
  }
  if (slug.includes('/dokumenty')) {
    return 'document';
  }
  if (slug.includes('/statuty')) {
    return 'statute';
  }
  switch (slug) {
    case 'e-szkolenie':
    case 'e-szkolenia':
    case 'e-szkolenia-dla-nauczycieli':
    case '/produkty/e-szkolenia':
      return 'training';
    case 'dokumenty':
    case 'dokumenty-szkolne-wzory':
    case '/produkty/dokumenty':
      return 'document';
    case '/produkty/statuty':
      return 'statute';
    case 'pakiety':
    case '/produkty/pakiety':
      return 'pack';
    case '/szkolenia-otwarte':
    case '/szkolenia-otwarte-dla-nauczycieli':
    case '/produkty/szkolenia-otwarte':
    case '/panel/zapisy-na-szkolenia-otwarte':
      return 'openTraining';
    case '/szkolenia-zamkniete':
    case '/szkolenia-zamkniete-dla-nauczycieli':
      return 'closedTrainings';
    case 'e-lekcje':
    case 'e-lekcja':
    case '/produkty/e-lekcje':
      return 'lesson';
    default:
      return 'training';
  }
};

const productTypeToSlug = (slug) => {
  switch (slug) {
    case 'training':
      return 'e-szkolenia';
    case 'document':
      return 'dokumenty';
    case 'statute':
      return 'statuty';
    default:
      return 'training';
  }
};

const emptyTrainingProduct = {
  _id: null,
  title: '',
  text: '',
  imageSrc: '',
  price: 0,
  vatRate: 'vat23',
  categories: [],
  short: '',
  video: '',
  tags: [],
  days: 1,
};

const emptyDocumentProduct = {
  _id: null,
  title: '',
  text: '',
  imageSrc: '',
  price: 0,
  vatRate: 'vat23',
  categories: [],
  short: '',
  tags: [],
  days: 1,
};

const emptyPackProduct = {
  _id: null,
  type: 'pack',
  title: '',
  price: 0,
  vatRate: 'vat23',
  contains: 0,
};

const calculateGrossPrice = ({ product, vatRates }) => {
  const vatRate = vatRates.find((vat) => vat && vat._id === product.vatRate);
  const rate = vatRate ? 1 + vatRate.rate : 1;
  return parseInt(parseFloat(product.price) * rate * 100, 10) / 100;
};

const calculateGrossPriceWithNumber = ({ price, vatRate, vatRates }) => {
  const vatRateObj = vatRates.find((vat) => vat && vat._id === vatRate);
  const rate = vatRateObj ? 1 + vatRateObj.rate : 1;
  return parseInt(parseFloat(price) * rate * 100, 10) / 100;
};

const calculateVat = ({ product, vatRates }) =>
  parseInt(
    parseFloat(product.price) *
      vatRates.find((vat) => vat._id === product.vatRate).rate *
      100,
    10,
  ) / 100;

export {
  productSlugToType,
  emptyTrainingProduct,
  emptyDocumentProduct,
  emptyPackProduct,
  calculateGrossPrice,
  calculateVat,
  calculateGrossPriceWithNumber,
  productTypeToSlug,
};
