import React from 'react';
import PropTypes from 'prop-types';
import { CardTitle, CardText } from 'material-ui/Card';
import RaisedButton from 'material-ui/RaisedButton';
import './Product.scss';
import { browserHistory } from 'react-router';

const longTitleStyle = {
  fontSize: '20px',
  lineHeight: '22px',
};

const shortTitleStyle = {
  lineHeight: '30px',
};

const Product = ({ title, short, imageSrc, slug, type, isNew }) => {
  const getRoute = () => {
    let route = `/produkty/${slug}`;
    if (type === 'schoolProduct') {
      route = `/produkty-szkolne/produkt/${slug}`;
    } else if (type === 'onlineTraining') {
      route = `/produkty/szkolenia-online/${slug}`;
    }
    return route;
  };

  const goToProduct = () => {
    browserHistory.push(getRoute());
  };

  const setImageContainerHeight = (event) => {
    const imgElement = event.currentTarget;
    const setImageContainerHeightFunc = () => {
      const width = imgElement.clientWidth;
      const height = (width * 9) / 16; // Calculate height based on 16:9 aspect ratio
      imgElement.closest('.product__image').style.height = `${height}px`;
    };
    setImageContainerHeightFunc();

    const oldOnResize = window.onresize;
    window.onresize = () => {
      if (oldOnResize) {
        oldOnResize();
      }
      setImageContainerHeightFunc();
    };
  };

  const titleText = isNew ? `NOWOŚĆ: ${title}` : title;

  return (
    <div className="product">
      {imageSrc && (
        <a href={getRoute()} className="product__image">
          <img
            src={imageSrc}
            alt={titleText}
            onLoad={setImageContainerHeight}
          />
        </a>
      )}
      <CardTitle
        title={titleText}
        titleStyle={title.length > 50 ? longTitleStyle : shortTitleStyle}
      />
      <CardText className="shortText">
        {short}
        <div className="product__button-wrapper">
          <RaisedButton
            primary
            label="Zobacz"
            onClick={(event) => {
              event.preventDefault();
              goToProduct();
            }}
            href={getRoute()}
          />
        </div>
      </CardText>
    </div>
  );
};

Product.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  short: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isNew: PropTypes.bool,
};

Product.defaultProps = {
  isNew: false,
};

export default Product;
