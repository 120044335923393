import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import FolderOpen from 'material-ui/svg-icons/file/folder-open';
import { RaisedButton } from 'material-ui';
import moment from 'moment';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import Panel from '../common/Panel';
import './HomeLegalActs.scss';
import withGraphqlHook from '../../common/withGraphqlHook';
import getLatestLegalActsQuery from '../../graphql/queries/getLatestLegalActs.graphql';

function HomeLegalActs(props) {
  const { ready, legalActs } = props;
  return (
    <Panel backgroundColor="#E5CB20" title="Obowiązujące akty prawne">
      <div className="hla-wrapper">
        {ready ? (
          legalActs.map((legalAct) => {
            const date = legalAct.date
              ? `${moment(legalAct.date).format('DD-MM-YYYY')}: `
              : '';
            return (
              <a
                onClick={() =>
                  browserHistory.push(`/akty-prawne/akt/${legalAct.slug}`)
                }
                href={`/akty-prawne/akt/{legalAct.slug}`}
                key={legalAct.slug}
              >
                <div className="hla-legalact">
                  <div className="hla-iconcontainer">
                    <FolderOpen
                      style={{
                        width: '40px',
                        height: '40px',
                        color: '#E5CB20',
                      }}
                    />
                  </div>
                  <div className="hla-textcontainer">
                    {date}
                    {legalAct.title}
                  </div>
                </div>
              </a>
            );
          })
        ) : (
          <div className="hla-legalact" />
        )}
        <div className="hla-seemore">
          <RaisedButton
            label="Zobacz wszystkie"
            onClick={() => browserHistory.push('/akty-prawne')}
            backgroundColor="#E5CB20"
            labelColor="#fff"
          />
        </div>
      </div>
    </Panel>
  );
}

export default withGraphqlHook(() => {
  const { loading, data } = useQuery(getLatestLegalActsQuery);
  const legalActs = get(data, 'getLatestLegalActs', []);

  return { ready: !loading, legalActs };
}, HomeLegalActs);

HomeLegalActs.propTypes = {
  ready: PropTypes.bool.isRequired,
  legalActs: PropTypes.array.isRequired,
};
