import { useEffect } from 'react';

export default function useMetaKeyword(propsToCheck) {
  useEffect(() => {
    if (propsToCheck && propsToCheck.tags) {
      document.getElementsByTagName('meta').keywords.content =
        propsToCheck.tags.join(' ');
    }
  }, [propsToCheck]);
}
