import React, { Fragment } from 'react';
import { Card, CardHeader, CardText } from 'material-ui';
import moment from 'moment';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import { Loading } from '../../utils/loading';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import FileList from '../fileList/fileList';
import LimitedFileList from '../fileList/LimitedFileList';
import InfoPanel from '../../utils/InfoPanel/InfoPanel';
import UserDocumentDatePicker from '../UserDocumentDatePicker/UserDocumentDatePicker';
import './index.scss';
import withGraphqlHook from '../../common/withGraphqlHook';
import getUserDocumentQuery from '../../graphql/queries/getUserDocument.graphql';
import VimeoPlayer from '../common/VimeoPlayer';
import TrainingShare from '../common/TrainingShare';

class UserSingleDocumentPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  canDisplayVideo = () => {
    const { product, document } = this.props;
    const displayDate = document && document.displayDate;
    const days = product && product.days;

    if (displayDate && days && product.type === 'training') {
      return moment().isBetween(
        moment(displayDate).subtract(days, 'day'),
        moment(displayDate).add(days + 1, 'day'),
        '[]',
      );
    }
    if (displayDate && days && product.type === 'lesson') {
      return moment().isBetween(
        moment(displayDate),
        moment(displayDate).add(days + 1, 'day'),
        '[]',
      );
    }
    return false;
  };

  canChangeVideoDisplayDate = () => {
    const { product, document } = this.props;
    const displayDate = document && document.displayDate;
    const days = product && product.days;
    if (displayDate && days && product.type === 'training') {
      return moment().isBefore(moment(displayDate).subtract(days, 'day'));
    }
    if (displayDate && days && product.type === 'lesson') {
      return moment().isBefore(moment(displayDate));
    }
    if (isNull(displayDate)) {
      return true;
    }
    return false;
  };

  render() {
    if (this.props.loading) {
      return <Loading />;
    }
    const { product } = this.props;

    if (!product) {
      return 'Błąd w wyszukiwaniu produktu! Prosimy o kontakt!';
    }

    if (product.type === 'training' || product.type === 'lesson') {
      if (this.canChangeVideoDisplayDate()) {
        return (
          <UserDocumentDatePicker
            product={product}
            document={this.props.document}
            refetch={this.props.refetch}
          />
        );
      }
      if (this.canDisplayVideo()) {
        return (
          <Card expanded={this.state.expanded}>
            <CardHeader title={product.title} subtitle={product.short} />
            <CardText>
              {product.isNewPlayer ? (
                <Fragment>
                  <VimeoPlayer id={product.vimeoId} />
                  {(product.allowSharing || product.type === 'lesson') && (
                    <TrainingShare
                      userDocumentId={this.props.params.documentId}
                      shareKey={this.props.document.shareKey}
                      shareDate={this.props.document.shareDate}
                      refetch={this.props.refetch}
                    />
                  )}
                </Fragment>
              ) : (
                <VideoPlayer
                  video={product.video}
                  userDocumentId={this.props.params.documentId}
                />
              )}
            </CardText>
            <FileList
              productId={product._id}
              userDocumentId={this.props.params.documentId}
              display
            />
          </Card>
        );
      }

      return (
        <InfoPanel role="info" fade={false}>
          Termin szkolenia minął
        </InfoPanel>
      );
    }
    return (
      <Card expanded={this.state.expanded}>
        <CardHeader title={product.title} subtitle={product.short} />

        <CardText />
        <LimitedFileList
          productId={product._id}
          userDocumentId={this.props.document._id}
        />
      </Card>
    );
  }
}

export default withGraphqlHook((props) => {
  const { data, loading, refetch } = useQuery(getUserDocumentQuery, {
    variables: { id: props.params.documentId },
  });
  let product = null;
  const document = get(data, 'getUserDocument', null);
  if (!loading && document) {
    product = document.product;
  }

  return { loading, document, product, refetch };
}, UserSingleDocumentPage);

UserSingleDocumentPage.propTypes = {
  refetch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  product: PropTypes.shape({
    _id: PropTypes.string,
    days: PropTypes.number,
    type: PropTypes.string,
    title: PropTypes.string,
    short: PropTypes.string,
    priceGross: PropTypes.number,
    trailer: PropTypes.string,
    video: PropTypes.string,
    vimeoId: PropTypes.string,
    isNewPlayer: PropTypes.bool,
    allowSharing: PropTypes.bool,
  }).isRequired,
  document: PropTypes.shape({
    _id: PropTypes.string,
    displayDate: PropTypes.string,
    shareKey: PropTypes.string,
    shareDate: PropTypes.object,
  }).isRequired,
  params: PropTypes.shape({
    documentId: PropTypes.string,
  }).isRequired,
};
