import React, { useState, useEffect, useContext } from 'react';
import './productDetails.scss';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import {
  Card,
  CardActions,
  CardText,
  RaisedButton,
  SelectField,
  MenuItem,
} from 'material-ui';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import { Loading } from '../../utils/loading';
import { calculateGrossPrice } from '../../utils/product';
import { generateSubpageTitle } from '../../utils/generatePageTitle';
import useMetaKeyword from '../../hooks/useMetaKeyword';
import { AppContext } from '../../common/AppContext';
import getProductBySlugQuery from '../../graphql/queries/getProductBySlug.graphql';
import getProductsQuery from '../../graphql/queries/getProducts.graphql';
import getVatRatesQuery from '../../graphql/queries/getVatRatesQuery.graphql';

const ProductDetails = (props) => {
  const {
    data: productData,
    loading: productLoading,
    error: productError,
  } = useQuery(getProductBySlugQuery, {
    variables: { slug: props.params.productSlug },
  });

  if (
    productError &&
    productError.message === 'GraphQL error: Product is not available'
  ) {
    browserHistory.push('/');
  }

  const { data: packsData, loading: packsLoading } = useQuery(
    getProductsQuery,
    { variables: { type: 'pack' } },
  );
  const { data: vatData, loading: vatLoading } = useQuery(getVatRatesQuery);

  const loading = productLoading || packsLoading || vatLoading;

  const product = get(productData, 'getProductBySlug', null);
  const fetchedPacks = get(packsData, 'getProducts', []);
  const vatRates = get(vatData, 'getVatRates', []);
  useMetaKeyword(product);
  useEffect(() => {
    if (product) {
      if (product.pageTitle) {
        generateSubpageTitle(product.pageTitle, true);
      } else {
        generateSubpageTitle(product.title);
      }
      if (product.type === 'onlineTraining') {
        const route = `/produkty/szkolenia-online/${props.params.productSlug}`;
        browserHistory.push(route);
      }
    }
  }, [product]);
  const { setMessage, cart, setCart, pack, setPack, packFull } = useContext(
    AppContext,
  );
  const [addingToPack, setAddingToPack] = useState(false);

  if (loading || !product) {
    return <Loading />;
  }

  if (!loading && !product) {
    browserHistory.push('/');
  }

  const packs = fetchedPacks.map((packItem) => ({
    ...packItem,
    grossPrice: calculateGrossPrice({ product: packItem, vatRates }),
  }));
  product.grossPrice = calculateGrossPrice({ product, vatRates });

  const addToCart = (packId) => {
    const cartProduct = {
      id: product._id,
      type: product.type,
    };

    if (packId) {
      const currentPack = [...pack];
      cartProduct.id = packId;
      cartProduct.type = 'pack';
      currentPack.push(product._id);
      setPack(currentPack);
    }

    if (!cart.find((prod) => prod.id === cartProduct.id)) {
      const currentCart = [...cart];
      currentCart.push(cartProduct);
      setCart(currentCart);
      browserHistory.push('/koszyk');
    } else {
      setMessage('Produkt jest już w koszyku');
    }
  };

  const addToPack = () => {
    const currentPack = [...pack];
    if (currentPack && currentPack.includes(product._id)) {
      setMessage('Produkt jest już w pakiecie!');
      return;
    }
    if (currentPack.length && !packFull) {
      currentPack.push(product._id);
      setPack(currentPack);
      browserHistory.push('/koszyk');
    } else if (packFull) {
      setMessage('Pakiet jest już pełen!');
    } else {
      setAddingToPack(true);
    }
  };

  const handleChange = (event, index, val) => {
    addToCart(val);
  };

  const price = product
    ? product.type === 'training'
      ? `${product.price} zł netto`
      : `${product.grossPrice} zł brutto`
    : 0;
  const isTraining = product && product.type === 'training';

  if (product.hide) {
    browserHistory.push('/');
  }
  return (
    <div>
      <Card>
        <br />
        <br />
        <div
          className={`row ${isTraining ? 'product-title' : 'document-title'}`}
        >
          <div className="col-lg-7 col-md-12">
            <h1 className="pagetitle">{product.title}</h1>
            <div className="title-buttons">
              <RaisedButton
                label="Do koszyka"
                onClick={() => addToCart()}
                primary={!isTraining}
              />{' '}
              {isTraining ? (
                <RaisedButton label="Do pakietu" onClick={addToPack} />
              ) : null}
              {addingToPack ? (
                <SelectField
                  hintText="Wybierz pakiet"
                  value={''}
                  onChange={handleChange}
                >
                  {packs.map((packItem) => (
                    <MenuItem
                      value={packItem._id}
                      primaryText={`${packItem.title} - ${packItem.grossPrice}zł`}
                      key={packItem._id}
                    />
                  ))}
                </SelectField>
              ) : null}
            </div>
          </div>
          <div className="col-lg-5 hidden-md hidden-xs">
            <img src={product.imageSrc} className="product-image" />
          </div>
        </div>
        <CardText>
          <div dangerouslySetInnerHTML={{ __html: product.text }} />
          {product.trailer ? (
            <iframe
              width="560"
              height="315"
              src={`https://www.youtube.com/embed/${product.trailer}`}
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
              className="youtube"
            />
          ) : null}
          <div className="price-tag">Cena: {price}</div>
        </CardText>
        <CardActions>
          <RaisedButton
            primary
            label="Do koszyka"
            onClick={() => addToCart()}
          />
          {product.type === 'training' ? (
            <RaisedButton primary label="Do pakietu" onClick={addToPack} />
          ) : null}
          {addingToPack ? (
            <SelectField
              hintText="Wybierz pakiet"
              value={''}
              onChange={handleChange}
            >
              {packs.map((packItem) => (
                <MenuItem
                  value={packItem._id}
                  primaryText={`${pack.title} - ${pack.grossPrice}zł`}
                  key={pack._id}
                />
              ))}
            </SelectField>
          ) : null}
        </CardActions>
      </Card>
    </div>
  );
};

export default ProductDetails;

ProductDetails.propTypes = {
  product: PropTypes.shape({
    _id: PropTypes.string,
    type: PropTypes.string,
    price: PropTypes.number,
    priceGross: PropTypes.number,
    trailer: PropTypes.string,
    text: PropTypes.string,
    imageSrc: PropTypes.string,
    title: PropTypes.string,
    pageTitle: PropTypes.string,
  }).isRequired,
  packs: PropTypes.array.isRequired,
};
