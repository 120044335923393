import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Dialog, List, ListItem } from 'material-ui';
import { browserHistory } from 'react-router';
import './styles.scss';
import sortBy from 'lodash/sortBy';
import { districts } from './districts';

class OpenTrainingsMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      description: null,
      dialogOpen: false,
    };
    const { dates } = props;
    this.districts = districts.map((district) => ({
      ...district,
      dates: dates.filter((date) => date.district === district.name),
    }));
  }

  handleDistrictClick = (district) => {
    this.setState({
      dialogOpen: true,
      selectedDistrict: district,
    });
  };

  hideTooltip = () => {
    this.setState({
      description: null,
    });
  };

  closeDialog = () => {
    this.setState({
      dialogOpen: false,
    });
  };

  showTooltip(district) {
    const sortedDates = sortBy(district.dates, 'trainingDate');
    const description = sortedDates.map((date) => {
      const { _id, city, trainingDate, title } = date;

      return (
        <React.Fragment key={_id}>
          <a onMouseOver={this.handleDistrictClick}>
            {moment(trainingDate, 'x').format('DD-MM-YYYY')} - {title} - {city}
          </a>
          <br />
        </React.Fragment>
      );
    });

    this.setState({
      description,
      descriptionPosTop: district.tooltipPosTop,
      descriptionPosLeft: district.tooltipPosLeft,
    });
  }

  render() {
    const {
      description,
      descriptionPosTop,
      descriptionPosLeft,
      dialogOpen,
    } = this.state;
    const isMobile = window.innerWidth < 800;
    return (
      <div
        className="OpenTrainingsMap"
        ref={(map) => {
          this.mapRef = map;
        }}
      >
        {description ? (
          <div
            className="OpenTrainingsMap__description"
            ref={(desc) => {
              this.descRef = desc;
            }}
            style={{
              top: descriptionPosTop,
              left: descriptionPosLeft,
            }}
          >
            {description && description.length
              ? description
              : 'Brak nadchodzących szkoleń'}
          </div>
        ) : null}
        <svg
          baseProfile="tiny"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="-0.254 -0.933 580 552"
        >
          {this.districts.map((district) => (
            <path
              key={district.path}
              className="OpenTrainingsMap__path"
              strokeWidth="1.713"
              strokeLinecap="round"
              strokeLinejoin="round"
              d={district.path}
              onMouseEnter={() =>
                isMobile ? null : this.showTooltip(district)
              }
              onMouseLeave={this.hideTooltip}
              onClick={() => this.handleDistrictClick(district)}
            />
          ))}
        </svg>
        <Dialog
          title={
            this.state.selectedDistrict
              ? `${this.state.selectedDistrict.name} - dostępne szkolenia`
              : ''
          }
          modal={false}
          open={dialogOpen}
          onRequestClose={this.closeDialog}
        >
          <List>
            {this.state.selectedDistrict
              ? this.state.selectedDistrict.dates.map((date) => (
                  <ListItem
                    key={date._id}
                    primaryText={`${moment(date.trainingDate, 'x').format(
                      'DD-MM-YYYY',
                    )} - ${date.title} - ${date.city}`}
                    onClick={() =>
                      browserHistory.push(
                        `/szkolenia-otwarte/${date.slug}/${date._id}`,
                      )
                    }
                  />
                ))
              : null}
            {this.state.selectedDistrict &&
            this.state.selectedDistrict.dates.length ? null : (
              <ListItem>Brak nadchodzących szkoleń</ListItem>
            )}
          </List>
        </Dialog>
      </div>
    );
  }
}

OpenTrainingsMap.propTypes = {
  dates: PropTypes.arrayOf(
    PropTypes.shape({
      city: PropTypes.string,
      district: PropTypes.string,
      trainingDate: PropTypes.date,
      text: PropTypes.string,
    }),
  ).isRequired,
};

export default OpenTrainingsMap;
