import React from 'react';
import PropTypes from 'prop-types';
import './NewProductsList.scss';
import { Card, CardText } from 'material-ui';
import { Loading } from '../../utils/loading';
import NewDynamicColumnView from '../../NewLayout/NewDynamicColumnView';
import Product from '../product/Product';

const NewProductsList = ({ products, loading, pageSettings }) => {
  const productsToRender = [];

  const insertClearfixes = () => {
    let currentEl = 0;

    products.forEach((product, index) => {
      const render = (
        <div
          key={currentEl}
          className="products-list__product col-sm-6 col-md-6 col-lg-4"
        >
          <Product {...product} />
        </div>
      );

      currentEl += 1;

      productsToRender.push(render);

      if (index % 2 === 1) {
        productsToRender.push(
          <div key={currentEl} className="clearfix visible-sm" />,
        );
        currentEl += 1;
      }

      if (index % 3 === 2) {
        productsToRender.push(
          <div key={currentEl} className="clearfix visible-md" />,
        );
        currentEl += 1;
      }
    });
  };

  insertClearfixes();

  // eslint-disable-next-line react/no-multi-comp
  const renderPageContent = pageSettings.content ? (
    <CardText
      dangerouslySetInnerHTML={{
        __html: pageSettings.content,
      }}
    />
  ) : null;

  return (
    <NewDynamicColumnView {...pageSettings}>
      <div className="newsGroup">
        <div className="row row--flex">
          <div className="col-lg-12" style={{ width: '100%' }}>
            {loading ? (
              <Loading />
            ) : (
              <Card>
                <h1 className="pagetitle">{pageSettings.title}</h1>
                {renderPageContent}
              </Card>
            )}
          </div>
        </div>
        <div className="col-lg-12" style={{ padding: '20px 0' }}>
          <div className="newsGroup__news-list">{productsToRender}</div>
        </div>
      </div>
    </NewDynamicColumnView>
  );
};

NewProductsList.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string,
      imageSrc: PropTypes.string,
    }),
  ).isRequired,
  pageSettings: PropTypes.shape({
    content: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default NewProductsList;
