const customTitles = [
  {
    path: '/',
    title: 'Szkolenia dla oświaty - ECRK LEX - Elżbieta Linowska',
    description:
      'Szkolenia dla oświaty ➤ Podnieś swoje kwalifikacje jako nauczyciel ✅ Poznaj nowe metody dydaktyczne i zyskaj cenne umiejętności ✅ Zapraszamy!',
  },
  {
    path: '/produkty/dokumenty-szkolne-wzory',
    title: 'Dokumenty szkolne, wzory dokumentów szkolnych - ECRK Lex',
    description:
      'Dokumenty szkolne wzory ➤ Znajdź profesjonalne wzory dokumentów szkolnych ✅ Ułatw sobie pracę w szkole dzięki naszym szablonom ✅ Sprawdź nas!',
  },
  {
    path: '/produkty/e-szkolenia-dla-nauczycieli',
    title: ' E-szkolenia dla nauczycieli - ECRK Lex',
    description:
      ' E-szkolenia dla nauczycieli ➤ Oferujemy kursy z nowoczesnymi metodami nauczania ✅ Technologii edukacyjnych i zarządzania klasą ✅ Zobacz naszą ofertę!',
  },
  {
    path: '/szkolenia-otwarte-dla-nauczycieli',
    title: 'Szkolenia otwarte dla nauczycieli - ECRK Lex',
    description:
      'Szkolenia otwarte dla nauczycieli ➤ Rozwijaj swoje kompetencje w dynamicznym środowisku edukacyjnym ✅ Wysoka jakość usług ✅ Zapraszamy!',
  },
  {
    path: '/szkolenia-zamkniete-dla-nauczycieli',
    title: 'Szkolenia zamknięte dla nauczycieli - ECRK Lex',
    description:
      'Szkolenia zamknięte dla nauczycieli ➤ Dostosowane do Twoich potrzeb i oczekiwań ✅ Oferujemy wysoką jakość szkoleń | Podnieś swoje kwalifikacje z nami!',
  },
  {
    path: '/rodo',
    title: 'Dokumentacja RODO - ECRK Lex',
    description:
      'Dokumentacja RODO ➤ Rozwijaj swoje umiejętności pedagogiczne dzięki naszym profesjonalnym szkoleniom dla nauczycieli ⭐ Sprawdź nasze oferty!',
  },
  {
    path: '/aktualnosci',
    title: 'Aktualności - ECRK Lex',
    description:
      'Aktualności ➤ Rozwijaj swoje umiejętności pedagogiczne dzięki naszym profesjonalnym szkoleniom dla nauczycieli ⭐ Sprawdź nasze oferty!',
  },
  {
    path: '/akty-prawne',
    title: 'Akty prawne - ECRK Lex',
    description:
      'Akty prawne ➤ Rozwijaj swoje umiejętności pedagogiczne dzięki naszym profesjonalnym szkoleniom dla nauczycieli ⭐ Sprawdź nasze oferty!',
  },
  {
    path: '/produkty-szkolne',
    title: 'Produkty szkolne - ECRK Lex',
    description:
      'Produkty szkolne ➤ Rozwijaj swoje umiejętności pedagogiczne dzięki naszym profesjonalnym szkoleniom dla nauczycieli ⭐ Sprawdź nasze oferty!',
  },
  {
    path: '/o-nas',
    title: 'O nas - ECRK Lex',
    description:
      'O nas ➤ Rozwijaj swoje umiejętności pedagogiczne dzięki naszym profesjonalnym szkoleniom dla nauczycieli ⭐ Sprawdź nasze oferty!',
  },
  {
    path: '/rada-programowa',
    title: 'Rada programowa - ECRK Lex',
    description:
      'Rada programowa ➤ Rozwijaj swoje umiejętności pedagogiczne dzięki naszym profesjonalnym szkoleniom dla nauczycieli ⭐ Sprawdź nasze oferty!',
  },
  {
    path: '/opinie',
    title: 'Opinie klientów - ECRK Lex',
    description:
      'Opinie klientów ➤ Rozwijaj swoje umiejętności pedagogiczne dzięki naszym profesjonalnym szkoleniom dla nauczycieli ⭐ Sprawdź nasze oferty!',
  },
  {
    path: '/faq',
    title: 'FAQ - ECRK Lex',
    description:
      'FAQ ➤ Rozwijaj swoje umiejętności pedagogiczne dzięki naszym profesjonalnym szkoleniom dla nauczycieli ⭐ Sprawdź nasze oferty!',
  },
  {
    path: '/produkty/statuty',
    title: 'Statuty - ECRK Lex',
    description:
      'Statuty ➤ Rozwijaj swoje umiejętności pedagogiczne dzięki naszym profesjonalnym szkoleniom dla nauczycieli ⭐ Sprawdź nasze oferty!',
  },
  {
    path: '/produkty/szkolenia-online',
    title: 'Szkolenia online - ECRK Lex',
    description:
      'Szkolenia online ➤ Rozwijaj swoje umiejętności pedagogiczne dzięki naszym profesjonalnym szkoleniom dla nauczycieli ⭐ Sprawdź nasze oferty!',
  },
  {
    path: '/warto-wiedziec/samorzady',
    title: 'Warto wiedzieć - samorządy - ECRK Lex',
    description:
      'Warto wiedzieć - samorządy ➤ Rozwijaj swoje umiejętności pedagogiczne dzięki naszym profesjonalnym szkoleniom dla nauczycieli ⭐ Sprawdź nasze oferty!',
  },
  {
    path: '/warto-wiedziec/szpubliczne',
    title: 'Warto wiedzieć - szkoły publiczne - ECRK Lex',
    description:
      'Warto wiedzieć - szkoły publiczne ➤ Rozwijaj swoje umiejętności pedagogiczne dzięki naszym profesjonalnym szkoleniom dla nauczycieli ⭐ Sprawdź nasze oferty!',
  },
  {
    path: '/warto-wiedziec/kadry',
    title: 'Warto wiedzieć - kadry - ECRK Lex',
    description:
      'Warto wiedzieć - kadry ➤ Rozwijaj swoje umiejętności pedagogiczne dzięki naszym profesjonalnym szkoleniom dla nauczycieli ⭐ Sprawdź nasze oferty!',
  },
  {
    path: '/produkty/e-lekcje',
    title: 'E-lekcje - ECRK Lex',
    description:
      'E-lekcje ➤ Rozwijaj swoje umiejętności pedagogiczne dzięki naszym profesjonalnym szkoleniom dla nauczycieli ⭐ Sprawdź nasze oferty!',
  },
];

export default customTitles;
