import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { Card, CardTitle, CardText } from 'material-ui';
import { useMutation, useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import { Loading } from '../../utils/loading';
import { productSlugToType } from '../../utils/product';
import { AppContext } from '../../common/AppContext';
import withGraphqlHook from '../../common/withGraphqlHook';
import getPageSettingsQuery from '../../graphql/queries/getPageSettings.graphql';
import addClosedTrainingRequestMutation from '../../graphql/mutations/addClosedTrainingRequest.graphql';
import DynamicColumnView from '../../NewLayout/DynamicColumnView';
import ClosedTrainingsRequestForm from './ClosedTrainingRequestForm';

const defaultRequest = {
  training: {},
  date: {},
  participantsNumber: '',
  place: '',
  contactPersonName: '',
  contactPersonEmail: '',
  contactPersonPhone: '',
  comment: '',
};

class ClosedTrainings extends React.Component {
  state = {
    request: {
      training: {},
      date: {},
      participantsNumber: '',
      place: '',
      contactPersonName: '',
      contactPersonEmail: '',
      contactPersonPhone: '',
      comment: '',
    },
    saving: false,
    success: false,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.pageSettings !== this.props.pageSettings) {
      const { user } = this.context;
      const request = { ...this.state.request };
      if (user) {
        request.contactPersonName = user.profile.contactPersonName;
        request.contactPersonEmail = user.username;
        request.contactPersonPhone = user.profile.contactPersonPhone;
      }

      this.setState({
        request,
      });
    }
  }

  static contextType = AppContext;

  handleRequestFormSubmit = async (request) => {
    this.setState({
      saving: true,
    });

    await this.props.sendClosedTrainingConfirmation({
      variables: {
        input: request,
      },
    });
    this.setState({
      request: defaultRequest,
      saving: false,
      success: true,
    });
    browserHistory.push('/');

    this.context.setMessage('Twoje zapytanie zostało wysłane pomyślnie');
  };

  render() {
    const { pageSettings, loading } = this.props;

    if (loading) {
      return <Loading />;
    }

    const trainings = pageSettings.trainings.sort((a, b) =>
      a.title.localeCompare(b.title),
    );

    return (
      <DynamicColumnView
        ready={!loading}
        hasTwoColumns={pageSettings && pageSettings.hasTwoColumns}
        secondColumn={pageSettings && pageSettings.secondColumn}
      >
        <div className="row row--flex">
          <div className="col-lg-12">
            {pageSettings ? (
              <Card>
                <h1 className="pagetitle">{pageSettings.title}</h1>
                <CardText
                  dangerouslySetInnerHTML={{ __html: pageSettings.content }}
                />
              </Card>
            ) : null}
            <br />
            <Card>
              <CardText>
                <div className="row">
                  <ClosedTrainingsRequestForm
                    user={this.context.user}
                    trainings={trainings}
                    request={this.state.request}
                    onSubmit={this.handleRequestFormSubmit}
                    saving={this.state.saving}
                    success={this.state.success}
                    ref={(node) => {
                      this.userForm = node;
                    }}
                    submit
                  />
                </div>
              </CardText>
            </Card>
          </div>
        </div>
      </DynamicColumnView>
    );
  }
}

export default withGraphqlHook((props) => {
  const type = productSlugToType(props.location.pathname);

  const { data, loading } = useQuery(getPageSettingsQuery, {
    variables: { type: 'pageContent', page: type },
  });

  const [sendClosedTrainingConfirmation] = useMutation(
    addClosedTrainingRequestMutation,
  );

  const pageSettings = get(data, 'getPageSettings.settings', null);

  return { pageSettings, loading, sendClosedTrainingConfirmation };
}, ClosedTrainings);

ClosedTrainings.propTypes = {
  loading: PropTypes.bool.isRequired,
  pageSettings: PropTypes.object,
};

ClosedTrainings.defaultProps = {
  pageSettings: null,
};
