import React from "react";
import PropTypes from "prop-types";
import "./DynamicColumnView.scss";

function NewDynamicColumnView({ children }) {
  return (
    <div style={{ marginTop: 20 }} className="row row--flex">
      <div className="col-lg-12">{children}</div>
    </div>
  );
}

NewDynamicColumnView.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

NewDynamicColumnView.defaultProps = {
  children: null,
};

export default NewDynamicColumnView;
