import React from 'react';
import { browserHistory } from 'react-router';
import PropTypes from 'prop-types';
import { Dialog, RaisedButton } from 'material-ui';
import { useQuery } from '@apollo/react-hooks';
import './HighlightedNews.scss';
import Skeleton from 'react-loading-skeleton';
import getHomePageNewsQuery from '../../graphql/queries/getHomepageNews.graphql';
import getPageSettingsQuery from '../../graphql/queries/getPageSettings.graphql';
import withGraphqlHook from '../../common/withGraphqlHook';
import 'react-loading-skeleton/dist/skeleton.css';

class HighlightedNews extends React.Component {
  state = {
    highlightedNewsIndex: 0,
    isModalOpen: false,
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate(nextProps) {
    const day = 1000 * 60 * 24;
    const timePassed =
      new Date().valueOf() - window.localStorage.closeModalTime;
    const shouldOpenModal = window.localStorage.closeModalTime
      ? timePassed > day
      : true;
    if (
      nextProps.settings &&
      nextProps.settings.openModal &&
      !this.state.isModalOpen &&
      shouldOpenModal
    ) {
      // eslint-disable-next-line react/no-will-update-set-state
      this.setState({
        isModalOpen: true,
      });
    }
  }

  handleClose = () => {
    this.setState({
      isModalOpen: false,
    });
    window.localStorage.closeModalTime = new Date().valueOf();
  };

  changeHeroNews = () => {
    let nextNews = this.state.highlightedNewsIndex + 1;
    if (nextNews + 1 > this.props.news.length) {
      nextNews = 0;
    }

    this.setState({
      highlightedNewsIndex: nextNews,
    });
  };

  render() {
    const { isMobile } = this.props;
    const news = [...this.props.news];

    const heroNews = isMobile
      ? null
      : news.splice(this.state.highlightedNewsIndex, 1)[0];
    const secondaryNews = [...news];

    if (!isMobile && !this.interval) {
      this.interval = setInterval(() => this.changeHeroNews(), 10000);
    }
    const heroHasLongTitle = heroNews && heroNews.title.length > 130;

    return (
      <div className="highnews-wrapper">
        <React.Fragment>
          <div className="row">
            <div className="col-lg-6 col-md-12">
              {isMobile ? null : (
                <div className="highnews-heronews">
                  <div
                    className="highnews-heroimage"
                    style={
                      !this.props.loading
                        ? { backgroundImage: `url("${heroNews.imageSrc}"` }
                        : {}
                    }
                  />
                  <div className="highnews-herooverlay">
                    <div
                      className="highnews-herotitle"
                      style={{
                        lineHeight: `${
                          this.props.loading || heroHasLongTitle
                            ? '16px'
                            : '23px'
                        }`,
                        fontSize: `${
                          this.props.loading || heroHasLongTitle
                            ? '14px'
                            : '20px'
                        }`,
                      }}
                    >
                      {this.props.loading ? (
                        <Skeleton count={2} />
                      ) : (
                        heroNews.title
                      )}
                    </div>
                    <div className="highnews-herobutton">
                      <RaisedButton
                        primary
                        label="Zobacz"
                        onClick={() =>
                          !this.props.loading &&
                          browserHistory.push(`/news/${heroNews.slug}`)
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="highnews-newslist">
                {secondaryNews.map((singleNews) => (
                  <div
                    className="highnews-singlenews"
                    key={singleNews._id}
                    onClick={() =>
                      browserHistory.push(`/news/${singleNews.slug}`)
                    }
                  >
                    <span className="title">{singleNews.title}</span>
                    <div className="highnews-newsimage">
                      <img src={singleNews.imageSrc} alt={singleNews.title} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </React.Fragment>
        {this.props.settings ? (
          <Dialog
            title="Komunikat"
            modal={false}
            open={this.state.isModalOpen}
            onRequestClose={this.handleClose}
            autoScrollBodyContent
          >
            {this.props.settings.youtubeModalContent ? (
              <iframe
                width="560"
                height="315"
                src={`${this.props.settings.ytLink}?autoplay=1`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: this.props.settings.content,
                }}
              />
            )}
            <br />
            <RaisedButton label="Zamknij" onClick={this.handleClose} primary />
          </Dialog>
        ) : null}
      </div>
    );
  }
}

export default withGraphqlHook(() => {
  let news = [];
  let settings = null;
  const { data: newsData, loading: newsLoading } =
    useQuery(getHomePageNewsQuery);
  const { data: settingsData, loading: settingsLoading } = useQuery(
    getPageSettingsQuery,
    {
      variables: { type: 'homepageSettings' },
    },
  );

  const loading = newsLoading || settingsLoading;

  if (!loading) {
    news = newsData.getHomepageNews;
    // eslint-disable-next-line prefer-destructuring
    settings = settingsData.getPageSettings.settings;
  }

  return { news, loading, settings };
}, HighlightedNews);

HighlightedNews.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  news: PropTypes.array.isRequired,
  settings: PropTypes.shape({
    content: PropTypes.string,
    ytLink: PropTypes.string,
    youtubeModalContent: PropTypes.bool,
    openModal: PropTypes.bool,
  }),
};

HighlightedNews.defaultProps = {
  settings: null,
};
