import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RaisedButton } from 'material-ui';
import { browserHistory } from 'react-router';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import Panel from '../common/Panel';
import RecommendationLoading from '../common/RecommendationLoading';
import getRecommendationsQuery from '../../graphql/queries/getRecommendations.graphql';
import Recommendation from '../common/Recommendation';

function HomeRecommendations() {
  const [currentRecommendationIdx, setCurrentRecommendationIdx] = useState(0);
  const [recommendationsInterval, setRecommendationsInterval] = useState(null);
  const { data, loading } = useQuery(getRecommendationsQuery);
  const recommendations = get(data, 'getRecommendations', []);

  const startChangeInterval = (time) => {
    setRecommendationsInterval(
      setTimeout(() => {
        const getNext = (sec) =>
          sec === recommendations.length - 1 ? 0 : sec + 1;
        setCurrentRecommendationIdx((sec) => getNext(sec));
      }, time),
    );
  };

  const clearChangeInterval = () => {
    clearTimeout(recommendationsInterval);
  };

  if (loading) return <RecommendationLoading />;

  return (
    !!recommendations.length && (
      <Panel backgroundColor="#1B4079" title="Opinie" color="#fff">
        <div className="homereco-wrapper">
          {
            <Recommendation
              isHomeScreen
              recommendation={recommendations[currentRecommendationIdx]}
              startChangeInterval={startChangeInterval}
              clearChangeInterval={clearChangeInterval}
            />
          }
        </div>
        <div className="homereco-seemore">
          <RaisedButton
            label="Zobacz wszystkie"
            onClick={() => browserHistory.push('/opinie')}
            backgroundColor="#1B4079"
            labelColor="#fff"
          />
        </div>
      </Panel>
    )
  );
}

export default HomeRecommendations;

HomeRecommendations.propTypes = {
  recommendations: PropTypes.array.isRequired,
  ready: PropTypes.bool.isRequired,
};
