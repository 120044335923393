import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import isEmpty from 'lodash/isEmpty';
import InfoPanel from '../../../utils/InfoPanel/InfoPanel';
import BasketSchoolProducts from '../BasketSchoolProducts/BasketSchoolProducts';
import BasketDocuments from '../BasketDocuments/BasketDocuments';
import BasketTrainings from '../BasketTrainings/BasketTrainings';
import ShippingMethodSelect from '../../ShippingMethodSelect/ShippingMethodSelect';
import BasketPriceSummary from '../BasketPriceSummary/BasketPriceSummary';
import { getFixedPrice } from '../../../utils/utils';
import { Loading } from '../../../utils/loading';
import { CartLessons } from '../CartLessons/CartLessons';

const BasketProductsList = (props) => {
  const {
    items,
    lastStep,
    changeShippingMethod,
    shippingMethod,
    shippingMethods,
    loading,
  } = props;

  if (loading) {
    return <Loading />;
  }
  const documents = items.filter((product) => product.type === 'document');
  const schoolProducts = items.filter(
    (product) => product.type === 'schoolProduct',
  );
  const trainings = items.filter(
    (product) => product.type === 'training' || product.type === 'pack',
  );

  const lessons = items.filter((product) => product.type === 'lesson');

  const emptyBasket =
    !documents.length &&
    !schoolProducts.length &&
    !trainings.length &&
    !lessons.length;

  let vatSum = 0;
  let netSum = 0;
  let brutSum = 0;

  props.items.forEach((item) => {
    if (item.type === 'schoolProduct') {
      brutSum += item.quantity * item.price;
      netSum += item.quantity * item.priceNet;
      vatSum += item.quantity * item.vat;
    } else {
      const vat = item.public ? 0 : item.vat;
      brutSum += item.price + vat;
      netSum += item.price;
      vatSum += vat;
    }
  });

  brutSum = getFixedPrice(brutSum);
  netSum = getFixedPrice(netSum);
  vatSum = getFixedPrice(vatSum);

  const totalPrices = {
    brutSum,
    netSum,
    vatSum,
  };

  if (!isEmpty(props.shippingMethod)) {
    totalPrices.shipping = getFixedPrice(props.shippingMethod.price);
  }

  return (
    <div>
      {!!schoolProducts.length && (
        <React.Fragment>
          <BasketSchoolProducts lastStep={lastStep} items={schoolProducts} />
          {props.display ? null : (
            <ShippingMethodSelect
              changeShippingMethod={changeShippingMethod}
              shippingMethod={shippingMethod.name}
              shippingMethods={shippingMethods}
            />
          )}

          <div className="space-between-tables" />
        </React.Fragment>
      )}
      {!!documents.length && (
        <React.Fragment>
          <BasketDocuments lastStep={lastStep} items={documents} />
          <div className="space-between-tables" />
        </React.Fragment>
      )}
      {!!trainings.length && (
        <React.Fragment>
          <BasketTrainings
            lastStep={lastStep}
            items={trainings}
            products={props.products}
          />
          <div className="space-between-tables" />
        </React.Fragment>
      )}
      {!!lessons.length && (
        <React.Fragment>
          <CartLessons
            lastStep={lastStep}
            items={lessons}
            products={props.products}
          />
          <div className="space-between-tables" />
        </React.Fragment>
      )}
      {!emptyBasket && <BasketPriceSummary prices={totalPrices} />}
      {emptyBasket && (
        <InfoPanel role="info" fade={false}>
          Koszyk jest pusty
        </InfoPanel>
      )}
    </div>
  );
};

const basketItemShape = PropTypes.arrayOf(
  PropTypes.shape({
    _id: PropTypes.string,
    title: PropTypes.string,
    price: PropTypes.number,
    priceGross: PropTypes.number,
    vat: PropTypes.number,
    type: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.string),
    imageSrc: PropTypes.string,
    preview: PropTypes.string,
    modifyCartItem: PropTypes.func,
    displayDate: PropTypes.date,
  }),
);

BasketProductsList.propTypes = {
  schoolProducts: basketItemShape,
  documents: basketItemShape,
  trainings: basketItemShape,
  products: PropTypes.array,
  items: basketItemShape,
  display: PropTypes.bool,
  lastStep: PropTypes.bool,
  shippingMethod: PropTypes.shape({
    price: PropTypes.number,
    name: PropTypes.string,
  }),
  changeShippingMethod: PropTypes.func.isRequired,
  shippingMethods: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};

BasketProductsList.defaultProps = {
  documents: [],
  trainings: [],
  schoolProducts: [],
  products: [],
  display: false,
  items: [],
  shippingMethod: { price: 0, name: '' },
  lastStep: false,
};

export default BasketProductsList;
