import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import UserSingleOrderContainer from '../userSingleOrder/userSingleOrder';
import InfoPanel from '../../utils/InfoPanel/InfoPanel';
import getUserOrdersQuery from '../../graphql/queries/getUserOrders.graphql';
import getProductsQuery from '../../graphql/queries/getProducts.graphql';
import { Loading } from '../../utils/loading';

function UserOrders(props) {
  const variables = {};
  if (props.userId) {
    variables.userId = props.userId;
  }
  const {
    data: ordersData,
    refetch,
    loading: ordersLoading,
  } = useQuery(getUserOrdersQuery, { variables });
  const {
    data: productsData,
    loading: productsLoading,
  } = useQuery(getProductsQuery, { variables });

  if (ordersLoading || productsLoading) {
    return <Loading />;
  }

  const orders = get(ordersData, 'getUserOrders', []);
  const products = get(productsData, 'getProducts', []);

  return (
    <div>
      {orders.length > 0 ? (
        <div>
          {orders.map((order) => (
            <UserSingleOrderContainer
              refetch={refetch}
              order={order}
              products={products}
              key={order._id}
            />
          ))}
        </div>
      ) : (
        <InfoPanel role="info">Brak zamówień do wyświetlenia</InfoPanel>
      )}
    </div>
  );
}

UserOrders.propTypes = {
  userId: PropTypes.string,
};

UserOrders.defaultProps = {
  userId: null,
};

export default UserOrders;
