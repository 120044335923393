import React from 'react';
import { Chip, Avatar } from 'material-ui';
import {
  green300,
  green700,
  red300,
  red700,
  orange300,
  orange200,
} from 'material-ui/styles/colors';
import CheckCircle from 'material-ui/svg-icons/action/check-circle';
import NotInterested from 'material-ui/svg-icons/av/not-interested';
import Warning from 'material-ui/svg-icons/alert/warning';
import AccessTime from 'material-ui/svg-icons/device/access-time';
import PropTypes from 'prop-types';
import { getOrderStatus } from '../../utils/order';

const StatusChip = ({ status }) => {
  const style = {};
  switch (status) {
    case 'PENDING':
      style.face = orange200;
      style.background = orange300;
      style.icon = <AccessTime color="white" />;
      break;
    case 'CANCELLED':
    case 'ARCHIVED':
      style.face = red300;
      style.background = red700;
      style.icon = <NotInterested color="white" />;
      break;
    case 'COMPLETE':
    case 'CONFIRMED':
      style.face = green300;
      style.background = green700;
      style.icon = <CheckCircle color="white" />;
      break;
    default:
      style.face = orange200;
      style.background = orange300;
      style.icon = <Warning color="white" />;
  }

  return (
    <Chip backgroundColor={style.face}>
      <Avatar size={32} color={style.face} backgroundColor={style.background}>
        {style.icon}
      </Avatar>
      <span style={{ color: 'white' }}>{getOrderStatus(status)}</span>
    </Chip>
  );
};

StatusChip.propTypes = {
  status: PropTypes.string.isRequired,
};

export default StatusChip;
