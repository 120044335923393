import React from 'react';
import PropTypes from 'prop-types';
import { ListItem } from 'material-ui';
import { browserHistory } from 'react-router';
import Amount from './Amount';

export class Category extends React.Component {
  handleClick = (slug) => {
    browserHistory.push(`/produkty-szkolne/${slug}`);
  };

  categoryOpened = (slug, children) => {
    const { pathName } = this.props;
    if (pathName.includes(slug)) return true;

    let childInPath = false;
    children.forEach((child) => {
      if (pathName.includes(child)) childInPath = true;
    });
    return childInPath;
  };

  render() {
    const getListItem = (item) => {
      const innerStyle = {
        padding: '0 3px 0 0',
      };
      if (item.level !== 1)
        innerStyle.marginLeft = `${(item.level - 1) * 16}px`;

      return (
        <ListItem
          key={item._id}
          primaryText={item.name}
          style={{ fontSize: '12px', padding: '10px' }}
          innerDivStyle={innerStyle}
          onClick={() => this.handleClick(item.slug)}
          open={this.categoryOpened(item.slug, item.children)}
          rightIconButton={<Amount amount={item.productsCount} />}
          nestedItems={item.children.map((childSlug) => {
            {
              const childCategory = this.props.subcategories.find(
                (subcategory) => subcategory.slug === childSlug,
              );
              if (childCategory) return getListItem(childCategory);
              return null;
            }
          })}
        />
      );
    };
    return getListItem(this.props);
  }
}

Category.propTypes = {
  _id: PropTypes.string.isRequired,
  children: PropTypes.array,
  name: PropTypes.string.isRequired,
  subcategories: PropTypes.array,
  slug: PropTypes.string.isRequired,
  pathName: PropTypes.string.isRequired,
  amounts: PropTypes.object.isRequired,
  thirdLevel: PropTypes.array,
};

Category.defaultProps = {
  children: undefined,
  subcategories: undefined,
  thirdLevel: [],
};
