import React from 'react';
import {
  CardTitle,
  CardText,
  List,
  ListItem,
  IconMenu,
  MenuItem,
  IconButton,
} from 'material-ui';
import './userSingleOrder.scss';
import moment from 'moment';
import { grey400 } from 'material-ui/styles/colors';
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';
import AddCircle from 'material-ui/svg-icons/content/add-circle';
import ProductChangeDialog from '../ProductChangeDialog/ProductChangeDialog';
import { Loading } from '../../utils/loading';
import ProductPickDialog from '../ProductPickDialog/ProductPickDialog';
import StatusChip from '../StatusChip/StatusChip';

const getProductFeatures = (item) => (
  <div>
    <div>
      {item.color ? (
        <div>
          <small>Kolor: {item.color.text}</small>
        </div>
      ) : null}
      {item.size ? (
        <div>
          <small>Rozmiar: {item.size.size}</small>
        </div>
      ) : null}
      {item.quantity ? (
        <div>
          <small>Liczba sztuk: {item.quantity}</small>
        </div>
      ) : null}
    </div>
  </div>
);

class UserSingleOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productChangeModalOpen: false,
      productPickModalOpen: false,
      orderProductId: '',
      orderId: this.props.order._id,
      packProductId: '',
    };
  }

  modalClose = () => {
    this.props.refetch();
    this.setState({
      productChangeModalOpen: false,
      packProductId: '',
      orderProductId: '',
    });
  };

  productPickModalClose = () => {
    this.props.refetch();
    this.setState({
      productPickModalOpen: false,
      orderProductId: '',
    });
  };

  addPackProduct = (orderProductId) => {
    this.setState({
      productPickModalOpen: true,
      orderProductId,
    });
  };

  changePackProduct = (orderProductId, packProductId) => {
    if (this.canChangeContent(orderProductId, packProductId)) {
      this.setState({
        productChangeModalOpen: true,
        orderProductId,
        packProductId,
      });
    }
  };

  canChangeContent = (orderProductId, packProductId) => {
    const orderedProduct = this.props.order.items.find(
      (order) => order.itemId === orderProductId,
    );
    const displayDate = orderedProduct.displayDates[packProductId];
    const productDetails = this.props.products.find(
      (product) => product._id === packProductId,
    );
    const days = productDetails && productDetails.days;
    if (!displayDate) {
      return true;
    }
    return moment().isBefore(moment(displayDate, 'x').subtract(days, 'day'));
  };

  render() {
    const { order, products } = this.props;
    const iconButtonElement = (
      <IconButton touch tooltipPosition="bottom-left">
        <MoreVertIcon color={grey400} />
      </IconButton>
    );

    return (
      <div className="order">
        <div
          className="order__inner"
          ref={(node) => {
            this.innerNode = node;
          }}
        >
          <CardTitle
            title={`Zamówienie numer #${order.orderNo}`}
            subtitle={moment(order.created.date, 'x').format(
              'DD.MM.YYYY HH:mm',
            )}
            children={<StatusChip status={order.status} />}
          />
          <CardText>
            {this.props.loading ? (
              <Loading />
            ) : (
              <List>
                {order.items.map((item) => {
                  const nestedItems = [];
                  if (item.type === 'pack') {
                    for (let i = 0; i < item.contains; i++) {
                      if (item.content[i]) {
                        const currentProduct = products.find(
                          (product) => product._id === item.content[i],
                        );
                        const displayDate =
                          currentProduct &&
                          item.displayDates[currentProduct._id];
                        const dateText = displayDate
                          ? `Data wyświetlenia: ${moment(displayDate).format(
                              'DD.MM.YYYY',
                            )}`
                          : '';
                        if (currentProduct) {
                          nestedItems.push(
                            <ListItem
                              key={item.content[i]}
                              primaryText={currentProduct.title}
                              secondaryText={dateText}
                              disabled
                              rightIcon={
                                this.canChangeContent(
                                  item.itemId,
                                  currentProduct._id,
                                ) ? (
                                  <IconMenu
                                    iconButtonElement={iconButtonElement}
                                  >
                                    <MenuItem
                                      onClick={() =>
                                        this.changePackProduct(
                                          item.itemId,
                                          currentProduct._id,
                                        )
                                      }
                                    >
                                      Zmień produkt
                                    </MenuItem>
                                  </IconMenu>
                                ) : null
                              }
                            />,
                          );
                        }
                      } else if (order.status !== 'CANCELLED') {
                        nestedItems.push(
                          <ListItem
                            leftIcon={<AddCircle />}
                            key={`missing${i}`}
                            primaryText="Dobierz produkt"
                            onClick={() => this.addPackProduct(item.itemId)}
                          />,
                        );
                      }
                    }
                  }
                  return (
                    <ListItem
                      key={item.itemId}
                      primaryText={item.title}
                      nestedItems={nestedItems}
                      secondaryTextLines={3}
                      secondaryText={
                        item.type === 'schoolProduct'
                          ? getProductFeatures(item)
                          : ''
                      }
                      open
                      disabled
                    />
                  );
                })}
              </List>
            )}
          </CardText>
        </div>
        <ProductChangeDialog
          products={products}
          open={this.state.productChangeModalOpen}
          packProductId={this.state.packProductId}
          orderProductId={this.state.orderProductId}
          closeCb={this.modalClose}
          orderId={this.props.order._id}
          orderStatus={this.props.order.status}
        />
        <ProductPickDialog
          open={this.state.productPickModalOpen}
          products={products}
          closeCb={this.productPickModalClose}
          orderId={this.props.order._id}
          orderItemId={this.state.orderProductId}
        />
      </div>
    );
  }
}

export default UserSingleOrder;
