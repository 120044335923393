import React from 'react';
import PropTypes from 'prop-types';
import { lowerMenuItems } from './menuItem';
import './LowerMenu.scss';
import LowerMenuElement from './LowerMenuElement';

const LowerMenu = (props) => {
  const { isMobile, stickMenu } = props;
  return (
    <div
      className="lowermenu-wrapper"
      style={{
        position: stickMenu ? 'fixed' : 'static',
        marginTop: stickMenu ? 0 : 15,
      }}
    >
      <div className="container">
        <div
          className="lowermenu-menucontainer"
          style={{
            height: isMobile ? 'auto' : 60,
            flexDirection: isMobile ? 'column' : 'row',
          }}
        >
          {lowerMenuItems.map((menuItem) => (
            <LowerMenuElement
              key={menuItem.label}
              menuItem={menuItem}
              isMobile={isMobile}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

LowerMenu.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  stickMenu: PropTypes.bool.isRequired,
};

export default LowerMenu;
