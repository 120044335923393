import React from 'react';
import { Checkbox, MenuItem, SelectField } from 'material-ui';
import PropTypes from 'prop-types';
import InfoPanel from '../../../utils/InfoPanel/InfoPanel';
import getTrainingDateRange from './getTrainingDateRange';

const Step1 = ({
  isPublic,
  setIsPublic,
  eInvoice,
  setEInvoice,
  training,
  trainingDate,
  stepActions,
  price,
  selectedProvider,
  setProvider,
  providers,
  eInvoiceApprovalText,
  errors,
  setErrors,
}) => (
  <>
    <h2>{training && training.title}</h2>
    Szkolenie odbędzie się {trainingDate.trainingDate} w godzinach{' '}
    {getTrainingDateRange(trainingDate.startDate, trainingDate.endDate)}
    <br />
    <br />
    <div className="price-tag">Cena: {price}zł</div>
    <br />
    <Checkbox
      label="Finansowane ze środków publicznych"
      checked={isPublic}
      onCheck={() => setIsPublic(!isPublic)}
    />
    <SelectField
      floatingLabelText="Podmiot wystawiający"
      value={selectedProvider}
      onChange={(event, index, value) => {
        setProvider(value);
      }}
    >
      {Object.entries(providers).map(([key, name]) => (
        <MenuItem value={key} key={key} primaryText={name} />
      ))}
    </SelectField>
    {errors.provider && (
      <InfoPanel
        role="error"
        fade
        clearErrorCb={() => setErrors({ ...errors, provider: '' })}
      >
        {errors.provider}
      </InfoPanel>
    )}
    {eInvoiceApprovalText && (
      <Checkbox
        label={eInvoiceApprovalText}
        checked={eInvoice}
        onCheck={() => setEInvoice(!eInvoice)}
      />
    )}
    {stepActions}
  </>
);

Step1.propTypes = {
  isPublic: PropTypes.bool.isRequired,
  setIsPublic: PropTypes.func.isRequired,
  eInvoice: PropTypes.bool.isRequired,
  setEInvoice: PropTypes.func.isRequired,
  price: PropTypes.number.isRequired,
  training: PropTypes.object.isRequired,
  trainingDate: PropTypes.object.isRequired,
  stepActions: PropTypes.object.isRequired,
  selectedProvider: PropTypes.string,
  setProvider: PropTypes.func.isRequired,
  providers: PropTypes.object,
  eInvoiceApprovalText: PropTypes.string.isRequired,
  errors: PropTypes.shape({
    zipCode: PropTypes.string,
    name: PropTypes.string,
    city: PropTypes.string,
    address: PropTypes.string,
    email: PropTypes.string,
    participants: PropTypes.string,
    provider: PropTypes.string,
  }).isRequired,
  setErrors: PropTypes.func.isRequired,
};

Step1.defaultProps = {
  selectedProvider: '',
  providers: {},
};

export default Step1;
