import React from 'react';
import { List, ListItem } from 'material-ui/List';
import Toggle from 'material-ui/Toggle';
import './index.scss';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import getPageSettingsQuery from '../../graphql/queries/getPageSettings.graphql';
import NewsContext from './NewsFilterContext';

function NewsFilter() {
  const { data } = useQuery(getPageSettingsQuery, {
    variables: { type: 'newsCategories' },
  });

  const categories = get(data, 'getPageSettings.settings.categories', []);

  return (
    <NewsContext.Consumer>
      {(value) => (
        <List>
          {categories.map((category) => (
            <ListItem
              primaryText={category.name}
              style={{ fontSize: '12px', padding: '10px' }}
              rightToggle={
                <Toggle onToggle={() => value.changeFilter(category.value)} />
              }
              key={category.value}
            />
          ))}
        </List>
      )}
    </NewsContext.Consumer>
  );
}

export default NewsFilter;
