import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardActions,
  CardText,
  CardTitle,
  List,
  ListItem,
} from 'material-ui';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import withGraphqlHook from '../../common/withGraphqlHook';
import getFaqQuery from '../../graphql/queries/getFaq.graphql';
import getFaqCategoriesQuery from '../../graphql/queries/getFaqCategories.graphql';

function FAQComponent(props) {
  return (
    <div style={{ marginTop: 15 }}>
      <List>
        {props.categories.map((category) => {
          const faqs = props.faqs.filter(
            (faq) => faq.category === category._id,
          );
          return (
            <Card key={category._id} style={{ marginBottom: '15px' }}>
              <ListItem
                primaryTogglesNestedList={true}
                primaryText={<CardTitle title={category.name} />}
                nestedItems={[
                  <React.Fragment key={category._id}>
                    <CardText>
                      {faqs.length
                        ? faqs.map((faq) => (
                            <ListItem
                              primaryText={faq.question}
                              key={faq._id}
                              primaryTogglesNestedList={true}
                              nestedItems={[
                                <ListItem key={faq._id} disabled>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: faq.answer,
                                    }}
                                  />
                                </ListItem>,
                              ]}
                            />
                          ))
                        : 'Brak pytań w kategorii'}
                    </CardText>
                    <CardActions />
                  </React.Fragment>,
                ]}
              />
            </Card>
          );
        })}
      </List>
    </div>
  );
}

export default withGraphqlHook(() => {
  const { data: faqData, loading: faqLoading } = useQuery(getFaqQuery);
  const { data: faqCategoriesData, loading: faqCategoriesLoading } = useQuery(
    getFaqCategoriesQuery,
  );

  const loading = faqLoading || faqCategoriesLoading;

  const faqs = get(faqData, 'getFaq', []);
  const categories = get(faqCategoriesData, 'getFaqCategories', []);

  return { faqs, categories, loading };
}, FAQComponent);

FAQComponent.propTypes = {
  categories: PropTypes.object.isRequired,
  faqs: PropTypes.object.isRequired,
};
