import { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import { AppContext } from './common/AppContext';
import hasActiveRegistrationsWithFilesQuery from './graphql/queries/hasActiveRegistrationsWithFiles.graphql';

export default function UserDownloadStatus() {
  const { setUser, user } = useContext(AppContext);
  useQuery(hasActiveRegistrationsWithFilesQuery, {
    fetchPolicy: 'cache-and-network',
    skip: !user,
    onCompleted: (resp) => {
      const value = get(resp, 'hasActiveRegistrationsWithFiles', false);
      if (user && user.hasFilesToDownload !== value) {
        setUser({
          ...user,
          hasFilesToDownload: value,
        });
      }
    },
  });
  return null;
}
