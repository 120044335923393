import React from 'react';
import PropTypes from 'prop-types';
import { OnlineTrainingParticipants } from '@ecrklex/components';
import getTrainingDateRange from './getTrainingDateRange';

const Step4 = ({
  price,
  training,
  stepActions,
  shippingAddress,
  trainingDate,
  participants,
  displayShippingAddress,
  isProlegis,
}) => (
  <>
    <h2>{training && training.title}</h2>
    Szkolenie odbędzie się {trainingDate.trainingDate} w godzinach{' '}
    {getTrainingDateRange(trainingDate.startDate, trainingDate.endDate)}
    <br />
    <br />
    {displayShippingAddress && (
      <div>
        <strong>Materiały zostaną wysłane na adres:</strong>
        <br />
        {shippingAddress.name}
        <br />
        {shippingAddress.adress}
        <br />
        {shippingAddress.zipCode} {shippingAddress.city}
        <br />
      </div>
    )}
    Zaproszenie na szkolenie zostanie wysłane do każdego uczestnika osobno
    <br />
    <h3>Uczestnicy</h3>
    <OnlineTrainingParticipants
      participants={participants}
      displayOnly
      isProlegis={isProlegis}
    />
    <br />
    <strong>Cena końcowa:</strong> {price}zł
    {stepActions}
  </>
);

Step4.defaultProps = {
  isProlegis: false,
};

Step4.propTypes = {
  price: PropTypes.number.isRequired,
  stepActions: PropTypes.object.isRequired,
  training: PropTypes.object.isRequired,
  shippingAddress: PropTypes.shape({
    zipCode: PropTypes.string,
    name: PropTypes.string,
    city: PropTypes.string,
    adress: PropTypes.string,
  }).isRequired,
  trainingDate: PropTypes.object.isRequired,
  participants: PropTypes.array.isRequired,
  isProlegis: PropTypes.bool,
  displayShippingAddress: PropTypes.bool.isRequired,
};

export default Step4;
