const getNestedData = (obj, path) => {
  const currPath = path.split('.');
  let res = obj;
  for (let i = 0; i < currPath.length; i++) res = res[currPath[i]];
  return res;
};

const zipCodeValid = (zipCode) => /^[0-9][0-9]-[0-9][0-9][0-9]$/.test(zipCode);

const nipValid = (nip) =>
  /^(\d{10})$|^(\d{3}-\d{3}-\d{2}-\d{2})$|^(\d{3}-\d{2}-\d{2}-\d{3})$/.test(
    nip,
  );

const genCreatedOn = () => ({
  date: new Date(),
  unix: new Date().valueOf(),
});

const getProductEditBackPath = (productType) => {
  switch (productType) {
    case 'training':
      return '/panel/produkty/e-szkolenia';
    case 'document':
      return '/panel/produkty/dokumenty';
    case 'pack':
      return '/panel/produkty/pakiety';
    case 'lesson':
      return '/panel/produkty/e-lekcje';
    default:
      return '/panel';
  }
};

const getProductTypeByPath = (path) => {
  switch (path) {
    case '/profil/dokumenty':
      return 'document';
    case '/profil/e-szkolenia':
    case '/profil':
      return 'training';
    case '/profil/szkolenia-otwarte':
      return 'openTraining';
    case '/profil/e-lekcje':
      return 'lesson';
    default:
      return 'document';
  }
};

const getFixedPrice = (price) => {
  let newPrice = price.toFixed(2);
  if (newPrice.split('.').length === 1) newPrice += '0';
  return newPrice;
};

export {
  getNestedData,
  zipCodeValid,
  nipValid,
  genCreatedOn,
  getProductEditBackPath,
  getProductTypeByPath,
  getFixedPrice,
};
