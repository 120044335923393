import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CardTitle, CardText } from 'material-ui/Card';
import RaisedButton from 'material-ui/RaisedButton';
import './index.scss';
import { browserHistory } from 'react-router';

class News extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      imageWidth: undefined,
      imageHeight: undefined,
    };

    this.updateImageDimensions = this.updateImageDimensions.bind(this);
  }

  updateImageDimensions() {
    let imageWidth;
    let imageHeight;

    if (window.matchMedia('(min-width: 1200px)').matches) {
      imageWidth = 200;
      imageHeight = undefined;
    } else {
      const maxImageHeightOnMobiles = 200;

      imageWidth = undefined;
      imageHeight =
        this.innerNode.clientHeight > maxImageHeightOnMobiles
          ? maxImageHeightOnMobiles
          : this.innerNode.clientHeight;
    }

    this.setState({ imageWidth, imageHeight });
  }

  componentWillUnmount() {
    if (this.props.imageSrc) {
      window.removeEventListener('resize', this.updateImageDimensions);
    }
  }

  componentDidMount() {
    if (this.props.imageSrc) {
      this.updateImageDimensions();
      window.addEventListener('resize', this.updateImageDimensions);
    }
  }

  render() {
    const newsText = `${this.props.text
      .replace(/<(?:.|\n)*?>/gm, ' ')
      .substr(0, 200)}...`;

    return (
      <div
        className={classNames('news', {
          'news--has-image': this.props.imageSrc,
        })}
      >
        <div className="news__inner">
          <div>
            <img
              src={this.props.imageSrc}
              className="news-image"
              align="left"
            />
            <CardTitle
              title={this.props.title}
              subtitle={this.props.createdAt || ''}
            />
          </div>
          <br />
          <CardText>
            {newsText}
            <div className="news__button-wrapper">
              <RaisedButton
                primary
                label={`Czytaj dalej`}
                onClick={() => browserHistory.push(this.props.link)}
              />
            </div>
          </CardText>
        </div>
      </div>
    );
  }
}

News.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  imageSrc: PropTypes.string,
  slug: PropTypes.string,
  link: PropTypes.string,
  createdAt: PropTypes.string,
};

News.defaultProps = {
  createdAt: null,
};

export default News;
