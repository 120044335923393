import React from 'react';
import { blue500 } from 'material-ui/styles/colors';
import moment from 'moment/moment';
import { Avatar, IconButton, ListItem } from 'material-ui';
import Delete from 'material-ui/svg-icons/action/delete';
import ActionAssignment from 'material-ui/svg-icons/action/assignment';
import { useMutation } from '@apollo/react-hooks';
import countFileDownloadMutation from '../../graphql/mutations/countFileDownload.graphql';

export default function SingleFile(props) {
  const [countFileDownload] = useMutation(countFileDownloadMutation, {
    onCompleted: () => {
      props.refetch();
    },
  });

  const downloadFile = async () => {
    window.open(props.file.url);
    if (props.userDocumentId) {
      await countFileDownload({
        variables: {
          userDocumentId: props.userDocumentId,
          fileId: props.file._id,
        },
      });
    }
  };

  const { file } = props;
  return (
    <ListItem
      leftAvatar={
        <Avatar icon={<ActionAssignment />} backgroundColor={blue500} />
      }
      rightIconButton={
        props.display ? null : (
          <IconButton onClick={fileRemove}>
            <Delete />
          </IconButton>
        )
      }
      primaryText={file.name}
      onClick={downloadFile}
      secondaryText={
        props.display
          ? null
          : moment(file.created.date).format('DD-MM-YYYY, HH:mm')
      }
    />
  );
}
