import React from 'react';
import PropTypes from 'prop-types';
import {
  CardActions,
  CardText,
  RaisedButton,
  Subheader,
  SelectField,
  MenuItem,
  TextField,
  LinearProgress,
  DatePicker,
} from 'material-ui';
import './index.scss';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import USER_SHAPE from '../common/shapes/USER_SHAPE';

class ClosedTrainingRequestForm extends React.Component {
  state = {
    request: this.props.request,
    trainings: this.props.trainings,
    errors: {
      date: '',
      training: '',
      participantsNumber: '',
      place: '',
      contactPersonName: '',
      contactPersonEmail: '',
      contactPersonPhone: '',
    },
    success: this.props.success,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.success && !this.props.success) {
      this.setState({ request: nextProps.request });
    }
  }

  changeValue = (event) => {
    const { value } = event.target;
    const key = event.target.name;
    const request = { ...this.state.request };
    const errors = { ...this.state.errors };

    request[key] = value;
    errors[key] = '';

    this.setState({ errors });
    this.setState({ request });
  };

  changeTraining = (event, index, value) => {
    const errors = { ...this.state.errors };
    errors.training = '';
    this.setState({
      request: {
        ...this.state.request,
        training: value,
      },
      errors,
    });
  };

  changeDate = (event, value) => {
    const errors = { ...this.state.errors };
    errors.date = '';
    this.setState({
      request: {
        ...this.state.request,
        date: value,
      },
      errors,
    });
  };

  validateForm = () => {
    const { request, errors } = this.state;
    let isFormValid = true;
    Object.entries(request).forEach(([key, value]) => {
      if (key === 'comment') return;
      let valueCheck = value;
      if (isObject(value)) {
        valueCheck = isEmpty(valueCheck) ? '' : valueCheck.toString();
      }
      if (key === 'date') {
        valueCheck = value.getFullYear ? 'valid' : '';
      }
      if (key) errors[key] = valueCheck ? '' : 'Pole wymagane';
    });

    Object.values(errors).forEach((value) => {
      if (value && isFormValid) {
        isFormValid = false;
      }
    });

    this.setState({ errors });
    return isFormValid;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const formValid = this.validateForm();
    if (formValid && this.props.onSubmit !== 'undefined') {
      this.props.onSubmit(this.state.request);
    }
  };

  render() {
    const request = { ...this.state.request };
    const { trainings } = this.state;
    return (
      <form onSubmit={this.handleSubmit}>
        <CardText>
          <div className="row">
            <div className="col-md-6">
              <Subheader className="request-form__subheader">
                Dane o szkoleniu
              </Subheader>
              <SelectField
                floatingLabelText="Temat szkolenia"
                value={request.training}
                onChange={this.changeTraining}
                errorText={
                  this.state.errors.training ? this.state.errors.training : ''
                }
                fullWidth
              >
                {trainings.map((training) => (
                  <MenuItem
                    key={training._id}
                    value={training.title}
                    primaryText={training.title}
                  />
                ))}
              </SelectField>
              <div className="row">
                <div className="col-md-6">
                  <DatePicker
                    style={{ width: '100%' }}
                    hintText="Proponowana data"
                    DateTimeFormat={Intl.DateTimeFormat}
                    errorText={
                      this.state.errors.date ? this.state.errors.date : ''
                    }
                    value={request.date}
                    onChange={this.changeDate}
                    name="date"
                    locale="pl"
                    okLabel="Wybierz"
                    cancelLabel="Anuluj"
                    floatingLabelText="Proponowana data"
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    value={request.participantsNumber}
                    errorText={
                      this.state.errors.participantsNumber
                        ? this.state.errors.participantsNumber
                        : ''
                    }
                    floatingLabelText="Liczba uczestników"
                    fullWidth
                    onChange={this.changeValue}
                    aria-label="attendees-number"
                    name="participantsNumber"
                  />
                </div>
              </div>
              <TextField
                value={request.place}
                floatingLabelText="Miejsce"
                errorText={
                  this.state.errors.place ? this.state.errors.place : ''
                }
                fullWidth
                onChange={this.changeValue}
                aria-label="place"
                name="place"
              />
              <TextField
                value={request.comment}
                multiLine={true}
                rows={2}
                rowsMax={4}
                floatingLabelText="Komentarz"
                fullWidth
                onChange={this.changeValue}
                aria-label="comment"
                name="comment"
              />
              <br />
            </div>
            <div className="col-md-6">
              <Subheader className="request-form__subheader">
                Osoba kontaktowa
              </Subheader>
              <TextField
                value={request.contactPersonName}
                floatingLabelText="Imię i nazwisko"
                fullWidth
                onChange={this.changeValue}
                aria-label="registration-contactPersonName"
                name="contactPersonName"
                errorText={
                  this.state.errors.contactPersonName
                    ? this.state.errors.contactPersonName
                    : ''
                }
              />
              <br />
              <TextField
                value={request.contactPersonEmail}
                floatingLabelText="E-mail kontaktowy"
                fullWidth
                onChange={this.changeValue}
                aria-label="registration-contactPersonEmail"
                name="contactPersonEmail"
                errorText={
                  this.state.errors.contactPersonEmail
                    ? this.state.errors.contactPersonEmail
                    : ''
                }
              />
              <TextField
                value={request.contactPersonPhone}
                floatingLabelText="Telefon kontaktowy"
                fullWidth
                onChange={this.changeValue}
                aria-label="registration-contactPersonPhone"
                name="contactPersonPhone"
                errorText={
                  this.state.errors.contactPersonPhone
                    ? this.state.errors.contactPersonPhone
                    : ''
                }
              />
            </div>
          </div>
        </CardText>
        <CardActions className="request-form__actions">
          <RaisedButton
            type="submit"
            primary
            disabled={this.props.saving}
            label={
              this.props.saving ? (
                <span>
                  ...
                  <LinearProgress size={10} color="#fff" />
                </span>
              ) : (
                'Wyślij'
              )
            }
          />
        </CardActions>
        <CardText style={{ fontSize: '12px' }}>
          Zgodnie z art. 13 ogólnego rozporządzenia o ochronie danych osobowych
          z dnia 27 kwietnia 2016 r. (Dz. Urz. UE L 119 z 04.05.2016)
          informujemy, iż:
          <ol style={{ fontSize: '12px' }}>
            <li>
              Administratorem Pani/Pana danych osobowych jest ECRK Lex s.c. z
              siedzibą w Białymstoku przy Al. Jana Pawła II 59/43.
            </li>
            <li>
              Pani/Pana dane osobowe przetwarzane będą w celu realizacji
              zamówienia - na podstawie Art. 6 ust. 1 lit. b ogólnego
              rozporządzenia o ochronie danych osobowych z dnia 27 kwietnia 2016
              r.
            </li>
            <li>
              Odbiorcami Pani/Pana danych osobowych będą podmioty uczestniczące
              w realizacji zamówienia - ECRK Lex s.c., EduLex, Prolegis Elżbieta
              Linowska.
            </li>
            <li>
              Pani/Pana dane osobowe przechowywane będą przez okres 6 lat.
            </li>
            <li>
              Posiada Pani/Pan prawo do żądania od administratora dostępu do
              danych osobowych, prawo do ich sprostowania, usunięcia lub
              ograniczenia przetwarzania oraz prawo do przenoszenia danych.
            </li>
            <li>Ma Pani/Pan prawo wniesienia skargi do organu nadzorczego.</li>
            <li>
              Podanie danych osobowych jest dobrowolne, jednakże odmowa podania
              danych może skutkować odmową realizacji zamówienia.
            </li>
          </ol>
          Administrator danych osobowych ECRK Lex S.C.
        </CardText>
      </form>
    );
  }
}

ClosedTrainingRequestForm.defaultProps = {
  request: {
    training: {},
    date: '',
    participantsNumber: '',
    place: '',
    contactPersonName: '',
    contactPersonEmail: '',
    contactPersonPhone: '',
  },
  user: undefined,
  trainings: [],
  saving: false,
};

ClosedTrainingRequestForm.propTypes = {
  request: PropTypes.shape({
    training: PropTypes.shape({
      _id: PropTypes.string,
      title: PropTypes.string,
    }),
    date: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    participantsNumber: PropTypes.string,
    place: PropTypes.string,
    contactPersonName: PropTypes.string,
    contactPersonEmail: PropTypes.string,
    contactPersonPhone: PropTypes.string,
  }),
  trainings: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  user: USER_SHAPE,
  saving: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  success: PropTypes.bool.isRequired,
};

export default ClosedTrainingRequestForm;
