import React from 'react';
import { Card, CardText, CardTitle } from 'material-ui';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import { Loading } from '../../utils/loading';
import getPageSettingsQuery from '../../graphql/queries/getPageSettings.graphql';

const Regulations = () => {
  const { data, loading } = useQuery(getPageSettingsQuery, {
    variables: { type: 'pageContent', page: 'regulations' },
  });
  const pageContent = get(data, 'getPageSettings.settings', null);
  if (loading) {
    return <Loading />;
  }
  return (
    <div style={{ marginTop: 20 }}>
      <div className="row row--flex">
        <div className="col-lg-12">
          {pageContent ? (
            <Card>
              <CardTitle title={pageContent.title} />
              <CardText
                dangerouslySetInnerHTML={{
                  __html: pageContent.content,
                }}
              />
            </Card>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Regulations;
