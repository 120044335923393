import React, { useState } from 'react';
import { Parallax } from 'react-parallax';
import {
  Card,
  CardTitle,
  CardText,
  RaisedButton,
  SelectField,
  MenuItem,
  CardActions,
} from 'material-ui';
import { browserHistory } from 'react-router';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import { Loading } from '../../utils/loading';
import useMetaKeyword from '../../hooks/useMetaKeyword';
import useGenerateSubpageTitle from '../../hooks/useGenerateSubpageTitle';
import districts from '../../common/districts';
import getOpenTrainingWithDatesQuery from '../../graphql/queries/getOpenTrainingWithDates.graphql';
import withGraphqlHook from '../../common/withGraphqlHook';

const SingleOpenTraining = (props) => {
  const { loading, openTraining: product, openTrainingDates } = props;
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [selectedDate, setSelectedDate] = useState('');

  useMetaKeyword(product);
  useGenerateSubpageTitle(product);

  const changeSelectedDistrict = (event, index, value) => {
    setSelectedDistrict(value);
  };

  const changeOpenTrainingDate = (event, index, value) => {
    setSelectedDate(value);
  };

  const filteredTrainingDates = openTrainingDates.filter(
    (openTrainingDate) => openTrainingDate.district === selectedDistrict,
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <div style={{ marginTop: 20 }}>
      <Card>
        {product.imageSrc ? (
          <Parallax
            bgImage={product.imageSrc}
            className="logo-bar"
            strength={600}
          />
        ) : null}
        <CardTitle title={product.title} />
        <CardText>
          <div className="row">
            <div className="col-lg-6">
              <div dangerouslySetInnerHTML={{ __html: product.text }} />
            </div>
            <div className="col-lg-6">
              <CardTitle title="Wybierz termin" />
              <SelectField
                floatingLabelText="Województwo"
                value={selectedDistrict}
                onChange={changeSelectedDistrict}
                fullWidth
              >
                {districts.map((district) => (
                  <MenuItem
                    value={district.slug}
                    key={district.slug}
                    primaryText={`${district.name}`}
                  />
                ))}
              </SelectField>
              <br />
              <SelectField
                floatingLabelText="Termin"
                value={selectedDate}
                onChange={changeOpenTrainingDate}
                fullWidth
                disabled={!selectedDistrict}
              >
                {filteredTrainingDates.map((openTrainingDate) => {
                  return (
                    <MenuItem
                      value={openTrainingDate._id}
                      key={openTrainingDate._id}
                      primaryText={`${openTrainingDate.city} - ${moment(
                        openTrainingDate.trainingDate,
                        'x',
                      ).format('DD-MM-YYYY')} - ${openTrainingDate.place}`}
                    />
                  );
                })}
              </SelectField>
              <br />
              <br />
              <CardActions>
                {selectedDate && selectedDistrict ? (
                  <RaisedButton
                    primary
                    label="Zobacz szczegóły"
                    onClick={() => {
                      browserHistory.push(
                        `/szkolenia-otwarte/${product.slug}/${selectedDate}`,
                      );
                    }}
                  />
                ) : null}
              </CardActions>
            </div>
          </div>
        </CardText>
      </Card>
    </div>
  );
};

export default withGraphqlHook((props) => {
  const { slug } = props.params;
  let openTraining = null;
  let openTrainingDates = [];

  const { loading, data: openTrainingData } = useQuery(
    getOpenTrainingWithDatesQuery,
    {
      variables: {
        slug,
      },
    },
  );

  if (!loading) {
    openTraining = openTrainingData.getOpenTrainingWithDates;
    openTrainingDates = get(openTraining, 'dates', []);
  }

  return {
    loading,
    openTraining,
    openTrainingDates,
  };
}, SingleOpenTraining);

SingleOpenTraining.defaultProps = {
  districts: [],
  openTraining: {},
  openTrainingDates: [],
};

SingleOpenTraining.propTypes = {
  districts: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  openTraining: PropTypes.object,
  openTrainingDates: PropTypes.array,
};
