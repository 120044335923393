import React from 'react';
import { Card, CardText, CardTitle } from 'material-ui';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import { Loading } from '../../utils/loading';
import getPageSettingsQuery from '../../graphql/queries/getPageSettings.graphql';
import DynamicColumnView from '../../NewLayout/DynamicColumnView';

const About = () => {
  const { data, loading } = useQuery(getPageSettingsQuery, {
    variables: { type: 'pageContent', page: 'about' },
  });
  const pageContent = get(data, 'getPageSettings.settings', null);
  if (loading) {
    return <Loading />;
  }
  return (
    <DynamicColumnView ready={!loading} {...pageContent}>
      {pageContent ? (
        <Card>
          <CardTitle title={pageContent.title} />
          <CardText
            dangerouslySetInnerHTML={{
              __html: pageContent.content,
            }}
          />
        </Card>
      ) : null}
    </DynamicColumnView>
  );
};

export default About;
