import React, { useState } from 'react';
import { AutoComplete, Dialog, FlatButton } from 'material-ui';
import { useMutation } from '@apollo/react-hooks';
import InfoPanel from '../../utils/InfoPanel/InfoPanel';
import addProductToPackMutation from '../../graphql/mutations/addProductToPack.graphql';

export default function ProductPickDialog(props) {
  const [pickedProduct, setPickedProduct] = useState('');
  const [currentError, setCurrentError] = useState('');
  const [addToPack, { loading }] = useMutation(addProductToPackMutation, {
    onError: (err) => {
      setCurrentError(err.message);
    },
    onCompleted: () => {
      props.closeCb();
    },
  });

  const addProduct = async () => {
    if (pickedProduct) {
      await addToPack({
        variables: {
          orderId: props.orderId,
          orderItemId: props.orderItemId,
          productId: pickedProduct,
        },
      });
    }
  };

  const clearError = () => {
    setCurrentError('');
  };

  const handleChange = (product) => {
    if (product.value) {
      setPickedProduct(product.value);
    }
  };

  const productsList = props.products
    .filter((product) => product.type === 'training')
    .map((product) => ({
      text: product.title,
      value: product._id,
    }));
  const actions = [
    <FlatButton
      label="Anuluj"
      primary={true}
      onClick={props.closeCb}
      key="1"
    />,
    <FlatButton
      label="Dodaj"
      primary={true}
      keyboardFocused={true}
      onClick={addProduct}
      disabled={loading}
      key="2"
    />,
  ];

  return (
    <Dialog
      title="Dodaj produkt"
      modal={false}
      open={props.open}
      onRequestClose={props.closeCb}
      actions={actions}
    >
      {currentError ? (
        <InfoPanel role="error" fade clearErrorCb={clearError}>
          {currentError}
        </InfoPanel>
      ) : null}
      <AutoComplete
        floatingLabelText="Wpisz nazwę produktu"
        filter={AutoComplete.caseInsensitiveFilter}
        dataSource={productsList}
        fullWidth
        onNewRequest={handleChange}
      />
    </Dialog>
  );
}
