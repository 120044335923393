import React, { useContext, useState } from 'react';
import { DropDownMenu, MenuItem, Tab, Tabs } from 'material-ui';
import AccountCircle from 'material-ui/svg-icons/action/account-circle';
import Book from 'material-ui/svg-icons/action/book';
import List from 'material-ui/svg-icons/action/list';
import ShoppingCart from 'material-ui/svg-icons/action/shopping-cart';
import { browserHistory } from 'react-router';
import './userProfile.scss';
import Cloud from 'material-ui/svg-icons/file/cloud';
import PropTypes from 'prop-types';
import { AppContext } from '../../common/AppContext';
import RedNotificationDot from '../RedNotificationDot/RedNotificationDot';

function UserProfile(props) {
  const { user } = useContext(AppContext);
  let defaultTab = 0;
  switch (props.location.pathname) {
    case '/profil/e-szkolenia':
    case '/profil':
      defaultTab = 0;
      break;
    case '/profil/e-lekcje':
      defaultTab = 1;
      break;
    case '/profil/szkolenia-otwarte':
      defaultTab = 2;
      break;
    case '/profil/szkolenia-online':
      defaultTab = 3;
      break;
    case '/profil/dokumenty':
      defaultTab = 4;
      break;
    case '/profil/zamowienia':
      defaultTab = 5;
      break;
    case '/profil/dane':
      defaultTab = 6;
      break;
    default:
      defaultTab = 0;
  }

  const [initialTab] = useState(defaultTab);
  const handleChange = (tab) => {
    browserHistory.push(tab);
  };

  const handleDropdownChange = (event, index, value) => {
    switch (value) {
      case 0:
        browserHistory.push('/profil/e-szkolenia');
        break;
      case 1:
        browserHistory.push('/profil/e-lekcje');
        break;
      case 2:
        browserHistory.push('/profil/szkolenia-otwarte');
        break;
      case 3:
        browserHistory.push('/profil/szkolenia-online');
        break;
      case 4:
        browserHistory.push('/profil/dokumenty');
        break;
      case 5:
        browserHistory.push('/profil/zamowienia');
        break;
      case 6:
        browserHistory.push('/profil/dane');
        break;
      default:
        browserHistory.push('/profil/e-szkolenia');
    }
  };
  const isMobile = window.innerWidth < 720;

  return (
    <div style={{ margin: '10px 0' }}>
      {isMobile ? (
        <DropDownMenu
          value={defaultTab}
          style={{ width: '100%' }}
          onChange={handleDropdownChange}
        >
          <MenuItem value={0} primaryText="E-szkolenia" />
          <MenuItem value={1} primaryText="E-lekcje" />
          <MenuItem value={2} primaryText="Szkolenia otwarte" />
          <MenuItem value={3} primaryText="Szkolenia online" />
          <MenuItem value={4} primaryText="Dokumenty" />
          <MenuItem value={5} primaryText="Zamówienia" />
          <MenuItem value={6} primaryText="Dane" />
        </DropDownMenu>
      ) : (
        <Tabs onChange={handleChange} initialSelectedIndex={initialTab}>
          <Tab
            icon={<List className="material-icons" />}
            label="E-szkolenia"
            value="/profil/e-szkolenia"
          />
          <Tab icon={<Book />} label="E-lekcje" value="/profil/e-lekcje" />
          <Tab
            icon={<Book />}
            label="Szkolenia otwarte"
            value="/profil/szkolenia-otwarte"
          />
          <Tab
            icon={
              <div style={{ width: '100%' }}>
                <Cloud
                  color={
                    props.location.pathname === '/profil/szkolenia-online'
                      ? 'rgba(255, 255, 255)'
                      : 'rgba(255, 255, 255, 0.7)'
                  }
                />
                {user && user.hasFilesToDownload && (
                  <RedNotificationDot offset={12} />
                )}
              </div>
            }
            label="Szkolenia online"
            value="/profil/szkolenia-online"
          />
          <Tab
            icon={<List className="material-icons" />}
            label="Dokumenty"
            value="/profil/dokumenty"
          />
          <Tab
            icon={<ShoppingCart className="material-icons" />}
            label="Zamówienia"
            value="/profil/zamowienia"
          />
          <Tab icon={<AccountCircle />} label="Dane" value="/profil/dane" />
        </Tabs>
      )}

      <div className="profileTab">{props.children}</div>
    </div>
  );
}

export default UserProfile;

UserProfile.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
