import { useEffect } from 'react';
import { generateSubpageTitle } from '../utils/generatePageTitle';

export default function useMetaKeyword(propsToCheck) {
  useEffect(() => {
    if (propsToCheck && propsToCheck.title) {
      generateSubpageTitle(propsToCheck.title);
    }
  }, [propsToCheck]);
}
