import React, { useContext } from 'react';
import { Card, CardText } from 'material-ui';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import { Loading } from '../../utils/loading';
import USER_SHAPE from '../common/shapes/USER_SHAPE';
import getPageSettingsQuery from '../../graphql/queries/getPageSettings.graphql';
import { AppContext } from '../../common/AppContext';
import DynamicColumnView from '../../NewLayout/DynamicColumnView';
import GDPRForm from './GDPRForm';

function GDPR() {
  const { data, loading } = useQuery(getPageSettingsQuery, {
    variables: { type: 'pageContent', page: 'gdpr' },
  });
  const { user } = useContext(AppContext);
  const pageContent = get(data, 'getPageSettings.settings', null);
  if (loading) {
    return <Loading />;
  }

  if (pageContent)
    return (
      <DynamicColumnView ready={!loading} {...pageContent}>
        <>
          <Card>
            <h1 className="pagetitle">{pageContent.title}</h1>
            <CardText
              dangerouslySetInnerHTML={{
                __html: pageContent.content,
              }}
            />
          </Card>
          <br />
          {pageContent.additionalFields ? (
            <GDPRForm user={user} pageContent={pageContent} />
          ) : null}
        </>
      </DynamicColumnView>
    );
}

export default GDPR;

GDPR.propTypes = {
  user: USER_SHAPE,
  pageContent: PropTypes.shape({
    _id: PropTypes.string,
    content: PropTypes.string,
    hasTwoColumns: PropTypes.bool,
    secondColumn: PropTypes.array,
    title: PropTypes.string,
    type: PropTypes.string,
  }),
  ready: PropTypes.bool.isRequired,
};

GDPR.defaultProps = {
  user: null,
  pageContent: {},
};
