import React, { useState } from 'react';
import { RaisedButton, TextField } from 'material-ui';
import Search from 'material-ui/svg-icons/action/search';
import { browserHistory, Link } from 'react-router';
import PropTypes from 'prop-types';
import AccountBox from 'material-ui/svg-icons/action/account-box';
import Contact from 'material-ui/svg-icons/communication/mail-outline';
import NavbarBasket from '../navbarBasket';
import './TopHeader.scss';
import isLoggedIn from '../../utils/isLoggedIn';
import RedNotificationDot from '../RedNotificationDot/RedNotificationDot';

export default function TopHeader(props) {
  const [searchExpanded, setSearchExpanded] = useState(false);
  const [searchInput, setSearchInput] = useState('');

  const shouldDisplayNotification = () => {
    const currentLocation = browserHistory.getCurrentLocation();
    const isNotInProfilePage = !currentLocation.pathname.includes('/profil');
    return props.user && props.user.hasFilesToDownload && isNotInProfilePage;
  };

  const handleSearch = () => {
    browserHistory.push(`/szukaj/${searchInput}`);
    setSearchInput('');
    setSearchExpanded(false);
  };

  const handleAccountButton = () => {
    if (isLoggedIn()) {
      browserHistory.push('/profil');
    } else {
      browserHistory.push('/login');
    }
  };

  const searchClick = () => {
    if (searchExpanded && searchInput) {
      handleSearch();
    } else if (searchExpanded) {
      setSearchExpanded(false);
    } else {
      setSearchExpanded(true);
    }
  };

  const handleKey = (event) => {
    if (event.which === 13) {
      handleSearch();
    }
  };
  const setSearch = (event) => {
    setSearchInput(event.target.value);
  };

  const { isMobile } = props;
  return (
    <div className="topheader-wrapper">
      <div className="top-left">
        <div>
          ECRK Lex s.c.
          <br />
          Al. Jana Pawła II 59/43
          <br />
          15-704 Białystok
          <br />
        </div>
        <Link to="/">
          <img
            alt="ECRK logo"
            className="topheader-logo"
            src="/logo.svg"
            width={169}
            height={120}
          />
        </Link>
        <a href="https://prolegis.com.pl" target="_blank" rel="noreferrer">
          <img
            alt="Prolegis logo"
            className="topheader-logo-prolegis"
            src="/prolegis.svg"
            width={320}
            height={90}
          />
        </a>
      </div>
      {isMobile ? null : (
        <div className="topheader-right">
          <div className="topheader-cartwrapper">
            {props.user ? (
              <div style={{ textAlign: 'right' }}>
                Zalogowany jako {props.user.username}
                <br />
                <a href="#" className="logout" onClick={props.handleLogout}>
                  Wyloguj
                </a>
              </div>
            ) : null}
            <NavbarBasket />
          </div>
          <div className="topheader-buttonswrapper">
            <div className="topheader-searchwrapper">
              <TextField
                style={{
                  height: '34px',
                  width: searchExpanded ? '250px' : '0',
                  marginLeft: searchExpanded ? '5px' : '0',
                  transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
                }}
                value={searchInput}
                onChange={setSearch}
                name="search"
                onKeyPress={handleKey}
              />
              <Search
                style={{
                  margin: '0 6px',
                  cursor: 'pointer',
                }}
                color="#ddd"
                onClick={searchClick}
              />
            </div>
            <RaisedButton
              label={props.user ? 'Moje konto' : 'Zaloguj się'}
              backgroundColor="#E5CB20"
              labelColor="#fff"
              icon={<AccountBox style={{ transform: 'translateY(-2px)' }} />}
              onClick={handleAccountButton}
              style={{ marginRight: 5, position: 'relative' }}
            >
              {shouldDisplayNotification() && <RedNotificationDot />}
            </RaisedButton>
            <RaisedButton
              label="Kontakt"
              backgroundColor="#E5CB20"
              labelColor="#fff"
              icon={<Contact style={{ transform: 'translateY(-2px)' }} />}
              onClick={() => browserHistory.push('/kontakt')}
            />
          </div>
        </div>
      )}
    </div>
  );
}

TopHeader.propTypes = {
  user: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  handleLogout: PropTypes.func.isRequired,
};
