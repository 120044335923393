import React, { useContext, useState } from 'react';
import {
  Card,
  CardText,
  Checkbox,
  RaisedButton,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
} from 'material-ui';
import { useMutation } from '@apollo/react-hooks';
import get from 'lodash/get';
import { browserHistory } from 'react-router';
import { UserForm, validateEmail } from '@ecrklex/components';
import registerUserMutation from '../../graphql/mutations/registerUser.graphql';
import InfoPanel from '../../utils/InfoPanel/InfoPanel';
import { emptyUserProfile } from '../../utils/user';
import checkIfUserExistsMutation from '../../graphql/mutations/checkIfUserExists.graphql';
import { AppContext } from '../../common/AppContext';

export default function SignUp() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [stepIndex, setStepIndex] = useState(0);
  const [firstStepError, setFirstStepError] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [userExists, setUserExists] = useState(false);
  const [tacAccepted, setTacAccepted] = useState(true);
  const [marketingConsent, setMarketingConsent] = useState(true);
  const [checkUser] = useMutation(checkIfUserExistsMutation);
  const { setUser } = useContext(AppContext);

  const [submitMutation, { loading: saving }] = useMutation(
    registerUserMutation,
    {
      onCompleted: (data) => {
        const user = get(data, 'registerUser', null);
        setUser(user);
        browserHistory.push('/');
      },
    },
  );

  const signUp = async ({ profile }) => {
    const user = {
      email: username,
      password,
      details: { ...profile, marketingConsent },
    };
    await submitMutation({ variables: { input: user } });
  };

  const validatePassword = (event) => {
    if (event.target.value !== password) {
      setPasswordError('Podane hasła nie są indentyczne');
    } else {
      setPasswordError('');
    }
    setPasswordConfirm(event.target.value);
  };

  const checkEmail = () => {
    // eslint-disable-next-line no-useless-escape
    const emailTest = validateEmail(username.toLocaleLowerCase());
    if (!emailTest) {
      setEmailError('Podaj właściwy adres e-mail');
    }
    return emailTest;
  };

  const isNewUser = async () => {
    const result = await checkUser({ variables: { email: username } });
    if (result && result.data.checkIfUserExists) {
      setUserExists(true);
      return;
    }
    setStepIndex(1);
  };

  const firstStep = () => {
    if (
      username &&
      password &&
      passwordConfirm &&
      !passwordError &&
      checkEmail() &&
      tacAccepted
    ) {
      isNewUser();
    } else {
      setFirstStepError(true);
    }
  };

  const clearFirstStepError = () => {
    setFirstStepError(false);
  };

  const changeTac = () => {
    setTacAccepted(!tacAccepted);
  };

  const changeMarketingConsent = () => {
    setMarketingConsent(!marketingConsent);
  };

  return (
    <Card>
      <Stepper activeStep={stepIndex} orientation="vertical">
        <Step>
          <StepLabel>Login i hasło</StepLabel>
          <StepContent>
            Aby zarejestrować nowego użytkownika, podaj dane:
            <TextField
              name="username"
              hintText="Podaj e-mail"
              floatingLabelText="e-mail"
              aria-label="registration-email"
              fullWidth
              onChange={(event, newValue) => {
                setUsername(newValue);
                setEmailError('');
              }}
              errorText={emailError}
            />
            <br />
            <TextField
              type="password"
              name="password"
              hintText="Podaj hasło"
              aria-label="registration-password"
              floatingLabelText="hasło"
              fullWidth
              errorText={passwordError}
              onChange={(event, newValue) => {
                setPassword(newValue);
              }}
            />
            <TextField
              type="password"
              name="passwordConfirm"
              hintText="Potwierdź hasło"
              aria-label="registration-confirmation"
              floatingLabelText="Potwierdź hasło"
              fullWidth
              onChange={(event) => validatePassword(event)}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                margin: '.5rem 0',
              }}
            >
              <Checkbox
                checked={marketingConsent}
                onCheck={changeMarketingConsent}
                style={{ width: 'auto', marginTop: '.5rem' }}
                data-testid="marketingConsent"
              />
              <span>
                {`Wyrażam zgodę na otrzymywanie na adres mail informacji
                  marketingowych dotyczących ecrklex.pl. Zgodę można wycofać w
                  każdym czasie kontaktując się z biurem ECRK Lex s.c. Wycofanie
                  zgody nie wpływa na zgodność z prawem przetwarzania dokonanego
                  przed jej wycofaniem.`}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <Checkbox
                checked={tacAccepted}
                onCheck={changeTac}
                style={{ width: 'auto' }}
                data-testid="tocAccept"
              />
              <span>
                Akceptuję{' '}
                <a href="/regulamin" target="_blank">
                  regulamin
                </a>
              </span>
            </div>
            {firstStepError ? (
              <InfoPanel role="error" fade clearErrorCb={clearFirstStepError}>
                Uzupełnij poprawnie wszystkie pola!
              </InfoPanel>
            ) : null}
            <br />
            {userExists && (
              <InfoPanel role="error" fade={false} clearErrorCb={userExists}>
                Użytkownik o podanym adresie email już istnieje.{' '}
                <a style={{ color: 'blue' }} href="/login">
                  Kliknij tutaj
                </a>
                , aby się zalogować lub zresetować hasło.
              </InfoPanel>
            )}
            <RaisedButton
              style={{ marginTop: '1rem' }}
              label="Dalej"
              primary
              onClick={firstStep}
            />
          </StepContent>
        </Step>
        <Step>
          <StepLabel>Dane do faktury</StepLabel>
          <StepContent>
            <UserForm
              onSubmit={signUp}
              user={emptyUserProfile}
              submit
              submitButton
              saving={saving}
              submitText="Zarejestruj"
            />
          </StepContent>
        </Step>
      </Stepper>
      <CardText style={{ fontSize: '12px' }}>
        Zgodnie z art. 13 ogólnego rozporządzenia o ochronie danych osobowych z
        dnia 27 kwietnia 2016 r. (Dz. Urz. UE L 119 z 04.05.2016) informujemy,
        iż:
        <ol style={{ fontSize: '12px' }}>
          <li>
            Administratorem Pani/Pana danych osobowych jest ECRK Lex s.c. z
            siedzibą w Białymstoku przy Al. Jana Pawła II 59/43.
          </li>
          <li>
            Pani/Pana dane osobowe przetwarzane będą w celu realizacji
            zamówienia - na podstawie Art. 6 ust. 1 lit. b ogólnego
            rozporządzenia o ochronie danych osobowych z dnia 27 kwietnia 2016
            r.
          </li>
          <li>
            Odbiorcami Pani/Pana danych osobowych będą podmioty uczestniczące w
            realizacji zamówienia - ECRK Lex s.c., EduLex, Prolegis Elżbieta
            Linowska.
          </li>
          <li>Pani/Pana dane osobowe przechowywane będą przez okres 6 lat.</li>
          <li>
            Posiada Pani/Pan prawo do żądania od administratora dostępu do
            danych osobowych, prawo do ich sprostowania, usunięcia lub
            ograniczenia przetwarzania oraz prawo do przenoszenia danych.
          </li>
          <li>Ma Pani/Pan prawo wniesienia skargi do organu nadzorczego.</li>
          <li>
            Podanie danych osobowych jest dobrowolne, jednakże odmowa podania
            danych może skutkować odmową realizacji zamówienia.
          </li>
        </ol>
        Administrator danych osobowych ECRK Lex S.C.
      </CardText>
    </Card>
  );
}
SignUp.contextType = AppContext;
